.side-contacts {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-basis: 100%;
  flex-shrink: 0;
}

.side-contacts__block {
  display: flex;
  flex-direction: column;
  padding: 10px 12px;
  background-color: var(--white);
  border-radius: 10px;

  &:last-child {
    padding-bottom: 14px;
  }
}

.side-contacts__info {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--text);
  font-size: 16px;
  font-weight: 700;
  line-height: 1.6;
  text-decoration: none;

  svg {
    color: var(--blue);
    fill: var(--blue);
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
  
}

a.side-contacts__info {
  transition: color 0.3s;

  &:hover {
    color: var(--blue);
  }
}

.side-contacts__link {
  display: flex;
  text-decoration: none;
  color: var(--text);
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2; 
  transition: color 0.3s;
  &:not(:last-child) {
    margin-bottom: 5px;
  }
  &:hover {
    color: var(--blue);
  }
}