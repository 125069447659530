@charset "utf-8";
/* CSS Document */

.sidebarForm {
  /*background: #f6f6f6;*/
  background: #fff;
  box-shadow: 0 0 5px #ccc;
  -webkit-box-shadow: 0 0 5px #ccc;
  -moz-box-shadow: 0 0 5px #ccc;
  /*width: 420px;*/
}

#cor5 {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.sidebarForm h4 {
  font: 14px Verdana;
  color: #333333;
  text-align: left;
  padding: 10px 10px 0 10px;
}

.sidebarForm label {
  font: 12px Verdana;
  color: #333;
  padding: 10px;
}

.sidebarForm .input {
  width: 250px;
  height: 20px;
  overflow: hidden;
  background: white;
  border: solid 1px #DFE2E5;
  margin: 5px 0 10px 10px;
  box-shadow: 0 0 5px #ccc;
  -webkit-box-shadow: 0 0 5px #ccc;
  -moz-box-shadow: 0 0 5px #ccc;
}

.sidebarForm .input input {
  width: 248px;
  height: 26px;
  background: white;
  margin: -3px 0 0 -8px;
  padding: 0px 8px;
  border: none;
  background: transparent;
}

.sidebarForm .textarea {
  height: 70px;
  width: 320px;
  overflow: hidden;
  background: white;
  border: solid 1px #DFE2E5;
  margin: 5px 0 10px 10px;
  box-shadow: 0 0 5px #ccc;
  -webkit-box-shadow: 0 0 5px #ccc;
  -moz-box-shadow: 0 0 5px #ccc;
}

.sidebarForm .textarea textarea {
  width: 248px;
  height: 250px;
  background: white;
  border: none;
  resize:none;
}

.sidebarForm button {
  border: 1px #ccc solid;
  width: 267px;
  height: 40px;
  font: 13pt Georgia;
  text-decoration:none;
  text-align:center;
  font-style:italic;
  color: #333;
  background-color: #fea421;
  margin: 0px 0px 0px 10px;
  border-radius:10px;
}