article,
aside,
figure,
footer,
hgroup,
nav,
section {
  display: block;
}

/*  common styles */
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  /*text-align: center;*/
}

body,
td,
th,
h1,
h2,
h3,
h4,
h5,
input,
select,
textarea {
  color: #333;
  font: normal 14px Arial, Helvetica, sans-serif;
}

table,
td,
th {
  border: none;
  border-collapse: collapse;
}

form,
img,
div,
td,
th {
  padding: 0;
  margin: 0;
  border: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  text-align: left;
}

h1 {
  color: #3d6f91;
  padding: 0;
  font: normal 36px Arial, Helvetica, sans-serif;
  margin: 0 0 -3px -1px;
}

h2 {
  font: normal 18px Arial, Helvetica, sans-serif;
  color: #3d6f91;
  padding: 0;
  margin: 0 0 11px 0;
}

h3 {
  font: normal 18px Arial, Helvetica, sans-serif;
  padding: 0 0 11px 0;
  margin: 0 0;
  color: #4c4c4c;
}

h4 {
  font: normal 18px Arial, Helvetica, sans-serif;
  padding: 0;
  color: #3d6f91;
}

h5 {
  font: normal 24px Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 10px 0 48px 0;
  color: #3d6f91;
  text-align: center;
}

/*
h5 a {
	color: #924328;
	font: normal 24px 'Georgia', Tahoma, Arial, Helvetica, sans-serif;
	font-style: italic;
	text-decoration: none;
}
h5 a:hover {
	color: #447595;
	text-decoration: underline;
}
*/
h6 {
  color: #4d4d4d;
  font: bold 12px Arial, Helvetica, sans-serif;
}

p {
  padding: 0 0 0 0;
  margin: 20px 0 17px 0;
  line-height: 143%;
  font-size: inherit;
  font-family: inherit;
}

/*section{*/
/*margin: 0 0 20px;*/
/*}*/
.clear {
  clear: both;
  display: block;
  font-size: 1px;
  height: 1px;
  line-height: 1px;
  margin: 0 0 -1px;
}

.br {
  clear: both;
  font-size: 1px;
  height: 24px;
  line-height: 1px;
  margin: 0;
}

.brindex {
  clear: both;
  font-size: 1px;
  height: 9px;
  line-height: 1px;
  margin: 0;
}

.display_none {
  display: none;
}

a {
  color: #447595;
  text-decoration: underline;
  outline: none;
}

a:hover,
a.active {
  /*	
	color: #843525;
*/
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0 0 0 0;
  list-style: none;
}

ul li {
  padding: 0;
  margin: 0;
}

.error {
  text-align: left;
  font-size: 10px;
  color: #850600;
}

.float_right {
  float: right;
}

.float_left {
  float: left;
}

.align_left {
  text-align: left;
}

.align_center {
  text-align: center;
}

.align_right {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

/*  /common styles  */
#wrapper {
  text-align: center;
  width: 100%;
  min-height: 100%;
  height: 100%;
  height: auto !important;
  position: relative;
}

#wrapper #page {
  margin: 0 auto;
  text-align: left;
  padding: 40px 0 0 0px;
  width: 980px;
  right: 4px;
  position: relative;
  /*	z-index: 4;*/
}

/*
.girl {
    background: transparent url('../images1/girl.png') no-repeat 0 0;
	width: 299px;
	height: 388px;
}
*/
.wind1 {
  background: transparent url('/htdocs/images1/wind.png') no-repeat 2% 0;
  width: 100%;
  height: 100%;
  position: relative;
}

.wind2 {
  background: transparent url('/htdocs/images1/wind2.png') no-repeat 98% 0;
  width: 100%;
  height: 100%;
}


.lightcloud {
  background: transparent url('/htdocs/images1/lightcloud.png') repeat-x 0 0;
  height: 39px;
  width: 100%;
  position: absolute;
  float: left;
  lefT: 0;
  bottom: 0;
  z-index: 2;
  z-index: 0;
}


.wellcome {
  width: 322px;
  padding-top: 52px;
}

.wellcome h1 {
  font-size: 28px;
  text-shadow: 0px 1px 1px #000;
  margin: 6px 0 -1px 0;
}

.wellcome ul {
  margin: -13px 0 0 4px;
}

.wellcome li {
  background: transparent url('/htdocs/images1/level1.png') no-repeat 0 12px;
  padding: 8px 0 6px 16px;
}

.wellcome li a {
  text-decoration: none;
}

.wellcome li a:hover {
  text-decoration: underline;
}

#logo {
  display: block;
  background: transparent url('/htdocs/images1/logo.png') no-repeat 0 0;
  width: 198px;
  height: 81px;
  float: left;
  margin-top: 17px;
}

#topnav {
  float: left;
  font-size: 13px;
  width: 60%;
  height: 104px;
  margin-left: 5px;
  margin-top: 16px;
}

#topnav ul {
  padding: 0;
  margin: 0;
  float: left;
}

#topnav ul li {
  margin-right: 0px;
  padding-left: 14px;
  margin-bottom: -1px;
}

#topnav ul li a {
  text-decoration: none;
  display: block;
  background: transparent url('/htdocs/images1/level1.png') no-repeat 0 12px;
  padding: 7px 22px 7px 8px;

}

#topnav ul li:hover {
  background: transparent url('/htdocs/images1/roundleft.png') no-repeat 0 0;
}

#topnav ul li:hover a {
  color: #c2ecff;
  background: transparent url('/htdocs/images1/roundright.png') no-repeat 100% 0;
}

#lang {
  min-height: 10px;
  overflow: hidden;
  position: relative;
  /*
	float: right;
	*/
  font-size: 12px;
  margin-right: 36px;
  text-align: right;
  width: 153px;
  margin-top: -107px;
  /*
	display: inline-block;
	//display: inline;
	zoom: 1;
	*/
}

#lang2 {
  min-height: 10px;
  overflow: hidden;
  position: relative;
  /*
	float: right;
	*/
  font-size: 12px;
  margin-right: 36px;
  text-align: right;
  width: 153px;
  margin-top: -107px;
  /*
	display: inline-block;
	//display: inline;
	zoom: 1;
	*/
}

#lang a {
  position: relative;
  display: inline-block;
  zoom: 1;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
}

#lang2 a {
  position: relative;
  display: inline-block;
  zoom: 1;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
}

.ru {
  padding: 4px 0 0 8px;
  color: #a8e5ff;
  background: transparent url('/htdocs/images1/langdark.png') no-repeat 0 0;
  width: 26px;
  height: 29px;
}

.en {
  width: 25px;
  height: 27px;
  top: 10px;
  color: #4e737f;
  background: transparent url('/htdocs/images1/langlight.png') no-repeat 0 0;
  padding: 3px 0 8px 6px;
  margin-lefT: 5px;
}

#phone {
  font: bold 20px Arial;
  margin-right: 28px;
  margin-top: 7px;
  margin-bottom: 9px;
}

#auth {
  padding: 1px 0 8px 19px;
  display: block;
  font-size: 11px;
  color: #3d6f91;
  background: transparent url('/htdocs/images1/auth.png') no-repeat 0 2px;
  clear: right;
  font-size: 12px;
  margin-left: 6px;
  /*
	float: right;
	width: 131px;
	*/
}

#breadcrumbs {
  padding: 0;
  margin: 6px 0 0 -1px;
  font-size: 11px;
  float: left;
  clear: left;
  width: 100%;
}

#breadcrumbs li,
#breadcrumbs a,
#breadcrumbs span {
  display: inline-block;
  zoom: 1;
  color: #8eb1c5;
}

#breadcrumbs a {
  padding-right: 5px;
  margin-right: 0px;
  background: transparent url('/htdocs/images1/level3.png') no-repeat 100% 3px;
}

/*
#online {
	display: block;
    background: transparent url('../images1/onlinezakaz.png') no-repeat 0 0;
}
*/
/*
.online {
	background: red;
	width: 0px;
	height: 284px;
	position: absolute;
	right: 0;
	top: 7%;
	margin-top: 5px;
}
*/
.online {
  position: fixed;
  top: 24%;
  right: 0;
  margin-top: 5px;
  z-index: 10;
}

.online form h6 {
  display: block;
}

.online ul li {
  margin-top: 5px;
  margin-bottom: 12px;
  position: relative;
}

#page .online .textarea {
  margin-top: 5px;
  min-height: 54px;
  margin-bottom: 12px;
}

.slide-out-div {
  width: 264px;
  z-index: 4;
  background: #f2f4f5;
  border: 1px solid #cacaca;
  border-radius: 0 0 0 18px;
  -moz-border-radius: 0 0 0 18px;
  -webkit-border-radius: 0 0 0 18px;
  -khtml-border-radius: 0 0 0 18px;
  behavior: url(/htdocs/PIE.htc);
  padding: 28px 15px 1px 19px;
}

.handle {
  margin-top: -2px;
}

#content {
  min-height: 50px;
  overflow: hidden;
  padding-bottom: 280px;
}

#content.new-content {
  padding-bottom: 0;
}

.offer {
  margin-top: 12px;
  min-height: 50px;
  overflow: hidden;
  color: #4c4c4c;
}

.offer li {
  width: 290px;
  margin-right: 29px;
  float: left;
  /*
	width: 290px;
	margin-right: 29px;
	padding-left: 11px;
	bordeR: 1px solid blue;
	height: 17px;
}
form .input {
	height: 17px;
	*/
}

.offer li p {
  margin-left: 11px;
  margin-top: 12px;
}

.offer li img {
  float: left;
  margin-top: 18px;
}

.offer li.offerfirst img {
  margin-top: 0;
}

.offer li h2 {
  margin: 39px 0 0 0;
  line-height: 112%;
  position: relative;
  left: 10px;
  width: 70%;
}

.offer li.offerfirst h2 {
  width: 80%;
}

.offer li.offerlast {
  margin-right: 0;
  margin-left: 1px;
}

.offer li.offerlast h2 {
  width: 96%;
}

/*
.offer li img, .offer li h2 {
	float: left;
}
.offer li h2 {
	display: inline-block;
	//display: inline;
	zoom: 1;
}
.offer li p{
	border: 1px solid red;
}
*/
#column_left,
#column_right {
  float: left;
}

#column_left {
  width: 240px;
  margin-bottom: 22px;
}

#column_left ul {
  margin-top: 17px;
  margin-left: 1px;
}

#column_left ul li {
  background: transparent url('/htdocs/images1/level1.png') no-repeat 0 7px;
  padding: 2px 0 9px 16px;
  line-height: 138%;
}

#column_left ul li a {
  text-decoration: none;
}

#column_left ul li a:hover {
  text-decoration: underline;
}

#column_right {
  width: 700px;
  margin-bottom: 15px;
}

#column_right ul {
  margin-top: 21px;
}

#column_right ul li {
  /*background: transparent url('../images1/level2.png') no-repeat 0 7px;*/
  padding: 0 0 0 15px;
  line-height: 143%;
}

#column_right ul.contacts {
  width: 100%;
  margin: 57px 0 0 0;
  padding: 0;
  min-height: 50px;
  overflow: hidden;
}

#column_right ul.contacts li {
  background: none;
  padding: 0 0 14px 7px;
  margin: 0;
  float: left;
  width: 40%;
}

#column_right ul.contacts li.even {
  width: 57%;
}

#column_right ul.contacts li img {
  margin-top: 4px;
  margin-right: 16px;
  margin-bottom: 42px;
  float: left;
}

#column_right ul.contacts li p {
  margin: 0;
  padding: 0;
}

#column_right ul.contacts li h6,
.online h6 {
  display: inline-block;
  zoom: 1;
  padding: 0 0 0 9px;
}

#column_right ul.contacts li li {
  background: none;
  padding: 0;
  margin: 0;
  float: left;
  width: 40%;
}

#column_right ul.contacts li ul {
  margin-top: 3px;
}

#column_right ul.contacts li.last {
  clear: left;
  width: 100%;
}

#column_right ul.contacts li.last img {
  margin-bottom: 222px;
}

#column_right ul.contacts li.last li {
  width: 45%;
  margin-right: 31px;
}

#column_right ul.contacts li.last li .input {
  width: 100%;
}

/*
#column_right ul.contacts li.last li {
	width: 45%;
	margin-right: 39px;
}
*/
#column_right ul.contacts li form {
  margin-top: 15px;
}

#column_right form {
  margin-bottom: 17px;
}

form .input,
form textarea {
  bordeR: 2px solid #c0c9cf;
  border-radius: 13px;
  -moz-border-radius: 13px;
  -webkit-border-radius: 13px;
  -khtml-border-radius: 13px;
  padding: 5px 7px 5px 8px;
  behavior: url(/htdocs/PIE.htc);
  color: #666;
  font-style: italic;
  font-size: 11px;
  background: #f2f4f5;
  width: 90%;
}

#column_right form ul {
  min-height: 37px;
  overflow: hidden;
}

#column_right ul.contacts li.last textarea {
  margin-top: 3px;
  min-height: 94px;
  width: 85%;
  padding-right: 5px;
}

form input.submit {
  border-radius: 18px;
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  -khtml-border-radius: 18px;
  text-align: center;
  text-shadow: 1px 1px 1px #fff;
  behavior: url(/htdocs/PIE.htc);
  bordeR: none;
  color: #333;
  font: bold 14px Arial;
  padding: 11px 31px 12px 28px;
  background: transparent url('/htdocs/images1/submit.png') repeat-x 0 0;
  margin-right: 12px;
  margin-top: 17px;
  width: auto;
  cursor: pointer;
}

#footer {
  background: #d8edf5 url('/htdocs/images1/foot.png') repeat-x 0 0;
  width: 100%;
  position: absolute;
  bottom: 0px;
  float: left;
  lefT: 0;
  bottom: 0;
  z-index: 2;
}

#foot {
  width: 980px;
  margin: 0 auto;
  text-align: left;
  /*	height: 182px;*/
  color: #4d4d4d;
}

#foot .block {
  float: left;
  width: 30%;
  width: 279px;
  padding: 22px 0 0 41px;
  height: 136px;
}

#foot .block:first-child {
  width: 279px;
  padding-left: 0;
}

#foot .block ul {
  margin-top: 14px;
  margin-left: -2px;
}

#foot .block ul li {
  display: inline-block;
  zoom: 1;
  margin-right: 7px;
}

#foot .coal {
  background: transparent url('/htdocs/images1/coal.png') no-repeat 0 0;
}

#foot .align_center {
  font-size: 11px;
  margin: 5px 0 20px 0;
}

#foot p {
  padding: 0;
  margin: 0;
}

#foot a {
  color: #4c4c4c;
}








/* change select */
.calculate {
  background: #7799b0;
  width: 232px;
  position: absolute;
  float: right;
  right: 17%;
  /*	
	right: 20%;
	margin-right: 3px;
	height: 300px;
*/
  top: 31%;
  margin-top: 64px;
  z-index: 1;
  z-index: 0;
  border-radius: 18px;
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  -khtml-border-radius: 18px;
  behavior: url(/htdocs/PIE.htc);
  color: #fff;
  text-align: left;
  padding: 13px 16px 16px 16px;
}

.calculate h4 {
  color: #fff;
  text-align: center;
  margin: 2px 0 16px 0;
  text-shadow: 0px 1px 0px #020202;
}

.calculate p {
  padding: 0 0 6px 7px;
  font-size: 12px;
  margin: 0 0 0 0;
}

.calculate .input,
.calculate textarea {
  background: #546b7b;
  bordeR: none;
  color: #fff;
  font-style: normal;
  overflow: auto;
}

.calculate textarea {
  width: 218px;
}

.calculate .float_left {
  position: relative;
  z-indeX: 10;
}

.hidden {
  display: none;
}

#curr_list,
#curr_list1,
#curr_list2 {
  position: absolute;
  left: 0px;
  background: #495e6c;
  border-radius: 0 0 18px 18px;
  -moz-border-radius: 0 0 18px 18px;
  -webkit-border-radius: 0 0 18px 18px;
  -khtml-border-radius: 0 0 18px 18px;
  behavior: url(/htdocs/PIE.htc);
  top: 13px;
  width: 232px;
  /*	padding-bottom: 14px;*/
  z-index: 2;
}

#curr_list,
#curr_list1 {
  width: 110px;
}

#curr_list a.last,
#curr_list1 a.last,
#curr_list2 a.last,
#curr_list a.last span,
#curr_list1 a.last span,
#curr_list2 a.last span {
  bordeR: none;
}

#curr_list a.last span,
#curr_list1 a.last span,
#curr_list2 a.last span {
  padding-bottom: 10px;
}

#curr_list a.last:hover,
#curr_list1 a.last:hover,
#curr_list2 a.last:hover

/*, #curr_list a.last:hover span, #curr_list1 a.last:hover span, #curr_list2 a.last:hover span */
  {
  border-radius: 0 0 18px 18px;
  -moz-border-radius: 0 0 18px 18px;
  -webkit-border-radius: 0 0 18px 18px;
  -khtml-border-radius: 0 0 18px 18px;
  behavior: url(/htdocs/PIE.htc);
}

#curr_list a.first span,
#curr_list1 a.first span,
#curr_list2 a.first span {
  padding-top: 19px;
}

.float_left .dropdown {
  width: 114px;
}

.dropdown {
  position: relative;
  width: 212px;
  lefT: 0;
  z-index: 6;
  font-size: 11px;
}

.dropdown a {
  display: block;
  color: #fff;
  text-decoration: none;
  position: relative;
  border-bottom: 1px solid #6a7b87;
}

.dropdown a span {
  display: block;
  border-bottom: 1px solid #3a4b56;
  padding: 4px 10px 5px 10px;
}

.dropdown a:hover {
  background: #5e7381;
}

.dropdown a:hover span {}

#curr_selected,
#curr_selected1,
#curr_selected2 {
  width: 100%;
  color: #fff;
  padding: 5px 10px 7px 10px;
  cursor: pointer;
  height: 12px;
  background: #546b7b;
  background: #546b7b url('/htdocs/images1/arrow.png') no-repeat 97% 3px;
  border-radius: 18px;
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  -khtml-border-radius: 18px;
  behavior: url(/htdocs/PIE.htc);
  position: relative;
  margin: 0 0 4px 0;
  z-index: 3;
}

#curr_selected2 {
  background: #546b7b url('/htdocs/images1/arrow.png') no-repeat 99% 3px;
  width: 212px;
}

#curr_selected,
#curr_selected1 {
  width: 90px;
}

#curr_selected1б #curr_list1 {
  margin-lefT: 8px;
}

#curr_selected a,
#curr_selected1 a,
#curr_selected2 a {

  background: none;
  padding: 0;
}

#dd_currency,
#dd_currency1,
#dd_currency2 {
  /*
	width: 171px;
	background-position: 138px 8px;
	height: 15px;
	margin-top: 5px;
	*/
}










/*****************************/
#amount {
  background: none;
  bordeR: none;
  color: #fff;
  font-style: italic;
  text-align: center;
  width: 100%;
  margin: 4px 0 0 0;
  font-size: 11px;
}

.ui-slider {
  position: relative;
  text-align: left;
  margin: 9px 0 0 0;
  text-indent: -900px;
  font-size: 0px;
  line-height: 0%;
}

.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 18px;
  height: 19px;
}

.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: .7em;
  display: block;
  border: 0;
  background-position: 0 0;
}

.ui-slider-horizontal {
  height: 8px;
  border-radius: 18px;
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  -khtml-border-radius: 18px;
  behavior: url(/htdocs/PIE.htc);
}

.ui-slider-horizontal .ui-slider-handle {
  top: -5px;
  margin-left: -.6em;
}

.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
  border-radius: 18px;
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  -khtml-border-radius: 18px;
  behavior: url(/htdocs/PIE.htc);
  border-left: 1px solid #546b7b;
}

.ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}

.ui-slider-vertical {
  width: .8em;
  height: 100px;
}

.ui-slider-vertical .ui-slider-handle {
  left: -.3em;
  margin-left: 0;
  margin-bottom: -.6em;
}

.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%;
}

.ui-slider-vertical .ui-slider-range-min {
  bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max {
  top: 0;
}


.ui-widget {}

.ui-widget .ui-widget {
  font-size: 1em;
}

.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {}

.ui-widget-content {
  background: #546b7b;
  color: #222222;
}

.ui-widget-content a {
  color: #222222
  /*{fcContent}*/
  ;
}

.ui-widget-header {
  background: transparent url('/htdocs/images1/polzunokbg.png') repeat 0 0;
  color: #222222;
}

.ui-widget-header a {
  color: #222222
  /*{fcHeader}*/
  ;
}




/* Interaction states
----------------------------------*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background: transparent url('/htdocs/images1/arrowdouble.png') no-repeat 0 0;
  font-weight: normal
  /*{fwDefault}*/
  ;
  color: #555555
  /*{fcDefault}*/
  ;
}

.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
  color: #555555
  /*{fcDefault}*/
  ;
  text-decoration: none;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  background: transparent url('/htdocs/images1/arrowdouble.png') no-repeat 0 0;
  font-weight: normal
  /*{fwDefault}*/
  ;
  color: #212121
  /*{fcHover}*/
  ;
}

.ui-state-hover a,
.ui-state-hover a:hover {
  color: #212121
  /*{fcHover}*/
  ;
  text-decoration: none;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  background: transparent url('/htdocs/images1/arrowdouble.png') no-repeat 0 0;
  font-weight: normal
  /*{fwDefault}*/
  ;
  color: #212121
  /*{fcActive}*/
  ;
}

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  color: #212121
  /*{fcActive}*/
  ;
  text-decoration: none;
}

.ui-widget :active {
  outline: none;
}

div.fileinputs {
  position: relative;
  left: -8px;
}

div.fakebtn {
  position: absolute;
  top: 0px;
  left: 10px;
  z-index: 1;
  cursor: pointer;
}

input.file {
  position: absolute;
  top: 0px;
  left: 20px;
  z-index: 3;
}

input.fakefile {
  position: relative;
  -moz-opacity: 0;
  opacity: 0;
  z-index: 3;
}

div.faketext {
  position: absolute;
  top: 0px;
  left: 20px;
  z-index: 5;
}

input.browsetext {
  background: transparent;
  /*
 height: 26px;
 */
  width: 160px;
  border: none;
  font-size: 11px;
  font-style: italic;
  color: #666;
  padding-top: 7px;
}


/*FIX*/
/*** Стили относящиеся к модальному окну и содержанию ***/
.popup h1 {
  /* заголовок 1 */
  color: #008000;
  text-align: left;
  text-shadow: 0 1px 3px rgba(0, 0, 0, .3);
  font: 24px 'Trebuchet MS', Helvetica, sans-serif;
  font-weight: bold;
}

.popup h2 {
  /* заголовок 2 */
  color: #008000;
  text-align: left;
  text-shadow: 0 1px 3px rgba(0, 0, 0, .3);
  font: 22px 'Trebuchet MS', Helvetica, sans-serif;
}

/* Базовые стили слоя затемнения и модального окна  */
.overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  visibility: hidden;
  /* фон затемнения */
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  position: fixed;
  /* фиксированное поцизионирование */
  cursor: default;
  /* тип курсара */
  -webkit-transition: opacity .5s;
  -moz-transition: opacity .5s;
  -ms-transition: opacity .5s;
  -o-transition: opacity .5s;
  transition: opacity .5s;
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}

.is-image {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  width: 100%;
  height: auto;
  /* скругление углов встроенных картинок */
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

/* встроенные элементы м-медиа, фреймы */
embed,
iframe {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  min-width: 320px;
  /*max-width: 600px;*/
  width: 100%;
}

/* стили модального окна */
.popup {
  top: 0;
  right: 0;
  left: 0;
  font-size: 14px;
  z-index: 10;
  display: block;
  visibility: hidden;
  margin: 0 auto;
  width: 90%;
  min-width: 250px;
  max-width: 320px;
  /* фиксированное позиционирование, окно стабильно при прокрутке */
  position: fixed;
  padding: 15px;
  border: 1px solid #383838;
  /* скругление углов */
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  background-color: #FFFFFF;
  /* внешняя тень */
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
  -ms-box-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
  -o-box-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.8);
  /* полная прозрачность окна, появление при клике */
  opacity: 0;
  /* эффект перехода (появление) */
  -webkit-transition: all ease .5s;
  -moz-transition: all ease .5s;
  -ms-transition: all ease .5s;
  -o-transition: all ease .5s;
  transition: all ease .5s;
}

.overlay:target+.popup {
  top: 20%;
  visibility: visible;
  opacity: 1;
}

/* формируем кнопку закрытия */
.close {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
  background-color: rgba(61, 61, 61, 0.8);
  -webkit-box-shadow: 0px 0px 10px #000;
  -moz-box-shadow: 0px 0px 10px #000;
  box-shadow: 0px 0px 10px #000;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  line-height: 20px;
  -webkit-transition: all ease .8s;
  -moz-transition: all ease .8s;
  -ms-transition: all ease .8s;
  -o-transition: all ease .8s;
  transition: all ease .8s;
}

.close:before {
  color: rgba(255, 255, 255, 0.9);
  content: "X";
  text-shadow: 0 -1px rgba(0, 0, 0, 0.9);
  font-size: 12px;
}

.close:hover {
  background-color: rgba(252, 20, 0, 0.8);
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
  border: 5px solid #ff0000;
}

.popup p,
.popup div {
  margin-bottom: 10px;
}








/*СТИЛИ МОДАЛЬНЫХ ОКОН, кнопок задать вопрос, заказать звонок*/

/*Заказать звонок*/
#popup-form {
  display: none;
  height: 100%;
  background-image: url('qsc.png');
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  /*эффект затемнённого фона*/
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: opacity .5s;
  -moz-transition: opacity .5s;
  -ms-transition: opacity .5s;
  -o-transition: opacity .5s;
  transition: opacity 0.5s;
}

#popup-form-inner {
  background: #ffffff;
  padding: 10px;
  position: fixed;
  top: 50%;
  left: 55%;
  margin-top: -275px;
  margin-left: -225px;
  border-radius: 5px;
}

a#closeX {
  padding: 0 4px;
  float: right;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  background: #4166A7;
  font-family: Calibri;
  font-weight: bold;
}


/*Задать вопрос*/
#popup-form2 {
  display: none;
  height: 100%;
  background-image: url('qsc.png');
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  /*эффект затемнённого фона*/
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: opacity .5s;
  -moz-transition: opacity .5s;
  -ms-transition: opacity .5s;
  -o-transition: opacity .5s;
  transition: opacity 0.5s;
}

#popup-form-inner2 {
  background: #ffffff;
  padding: 10px;
  position: fixed;
  top: 50%;
  left: 55%;
  margin-top: -275px;
  margin-left: -225px;
  border-radius: 5px;
}

a#closeX2 {
  padding: 0 4px;
  float: right;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  background: #4166A7;
  font-family: Calibri;
  font-weight: bold;
}


/*Задать вопрос*/
#popup-form3 {
  display: none;
  height: 100%;
  background-image: url('qsc.png');
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  /*эффект затемнённого фона*/
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: opacity .5s;
  -moz-transition: opacity .5s;
  -ms-transition: opacity .5s;
  -o-transition: opacity .5s;
  transition: opacity 0.5s;
}

#popup-form-inner3 {
  background: #ffffff;
  padding: 10px;
  position: fixed;
  top: 50%;
  left: 55%;
  margin-top: -275px;
  margin-left: -225px;
  border-radius: 5px;
}

a#closeX3 {
  padding: 0 4px;
  float: right;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  background: #4166A7;
  font-family: Calibri;
  font-weight: bold;
}









#closeX,
#closeX2 {
  -webkit-transition: all ease .8s;
  -moz-transition: all ease .8s;
  -ms-transition: all ease .8s;
  -o-transition: all ease .8s;
  transition: all ease .8s;
  border: 5px solid #fff;
}

#closeX:hover,
#closeX2:hover {
  background-color: rgba(252, 20, 0, 0.8);
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
  border: 5px solid #ff0000;
  border-radius: 5px;
}



/*Это всё для формы отправки файлов*/
#feedback-form {
  /* вся форма */
  width: 280px;
  padding: 0px;
  border-radius: 3px;
  background: #f1f1f1;
}

#feedback-form label {
  /* наименование полей */
  float: left;
  display: block;
  clear: right;
}

#feedback-form .w100 {
  /* поля */
  float: left !important;
  max-width: 400px;
  width: 97%;
  margin-bottom: 1em;
  padding: 1.5%;
}

#feedback-form .border {
  /* граница полей */
  border-radius: 1px;
  border-width: 1px;
  border-style: solid;
  border-color: #C0C0C0 #D9D9D9 #D9D9D9;
  box-shadow: 0 1px 1px rgba(255, 255, 255, .5), 0 1px 1px rgba(0, 0, 0, .1) inset;
}

#feedback-form .border:focus {
  outline: none;
  border-color: #abd9f1 #bfe3f7 #bfe3f7;
}

#feedback-form .border:hover {
  border-color: #7eb4ea #97cdea #97cdea;
}

#feedback-form .border:focus::-moz-placeholder {
  /* убрать при фокусе первоначальный текст поля */
  color: transparent;
}

#feedback-form .border:focus::-webkit-input-placeholder {
  color: transparent;
}

#feedback-form .border:not(:focus):not(:hover):valid {
  /* правильно заполненные поля */
  opacity: .8;
}

#submitFF {
  /* кнопка "Отправить" */
  padding: 2%;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .2) inset;
  background: #669acc;
  color: #fff;
}

#feedback-form br {
  height: 0;
  clear: both;
}

#submitFF:hover {
  background: #5c90c2;
}

#submitFF:focus {
  box-shadow: 0 1px 1px #fff, inset 0 1px 2px rgba(0, 0, 0, .8), inset 0 -1px 0 rgba(0, 0, 0, .05);
}

.uslugi_perevod {
  width: 100%;
  margin: 50px 0;
}

.uslugi_perevod tr {
  height: 40px;
  text-transform: uppercase;
}

h1 a {
  text-decoration: none;
  color: #3d6f91 !important;
}

@media (max-width: 1000px) {

  #topnav,
  .head,
  .head div:nth-child(5),
  .girl,
  #content,
  tr,
  #foot ul {
    display: flex !important;
    flex-direction: column;
  }

  .head .float_right {
    margin-top: 280px;
  }

  .calculate,
  #wrapper #page {
    position: static !important;
    order: 1;
    margin-top: 0;
  }

  .calculate {
    margin-bottom: 400px;
  }

  .head div:nth-child(5),
  #foot .align_center {
    display: none !important;
  }

  .head,
  .wellcome,
  #wrapper #page,
  .big1,
  #foot ul li,
  #foot,
  p,
  #ot,
  .otzyvy1,
  #column_right,
  .tablefooter {
    width: 100% !important;
  }

  #ot {
    height: 100% !important;
  }

  #footer {
    position: static !important;
  }

  tr {
    align-items: center;
  }

  /*body {*/
  /*    padding: 0 5px;*/
  /*}*/
  .block ul {
    display: flex;
    flex-direction: row !important;
  }

  #foot .coal,
  #footer {
    background: none !important;
  }

  #foot .block,
  #content {
    padding: 0 !important;
  }

  #column_right {
    height: auto;
  }

  .slide-out-div {
    padding-top: 5px !important;
  }

  .submit,
  .online p {
    margin-top: 5px !important;
  }

  .input1 {
    margin-bottom: 2px !important;
  }

  body .cbk-phone,
  body .cbk-phone .cbk-phone-bgr {
    width: 40px !important;
    height: 40px !important;
  }

  .cbk-phone .cbk-phone-phone .ws-icon-phone {
    font-size: 20px !important;
  }

  html body .cbk-phone .cbk-phone-phone {
    width: 10px !important;
    margin: 0px !important;
    margin-left: 10px !important;
    margin-top: 10px !important;
  }

  .online .slide-out-div,
  .online {
    position: static !important
  }
}
