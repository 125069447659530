@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {
    details>summary {
      display: flex;
    }
  }
}

.qa-it {
  border-bottom: 1px solid #7799B0; ;

  &:last-child {
    border-bottom: 0;
  }

}

.qa-it__btn {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: space-between;


  span:first-child {
    margin-right: auto;
  }

  &:hover {
    .qa-it__btn-svg {
      color: #447595;
    }
  }
}

.qa-it__btn-text {
  text-align: left;
  font-size: 16px;
  font-weight: 700;
}

.qa-it[open] {
  .qa-it__content {
    max-height: 6000px;
  }

  .qa-it__btn-svg {
    color: var(--green);
    transform: rotate(180deg);
  }

}

.qa-it__content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;

  p {
    margin-block: 10px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.qa-it__btn-svg {
  flex-shrink: 0;
  transition: transform 0.3s, color 0.3s, border-color 0.3s, background-color 0.3s;
}

.qa-it__content-inside {
  padding-block: 10px;
}


@media (max-width: calc($desktop - 1px)) {
  .qa-it {
    padding-block: 16px;
  }
}


@media (min-width: $desktop) {
  .qa-it__btn-svg { 
    width: 44px;
    height: 44px;
  }

  .qa-it__content-inside {
    padding-bottom: 24px;
    max-width: calc(100% - 140px);
  }
}