.menu__link {
  display: flex;
  color: var(--white);
  font-weight: 700;
  line-height: 1.07;
  text-transform: uppercase;
  text-decoration: none;
}

.menu__subs-link {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-block: 10px;
  padding-inline: var(--padding);
  color: var(--white);
  text-decoration: none;

  svg {
    flex-shrink: 0;
  }
}

.menu__item-span {
  display: flex;
  align-items: center;
  padding-right: var(--padding);

  svg {
    transition: transform 0.3s;
  }
}

.menu__link {
  flex-grow: 1;
}

.menu__subs {
  height: 0;
  overflow: hidden;
}

@media (max-width: calc($desktop - 1px)) {
  .menu__item.submenu-opened {
    .menu__subs {
      height: auto;
    }
  }

  .menu__link {
    padding-block: 10px;
    padding-inline: var(--padding);
  }

  .menu__subs .btn--blue-bd {
    margin-left: var(--padding);
    width: fit-content;
    color: var(--white);
    border-color: var(--white);
  }
}

@media (min-width: $desktop) {
  .menu {
    display: flex;
    align-items: center;
    gap: 30px;
    position: relative;
    z-index: 4;
  }

  .menu__subs {
    position: absolute;
    z-index: 1;
    top: 100%;
    width: calc(100% + var(--padding));
    display: flex;
    height: auto;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
  }

  
  .menu__subs .btn--blue-bd {
    position:absolute;
    bottom: 30px;
    left: 20px;
  }

  .menu__subs-list {
    display: block;
    column-count: 4;
    gap: 10px;
    margin-top: 23px;
    padding-inline: 20px;
    padding-top: 5px;
    padding-bottom: 80px;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    border-radius: 0 0 10px 10px;
    filter: drop-shadow(0px 4px 59.1px rgba(2, 58, 94, 0.20)); 
  }

  .menu__item-span {
    gap: 8px;
  }

  .menu__subs-link {
    padding-inline: 0;
    color: var(--text);
    transition:  color 0.3s;

    &:hover {
      color: var(--blue);
    }
  }

  .menu__item {
    &:hover .menu__subs {
      visibility: visible;
      opacity: 1;
    } 

    &:hover {
      .menu__item-span svg {
        transform: rotate(180deg);
      }
    }
  }

  .menu__link {
    transition: color 0.3s;

    &:hover {
      color: var(--yellow);
    }
  }
}

@media (min-width: 1270px) {
  .menu__subs {
  width: 712px;
  }
}