.popup-form__btn {
  background: #FEB021;
  border-radius: 35px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 14px 43px;
  display: inline-block;
  cursor: pointer;
  margin-top: 24px;
  border: 0;
}

/*Заказать звонок*/
#popup-form {
  display: none;
  height: 100%;
  background-image: url('qsc.png');
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  /*эффект затемнённого фона*/
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: opacity .5s;
  -moz-transition: opacity .5s;
  -ms-transition: opacity .5s;
  -o-transition: opacity .5s;
  transition: opacity 0.5s;
}

#popup-form-inner {
  background: #ffffff;
  padding: 10px;
  position: fixed;
  top: 50%;
  left: 55%;
  margin-top: -275px;
  margin-left: -225px;
  border-radius: 5px;
}

a#closeX {
  padding: 0 4px;
  float: right;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  background: #4166A7;
  font-family: Calibri;
  font-weight: bold;
}


/*Задать вопрос*/
#popup-form2 {
  display: none;
  height: 100%;
  background-image: url('qsc.png');
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  /*эффект затемнённого фона*/
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: opacity .5s;
  -moz-transition: opacity .5s;
  -ms-transition: opacity .5s;
  -o-transition: opacity .5s;
  transition: opacity 0.5s;
}

#popup-form-inner2 {
  background: #ffffff;
  padding: 10px;
  position: fixed;
  top: 50%;
  left: 55%;
  margin-top: -275px;
  margin-left: -225px;
  border-radius: 5px;
}

a#closeX2 {
  padding: 0 4px;
  float: right;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  background: #4166A7;
  font-family: Calibri;
  font-weight: bold;
}


/*Задать вопрос*/
#popup-form3 {
  display: none;
  height: 100%;
  background-image: url('qsc.png');
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  /*эффект затемнённого фона*/
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: opacity .5s;
  -moz-transition: opacity .5s;
  -ms-transition: opacity .5s;
  -o-transition: opacity .5s;
  transition: opacity 0.5s;
}

#popup-form-inner3 {
  background: #ffffff;
  padding: 10px;
  position: fixed;
  top: 50%;
  left: 55%;
  margin-top: -275px;
  margin-left: -225px;
  border-radius: 5px;
}

a#closeX3 {
  padding: 0 4px;
  float: right;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  background: #4166A7;
  font-family: Calibri;
  font-weight: bold;
}

#closeX,
#closeX2 {
  -webkit-transition: all ease .8s;
  -moz-transition: all ease .8s;
  -ms-transition: all ease .8s;
  -o-transition: all ease .8s;
  transition: all ease .8s;
  border: 5px solid #fff;
}

#closeX:hover,
#closeX2:hover {
  background-color: rgba(252, 20, 0, 0.8);
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
  border: 5px solid #ff0000;
  border-radius: 5px;
}


/*Форма*/

.popup-form__container {
  display: none;
  background-image: url('qsc.png');
  position: fixed;
  z-index: 1002;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: opacity .5s;
  -moz-transition: opacity .5s;
  -ms-transition: opacity .5s;
  -o-transition: opacity .5s;
  transition: opacity 0.5s;
}

.popup-form {
  width: 310px;
  padding: 25px;
  background: #F2F4F5;
  border-radius: 10px;
  position: relative;
}

.popup-form .success {
  text-align: center;
  font-size: 16px;
  color: #4b4;
  font-weight: bold;
}


.popup-form__container.opened {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-form__title {
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #447595;
  margin-bottom: 32px;
  padding-right: 40px;
}

.popup-form__close {
  width: 15px;
  height: 15px;
  background-image: url('/htdocs/images/topform/popup-close.svg');
  background-size: cover;
  position: absolute;
  top: 25px;
  right: 25px;
  opacity: 0.5;
  cursor: pointer;
  transition: 1s;
}

.popup-form__close:hover {
  transform: rotate(180deg);
}

.popup-form__row {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.popup-form__row_error {
  display: none;
  font-size: 12px;
  padding-top: 5px;
  color: #b44;
}

.popup-form__row.errors .popup-form__row_error {
  display: block;
}

.popup-form__row label,
.popup-form__section label {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  color: #447595;
  margin-bottom: 10px;
  text-align: left;
}


.popup-form__row input[type='text'],
.popup-form__row input[type='tel'],
.popup-form__row input[type='email'],
.popup-form__row textarea {
  padding: 10px;
  background: #FFFFFF;
  border-radius: 5px;
  border: 0;
  width: 100%;
}

.errors.popup-form__row input[type='text'],
.errors.popup-form__row input[type='tel'],
.errors.popup-form__row input[type='email'],
.errors.popup-form__row textarea {
  background-color: rgba(230, 110, 110, 0.2);
}

.errors.popup-form__row .side-form__form_input_error {
  display: block;
}

.popup-form__row textarea {
  resize: vertical;
}

.popup-form__btn {
  margin: 25px auto 0 auto;
  display: block;
}

.popup-form__section {
  margin-bottom: 25px;
}

.popup-form__section_title {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.2;
  color: #333333;
  margin-bottom: 15px;
}

.popup-form__personal {
  font-family: Arial;
  font-size: 10px;
  line-height: 1.2;
  margin-top: 25px;
}

.popup-form__personal a {
  color: #447595;
  font-size: 10px;
  line-height: 1.2;
}


.popup-form__container.side-form {
  display: flex;
  left: unset;
  background: unset;
  bottom: unset;
  top: 10%;
  align-items: flex-start;
  right: -317px;
  transition: 1s;
  z-index: 1001;
}

.popup-form__container.side-form .popup-form {
  border-radius: 10px 0 0 10px;
}

.popup-form__container.side-form.opened {
  right: 0;
}

.popup-form .input-file-list-remove {
  color: black;
}

.popup-form .input-file-list-item:last-child {
  margin-bottom: 0;
  flex-basis: 100%;
}

.side-form__form.order_form .side-form__form_row label.choose_file,
.popup-form__row .choose_file {
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #447595;
  padding: 7px 0;
  text-align: center;
  margin-bottom: 0;
}

.popup-form .popup-form__row .choose_file {
  margin-top: 5px;
}

.popup-form__row .choose_file+input {
  display: none;
}

.popup-form__row .choose_file {
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #447595;
  padding: 7px 0;
  text-align: center;
  margin-bottom: 0;
}

@media (max-width: 800px) {

  .popup-form__container.side-form {
    top: 80px;
  }

  .popup-form__container.side-form .popup-form__section_title {
    display: none;
  }

  .popup-form__container.side-form .popup-form__section {
    margin-bottom: 0;
  }

  .popup-form__container.side-form .popup-form__row {
    margin-bottom: 8px;
  }

}

.popup-form__container.side-form::before {
  content: '';
  position: absolute;
  bottom: -7px;
  right: 0;
  width: calc(100% + 7px);
  height: calc(100% - 4px);
  background-color: var(--blue);
  border-radius: 0 0 0 10px;
}

.side-form__btn {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 10px;
  left: -40px;
  width: 40px;
  height: 180px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  border: 0;
  background: var(--orange);
  border-radius: 30px 0 0 30px;
  text-transform: uppercase;
  color: var(--white);
  cursor: pointer;
  overflow: hidden;
}

.side-form__btn-text {
  padding: 10px 28px 10px 10px;
  background: var(--orange);
  height: 40px;
  width: 140px;
  transform: rotate(-90deg) translateX(-42px) translateY(-48px);
}

.side-form__btn-svg {
  display: flex;
  padding-left: 13px;
  padding-top: 7px;
  margin-top: auto;
  width: 40px;
  height: 40px;
  background-color: var(--blue);
}

.side-form__toggler.btn {
  margin-bottom: 15px;
  width: 100%;
}

.side-form__wrap {
  display: flex;
}

.popup-form__form {
  flex-basis: 100%;
  flex-shrink: 0;
  opacity: 0;
}

.sub-moved {
  .side-form__toggler svg {
    transform: scale(-1, 1);
  }
}

.sub-moved .side-form__contacts {
  opacity: 0;
}

.sub-moved  .side-form__wrap {
  transform: translateX(-260px);
}

.sub-moved .popup-form__form {
  opacity: 1;
}


@media (max-width: 800px) {
  .side-form__btn {
    top: 190px;
  }
}