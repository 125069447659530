.services-menu {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
}

.services-menu__item {
  display: flex;
  flex-direction: column;
}

.services-menu__span {
  display: flex;
  padding-inline: var(--padding);
  width: 100%;
  color: var(--text);
}

.services-menu__link,
.services-menu__subs-it {
  display: flex;
  flex-grow: 1;
  padding-block: 10px;
  color: var(--text);
  text-decoration: none;
}

.services-menu__subs-it {
  padding-inline: var(--padding);
}

.services-menu__subs {
  overflow: hidden;
  height: 0px;
}

.services-menu__btn span {
  display: none;
}

.services-menu__subs-list li {
  display: flex;
  break-inside: avoid;
}


@media (max-width: calc($desktop - 1px)) {
  .services-menu {
    height: 0;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s;
  }

  .services-menu.opened {
    height: auto;
    max-height: 5000px;
  }

  .services-menu__item.submenu-opened {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--white);
    height: calc(100svh - 127px);

    .services-menu__link {
      font-size: 18px;
      font-weight: 700;
    }

    .services-menu__subs{
      height: 100%;
    }
  }

  .services-menu__subs  {
    overflow-y: auto;
  }

  .services-menu__span.opened {
    flex-wrap: wrap;
    row-gap: 20px;

    .services-menu__link {
      flex-basis: 100%;
    }
  }

  .services-menu__span.opened .services-menu__btn {
    order: -1;
    padding-top: 10px;
    
    span {
      display: block;
    }

    svg {
      order: -1;
      transform: rotate(90deg);
    }
  }

  @media (min-width: $tablet) {
    @media (max-width: 1023px) {
      .services-menu__item.submenu-opened {
        height: calc(100svh - 85px);
      }
    }
  }
}

@media (min-width: $desktop) {
  .services-menu {
    z-index: 4;
    background-color: transparent;
    filter: drop-shadow(0px 4px 59.1px rgba(2, 58, 94, 0.20)); 
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;

    &.opened {
      visibility: visible;
      opacity: 1;
    }
  }

  .services-menu__btn {
    transform: rotate(-90deg);
    transition: color 0.3s;
  }

  .services-menu__link {
    padding-left: 20px;
  }

  .services-menu__span {
    gap: 10px;
    padding-right: 20px;
  }

  .services-menu__item {
    width: 300px;
    background-color: var(--white);
    transition: background-color 0.3s;

    &:last-child {
      padding-bottom: 30px;
      border-radius: 0 0 10px 10px;
    }

    &:hover {
      background-color: var(--blue-light);

      .services-menu__btn {
        color: var(--blue);
      }

      .services-menu__subs {
        visibility: visible;
        opacity: 1;
        filter: drop-shadow(0px 4px 59.1px rgba(2, 58, 94, 0.20)); 
      }
    }
  }

  .services-menu__subs-it {
    padding-inline: 20px;
    transition: color 0.3s;

    &:hover {
      color: var(--blue);
    }
  }

  .services-menu__subs {
    position:absolute;
    top: 0;
    left: 99%;
    display: flex;
    height: auto;
    min-height: 100%;
    width: calc(100svw - 183px - 300px - var(--padding) * 2);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .services-menu__subs-list {
    display: block;
    column-count: 2;
    gap: 10px;
    padding-bottom: 30px;
    width: 100%;
    background-color: var(--white);
    border-radius: 0 0 10px 10px;
    filter: drop-shadow(0px 4px 59.1px rgba(2, 58, 94, 0.20)); 
  }
}

@media (min-width: 1270px) {
  .services-menu__subs {
    width: calc(1270px - 183px - 300px - var(--padding) * 2);
  }
}