@media (max-width: 1580px) {
  .main_slide .text {
    max-width: 500px;
  }

  .main_slide .text h1,
  .main_slide .text span:first-child {
    font-size: 20px;
  }

  .main_slide .text span:nth-child(2) {
    font-size: 30px;
    line-height: 40px;
  }

}

@media (max-width: 1300px) {
  .history__list {
    flex-direction: column;
  }

  .history__item,
  .history__item:nth-child(even) {
    flex-direction: column-reverse;
    padding: 0 0 36px 64px;
    height: unset;
    align-items: flex-start;
    margin: 0;
  }

  .history__item:last-child {
    padding-bottom: 0;
  }


  .history__item::before,
  .history__item:nth-child(even)::before {
    left: 22px;
    bottom: unset;
    top: 10px;
  }

  .history__item::after,
  .history__item:nth-child(even)::after {
    width: 3px;
    height: unset;
    top: 0;
    bottom: 0;
    left: 29px;
    right: unset;
  }

  .history__item:first-child::after {
    left: 29px;
    top: 10px
  }

  .history__item:last-child::after {
    bottom: calc(100% - 10px);
    right: unset;
  }

  .history__text {
    margin: 0;
  }

  .history__year {
    font-size: 40px;
  }

  .delivery_item {
    padding: 16px;
  }

  .delivery_blockr::after,
  .service-header.delivery_header::after {
    left: auto;
    right: 24px;
  }

}

@media (max-width: 1200px) {
  .payment_list .list {
    flex-wrap: wrap;
  }

  .container,
  .main_slide .container {
    max-width: 100%;
  }

  .main_reviews_block {
    flex-wrap: wrap;
  }

  .main_reviews_block .reviews_list .item {
    margin-bottom: 16px;
    overflow: hidden;
  }

  .main_reviews_block .reviews_list .item:after {
    content: '';
    display: none;
  }

  .about-header__container {
    background: unset;
  }

  .about-header {
    background: unset;
  }

  .about-header__container {
    flex-direction: column;
  }

  .about-header__content {
    width: 100%;
    margin: 0 0 16px 0;
  }

  .about-header__catchphrase {
    width: 100%;
    padding: 20px 10px;
    font-size: 30px;
  }

  .about-header {
    padding: 16px 0 40px 0;
  }

  .choose-city__cities {
    gap: 15px 27px;
  }

  .choose-city__item {
    width: calc(calc(100% - 27px * 3) / 4);
  }
}

@media (max-width: 1470px) {
  .main_slide .side-form__form.order_form {
    width: 460px;
  }
}

@media (max-width: 1370px) {

  .side-form__form.order_form {
    width: 460px;
  }

  .service-header__text-right_wrapper {
    width: 100%;
  }

  .service-header__video-wrapper {
    display: none;
  }

  .bottom_text {
    width: 100%;
  }

  .service-header__info_right::before {
    display: none;
  }

  .service-header__text-wraper {
    width: 100%;
  }

  .service-header__form {
    left: 0;
  }

  .service-header__info_right {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    justify-items: center;
  }

  .service-header__block_title {
    width: 50%;
    justify-content: center;
    padding: 19px 0;
  }

  .service-header__price_h3 {
    font-size: 15px;
  }

  .service-header__text_h1 {
    font-size: 25px;
    padding-left: 15px;
  }
}

@media (max-width: 1200px) {
  .service-header__text_h1 {
    font-size: 20px;
  }
}

@media (max-width: 950px) {
  .service-header-new .container {
    display: grid;
    gap: 40px;
  }

  .service-header__text_h1 {
    font-size: 33px;
  }

  .service-header__video {
    width: 800px;
    height: 450px;
  }

  .service-header__block_title {
    width: 100%;
    justify-content: center;
    padding-right: unset;
  }

  .service-header__info_left {
    justify-content: center;
  }

  .service-header__info_right {
    justify-items: center;
    grid-template-columns: unset;
  }

  .service-header__info_right::before {
    display: none;
  }

  .service-header__video-right {
    display: none;
  }

  .service-header__video-wrapper {
    width: 0;
    height: 0;
  }

  .service-header__text-wraper {
    width: 100%;
  }

  .bottom_text {
    width: unset;
  }

  .service-header__form {
    left: 0;
    margin-left: 16px;
  }

  .service-header__text-right {
    padding-bottom: 120px;
    margin-bottom: -100px;
  }

  .service-header__price_title {
    padding-top: 100px;
  }
}

@media (max-width: 810px) {

  .service-header__video {
    width: 640px;
    height: 360px;
  }
}

@media (max-width: 730px) {
  .service-header__text_h1 {
    font-size: 25px;
  }

  .service-header__price_h3 {
    font-size: 13px;
  }

  .service-header__price_title {
    font-size: 20px;
  }

  .delivery_list {
    grid-template-columns: auto;
  }
}

@media (max-width: 670px) {

  .service-header__video {
    width: 480px;
    height: 270px;
  }
}

@media (max-width: 630px) {
  .service-header__video {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .service-header__form {
    margin-left: 0;
  }

  .side-form__form.service-header__form.order_form .side-form__form_row>label {
    white-space: unset;
  }

  .service-header__form .field_list {
    align-items: flex-end;
  }
}

@media (max-width: 530px) {
  .service-header__text_h1 {
    font-size: 20px;
  }

  .service-header__price_h3 {
    font-size: 8px;
    margin-right: 10px;
  }

  .service-header__price_title {
    padding-top: 85px;
    font-size: 15px;
  }
}


@media (max-width: 500px) {

  .service-header__video {
    aspect-ratio: 16/9;
    width: unset;
    height: unset;
  }
}

@media (max-width: 430px) {
  .service-header__price_title {
    padding-top: 74px;
  }

  .service-header__text_h1 {
    font-size: 17px;
  }
}


@media (max-width: 400px) {
  .service-header__video-frame {
    min-width: unset;
  }
}




@media (max-width: 1400px) {
  .service-header__info {
    padding-right: 50px;
  }
}

@media (max-width: 1024px) {

  .open-form__field,
  .open-form__form .orange-btn {
    width: 48%;
  }

  .service-header .container {
    flex-direction: column;
  }

  .service-header__info {
    padding-right: 0;
  }

  .side-form__form {
    width: 100%;
  }

  .service-header__info {
    display: flex;
    flex-direction: column;
  }

  .service-header__title {
    order: 1;
  }

  .service-header__text {
    order: 2;
    margin-bottom: 15px;
  }

  .plusses-block li {
    width: 100%;
    margin-bottom: 20px;
  }

  .service-header__price {
    order: 3;
  }

  .types-block__item {
    width: 48%;
  }

  .service-text__columns {
    flex-direction: column;
  }

  .service-text__column {
    flex-basis: 100%;
  }

  .service-text__columns li:nth-child(odd),
  .service-text__columns li:nth-child(even) {
    width: 100%;
  }

  .service-text {
    background: rgba(216, 237, 245, 0.8);
  }

  .service-text .container>*:nth-child(n+3) {
    display: none !important;
  }

  .service-text .container>*.service-text__more {
    display: block !important;
  }

  .service-text__columns>*:last-child {
    display: none !important;
  }

  .service-text__column>* {
    display: none !important;
  }

  .service-text__column>*:first-child,
  .service-text__more {
    display: block !important;
  }

  .service-text .container.opened>*:nth-child(n+3) {
    display: block !important;
  }

  .service-text .container.opened .service-text__columns>*:last-child {
    display: block !important;
  }

  .service-text .container.opened .service-text__column>* {
    display: block !important;
  }

  .service-text .opened.container>*.service-text__more {
    display: none !important;
  }

  .reviews-service__list {
    flex-direction: column;
  }

  .reviews-service__item {
    width: 100%;
    padding: 43px 170px 32px 12px;
    margin-bottom: 33px;
  }

  .reviews-service__text {
    font-size: 16px;
  }

  .main_slide .side-form__form.order_form {
    display: none;
  }

  .delivery_block,
  .service-header.delivery_header {
    padding: 70px 0 320px 0;
  }

  .delivery_block::after,
  .service-header.delivery_header::after {
    left: 50%;
    right: auto;
    transform: translate(-50%, 0);
  }

  .delivery_list {
    max-width: 100%;
  }


  .social {
    display: none;
  }

  .choose-city__cities {
    gap: 15px 30px;
  }

  .choose-city__item {
    width: calc(calc(100% - 30px * 2) / 3);
  }

  .choose-city__popup {
    padding: 30px;
  }

  .text-short-block,
  .text-short-block--main {
    margin: 20px 0;
  }

  .text-short,
  .text-short--main {
    padding: 30px 50px 30px 20px;
  }

  .service-header-new {
    padding-top: 14px;
  }
}

@media (max-width: 900px) {
  footer .top {
    flex-wrap: wrap;
  }
}

@media (max-width: 800px) {
  .payment_list .title {
    font-size: 30px;
  }

  .payment_block .container {
    flex-wrap: wrap;
  }

  .payment_block {
    padding: 26px 0;
  }

  .main_reviews_block .reviews_list.inner_list .item {
    height: auto;
    margin: 0 0 30px;
    width: 100%;
  }

  .payment_block .image {
    width: 100%;
    margin: 0 0 26px;
  }

  .payment_block .text {
    margin: 0 0 26px;
  }

  .payment_block .text div:first-child {
    margin-bottom: 16px;
  }

  .payment_list .list .item {
    width: 100%;
    margin: 0 0 26px;
  }

  .review_form .open-form__form .open-form__field:nth-child(2) {
    width: 100%;
    margin-right: 0;
  }

  .review_form .open-form__form .open-form__field:nth-child(2) textarea {
    width: 100%;
  }

  .review_form .open-form__form .open-form__field:first-child {
    width: 100%;
  }

  .review_form .open-form__form .open-form__field:first-child input {
    width: 100%;
  }

  .main_slide .container {
    width: 100%;
    padding: 0 18px;
  }

  .main_slide {
    padding-top: 45px;
    width: 100%;
    overflow: hidden;
  }

  .main_slide img,
  .main_slide .calculate-new {
    display: none;
  }

  .main_slide .text h1,
  .main_slide .text span:first-child {
    font-size: 30px;
  }

  .main_slide .text span:nth-child(2) {
    font-size: 30px;
    line-height: 1.2;
  }

  .main_slide .text div {
    width: 100%;
    font-size: 30px;
  }

  .main_slide:after {
    bottom: -4px;
    width: 100%;
    display: block;
  }

  .main_slide .text span:nth-child(3) {
    margin-bottom: 18px;
  }

  .advantages {
    flex-wrap: wrap;
    margin: 25px 0;
  }

  .advantages div {
    margin-bottom: 16px;
  }

  .text_block.sec,
  .text_block {
    background: transparent;
    display: block;
    padding: 0;
  }

  .text_block span:first-child {
    width: 100%;
    margin: 0 0 15px
  }

  .text_block span {
    display: block;
    width: 100%;
  }

  .services_block .list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .services_block .list .item {
    width: 100%;
    margin-right: 0;
  }

  .services_block {
    padding: 26px 0;
  }

  .services_block .title,
  .container .title {
    margin-bottom: 26px;
  }

  .advantages_block {
    margin: 26px 0;
  }

  .advantages_block .ad_list {
    justify-content: space-between;
  }

  .advantages_block .ad_list .item {
    width: 45%;
    margin-bottom: 26px;
  }

  .ad_list .item span {
    text-decoration: none;
  }

  .main_steps_block .step_list {
    flex-wrap: wrap;
    margin-bottom: 10px;
    counter-reset: number;
  }

  .main_steps_block .step_list .item {
    width: 48%;
    padding: 0;
    margin-bottom: 20px;
    height: auto;
  }

  .main_steps_block .step_list .item img {
    padding-left: 40px;
  }

  .main_steps_block .step_list .item:before {
    background-image: none !important;
    counter-increment: number;
    content: counter(number) ". ";
    width: unset;
    height: unset;
    font-size: 30px;
    color: #FEB021;
    font-weight: bold;
  }

  .main_reviews_block {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 26px;
  }

  .main_reviews_block .reviews_list {
    flex-wrap: wrap;
  }

  .main_reviews_block .reviews_list .item:after,
  .main_reviews_block .reviews_list .item:before {
    content: '';
    display: none;
  }

  .main_reviews_block .reviews_list .item {
    padding: 0;
    margin: 0 0 16px;
    height: auto;
    overflow: hidden;
  }

  .main_reviews_block .reviews_list .item:last-child {
    display: none;
  }

  .main_reviews_block .reviews_list .item span:first-of-type {
    padding: 25px;
    border: 0;
    margin: 0;
    max-height: none
  }

  .main_reviews_block .reviews_list .item span:last-of-type {
    display: block;
    width: 100%;
    padding: 12px 25px;
    background: rgba(119, 153, 176, 0.1);
  }

  footer {
    padding: 26px 0 13px;
  }

  footer .top>div {
    margin: 0 0 26px;
  }

  footer .bottom {
    flex-direction: column;
  }

  footer .bottom a {
    margin: 0 0 10px;
  }

  .open-form__container {
    padding: 35px 25px;
  }

  .open-form__field,
  .open-form__form .orange-btn {
    width: 100%;
  }

  .payment-block .container {
    flex-direction: column;
  }

  .payment-block__big-title,
  .prices-block h1 {
    font-size: 30px;
  }

  .payment-block--bottom .payment-block__form {
    margin-top: 0;
  }

  .payment-block__link,
  .payment-block__text {
    margin: 0 0 25px 0;
  }

  .price-list__text_row.columns {
    flex-direction: column;
  }

  .price-list__text_row.columns .price-list__text_column {
    width: 100%;
  }

  .price-list__text {
    margin-bottom: 25px;
  }

  .types-block__item {
    width: 100%;
  }

  .h1-big {
    font-size: 30px;
  }

  .h1-small {
    font-size: 20px;
  }

  .choose-city__cities {
    gap: 15px 40px;
  }

  .choose-city__item {
    width: calc(calc(100% - 40px * 1) / 2);
  }

  .choose-city__popup {
    padding: 40px;
  }

}

@media (max-width: 768px) {
  .new_trans {
    flex-direction: column;
  }

  .before-after {
    max-width: 100%;
    margin: 0 auto 50px;
  }

  .service-header.delivery_header {
    padding: 50px 0 300px 0;
  }

  .delivery_list {
    flex-direction: column;
  }

  .delivery_item:last-child {
    margin-bottom: 0;
  }

  .question__source {
    padding-left: 0;
  }

  .question--legal .question__body::before {
    display: none;
  }
}

@media (max-width: 600px) {
  .contact_block {
    width: 100%;
  }

  .contact_block ul li:nth-child(4),
  .contact_block ul li:nth-child(5),
  .contact_block ul li {
    margin-right: 0;
    margin-bottom: 25px;
  }

  .open_form_success,
  .side-form__form.order_form {
    width: 100%;
  }

  .side-form__form.order_form .side-form__form_row {
    width: 100%;
  }

  .side-form__form.order_form {
    padding: 30px 25px;
  }

  .field_list {
    justify-content: center;
  }

  .sumform {
    margin: 50px 0;
  }

  .about-types__item {
    flex-direction: row;
    width: 100%;
    padding: 15px 25px;
  }

  .about-types__icon {
    margin: 0 15px 0 0;
  }

  .before-after {
    padding: 0 40px;
  }

  .before-after .after-label {
    right: -43px;
  }

  .before-after .before-label {
    left: -43px;
  }

  .delivery_attention {
    padding: 24px;
    margin-bottom: 36px;
    flex-direction: column;
    align-items: center;
  }

  .delivery_attention_image {
    margin: 0 0 24px;
  }

  .choose-city__cities {
    gap: 15px 20px;
  }

  .choose-city__item {
    width: calc(calc(100% - 20px * 1) / 2);
  }

  .choose-city__popup {
    padding: 30px;
  }

  .text-short,
  .text-short--main {
    padding: 30px 20px 30px 20px;
  }

  .text-long div {
    width: 100%;
    display: block;
    margin-top: 20px;
  }

  .service-header__info_left .service-header__text_h1 {
    padding: 0 0 0 20px;
  }
}

@media (max-width: 500px) {
  .choose-city__cities {
    gap: 15px 20px;
  }

  .choose-city__item {
    width: calc(calc(100% - 20px * 1) / 2);
  }

  .choose-city__popup {
    padding: 20px;
    margin: 20px;
    gap: 30px;
  }

  .services_block .list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 400px) {
  .choose-city__cities {
    gap: 15px 10px;
  }

  .choose-city__item {
    width: calc(calc(100% - 10px * 1) / 2);
  }

  .choose-city__popup {
    padding: 10px;
    margin: 10px;
    gap: 30px;
  }
}