@import 'qa-it/qa-it.scss';

.qa {
  position: relative;
  padding-bottom: 40px;
}

.qa__h2 {
  margin-top: 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #447595;
  margin-bottom: 26px;
}

.qa__list {
  padding: 16px;
  background: rgba(119, 153, 176, 0.2);
}


@media (min-width: $desktop) {
  .qa__h2 {
    margin-bottom: 40px;
  }

  .qa {
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .qa__list {
    padding-inline: 40px;
    padding-block: 20px;
  }
}