.toggler {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;

  div {
    width: 17px;
    height: 3px;
    background: var(--blue);
    transition: transform 0.5s;

    &:nth-child(2) {
      width: 24px;
    }
  }

  &.opened div:first-child {
    width: 24px;
    transform: rotate(45deg) translate(6px, 5px);
  }

  &.opened div:nth-child(2) {
    transform: rotate(135deg) translate(-1px, 0);
  }

  &.opened div:last-child {
    opacity: 0;
  }
}

.btn.opened .toggler{
  div:first-child {
    width: 24px;
    transform: rotate(45deg) translate(4px, 4px);
  }

  div:nth-child(2) {
    transform: rotate(135deg) translate(0, 0);
  }

  div:last-child {
    opacity: 0;
  }
}