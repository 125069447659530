
.lng_form .lng_block {
  margin-bottom: 7px;
  text-align: center;
}

.lng_form .lng_block a {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #447595;
  text-decoration: none;
  width: 29px;
  height: 24px;
  display: inline-block;
}

.lng_form .lng_block .en {
  background: url(/htdocs/img/en.png) no-repeat;
  padding: 7px 0 0 0;
  width: 28px;
}

.lng_form .lng_block .ru {
  color: #fff;
  background: url(/htdocs/img/ru.png) no-repeat;
  padding: 7px 0 0 0;
  margin-right: 0;
}

.lng_form .call_btn {
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  padding: 14px 20px;
  border: 1px solid #447595;
  border-radius: 35px;
  color: #447595;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.lng_form .call_btn svg {
  display: none;
}

.lng-vk {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}

@media (max-width: 1580px) {
  .lng_form {
    margin-right: 20px;
  }

  .lng_form .call_btn {
    padding: 14px 10px;
  }
}

@media (max-width: 1200px) {
  
  .lng_form {
    margin-right: 10px;
  }

  .lng_form .call_btn {
    font-size: 12px;
    padding: 7px 10px;
  }
}

@media (max-width: 1024px) {
  .lng_form {
    display: none;
  }

  .lng_form .call_btn {
    border: 0;
    width: 24px;
    height: 24px;
    color: transparent;
    padding: 0;
  }

  .lng_form .call_btn svg {
    display: block;
  }
}