.head__contacts {
  display: grid;
  grid-template-columns: repeat(2,minmax(0, 1fr));
  gap: 5px 8px;
}

.head__contacts-phone {
  color: var(--text);
  font-size: 16px;
  font-weight: 700;
  line-height: 1.62;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: var(--blue);
  }
}

.head__contacts-mail {
  display: flex;
  align-items: center;
  grid-column: 1/-1;
  gap: 10px;
  color: var(--text);
  text-decoration: none; 
  transition: color 0.3s;

  &:hover {
    color: var(--blue);
  }
}

.head__contacts-mail--top {
  justify-content: center;
  padding-top: 7px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
  flex-basis: 100%;
}

@media (max-width: calc($desktop - 1px)) {
  .head__contacts {
    margin-bottom: 30px;
  }
}

