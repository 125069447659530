.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  appearance: none;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.3s, border-color 0.3s;
}

.btn--rounded {
  padding: 10px 25px;
  border-radius: 200px;
  border: 1px solid var(--blue-light);
}

.btn--blue {
  color: var(--blue);
  background-color: var(--blue-light);
  border-color: var(--blue-light);

  &:hover {
    background-color: var(--white);
  }
}

.btn--yellow {
  color: var(--white);
  background-color: var(--yellow);
  border-color: var(--yellow);

  &:hover {
    background-color: var(--orange);
    border-color: var(--orange);
  }
}

.btn--blue-bd {
  color: var(--blue);
  background-color: transparent;
  border-color: var(--blue);

  &:hover {
    background-color: var(--blue-light);
  }
}