* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

body {
  display: flex;
  flex-direction: column;
  color: var(--text);
}

.container {
  width: 100%;
  max-width: 1270px;
  margin: 0 auto;
  padding-inline: var(--padding);
}

img {
  display: block;
}

section {
  margin: 0;
}


.prices-button {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #FEB021;
  border-radius: 35px;
  padding: 14px 38px;
  margin-right: 77px;
  cursor: pointer;
  white-space: nowrap;
}

.main_slide {
  background: #D8EDF5;
  position: relative;
  padding-top: 70px;
  overflow: hidden;
}

.main_slide .container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 1520px;
}

.main_slide .container>img {
  align-self: flex-end;
}

.main_slide .text {
  width: 100%;
  max-width: 660px;
}

.main_slide::after {
  content: url("/htdocs/img/main_slide_bg.png");
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
}

.main_slide .text span {
  display: block;
}

.main_slide .text h1,
.main_slide .text span:first-child {
  font-size: 35px;
  line-height: 40px;
  text-transform: uppercase;
  color: #447595;
  text-decoration: underline;
  margin-bottom: 16px;
}

.main_slide .text span:nth-child(2) {
  font-weight: bold;
  font-size: 30px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #447595;
  margin-bottom: 16px;
}

.main_slide .text span:nth-child(3) {
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  margin-bottom: 70px;
}

.main_slide .text div {
  width: 397px;
  height: 109px;
  background: #7799B0;
  border-radius: 10px;
  font-size: 30px;
  line-height: 1.2;
  color: #FFFFFF;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.main_slide .calculate-new {
  width: 282px;
  max-width: 100%;
  background: #7799B0;
  border-radius: 10px;
  padding: 25px 35px;
  max-height: 360px;
}

.main_slide .calculate-new .title {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 16px;
}

.main_slide .calculate-new label {
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  margin-bottom: 10px;
  display: block;
}

.main_slide .calculate-new select {
  width: 100%;
  margin-bottom: 16px;
  color: #447595;
  border-radius: 5px;
  padding: 5px 10px;
  background: #fff;
  border: 0;
}

.open_calc_form,
.orange-btn {
  background: #FEB021;
  border-radius: 35px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 14px 43px;
  display: inline-block;
  cursor: pointer;
  margin-top: 24px;
  border: 0;
}

.advantages {
  display: flex;
  margin: 70px 0 50px;
  justify-content: space-between;
  width: 100%;
  max-width: 1100px;
}

.advantages div {
  display: flex;
  align-items: center;
}

.advantages div img {
  margin-right: 16px;
  display: block;
}

.advantages div span {
  font-size: 17px;
  line-height: 20px;
  color: #447595;
  max-width: 200px;
}

.text_block {
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  display: flex;
  gap: 20px;
  background: url("/htdocs/img/bg_txt.png") no-repeat;
  margin: 0 0 50px;
  padding: 50px 50px 44px;
  width: 100%;
  max-width: 1240px;
}

.text_block.sec {
  background: url("/htdocs/img/bg_sec_txt.png") no-repeat;
}

.text_block span {
  width: 430px;
  max-width: 100%;
}

.text_block span:first-child {
  font-weight: bold;
  width: 350px;
  margin-right: 45px;
}

.services_block {
  padding: 50px 0;
  background: #D8EDF5;
  border-radius: 10px;
}

.services_block .title,
.container .title {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #447595;
  margin-bottom: 50px;
}

.services_block .list {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(3, minmax(0, 350px));
  gap: 20px;
}


.services_block .list .item {
  display: flex;
  align-items: center;
  width: 350px;
  max-width: 100%;
  padding-inline: 16px;
  height: 80px;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  background: #447595;
  border-radius: 10px;
  text-decoration: none;
}

.services_block .list .item:hover {
  background: #7799B0;
}

.services_block .list .item span {
  color: #fff;
}


.services_block .list .item img {
  display: block;
  margin-right: 16px;
}

.advantages_block {
  margin: 50px 0;
}

.ad_list {
  display: flex;
  flex-wrap: wrap;
}

.ad_list .item {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.ad_list .item img {
  margin-bottom: 16px;
}

.ad_list .item span {
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #447595;
  text-decoration: underline;
  max-width: 200px;
}

.main_steps_block {
  margin: 50px 0;
}

.main_steps_block .step_list {
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
}

.main_steps_block .step_list .item {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  height: 260px;
  padding: 33px 0 0 98px;
  width: 237px;
}

.main_steps_block .step_list .item:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position-x: right;
  content: '';
}

.main_steps_block .step_list .item:nth-child(1):before {
  background-image: url(/htdocs/img/bgs_1.png);
}

.main_steps_block .step_list .item:nth-child(2):before {
  background-image: url(/htdocs/img/bgs_2.png);
}

.main_steps_block .step_list .item:nth-child(3):before {
  background-image: url(/htdocs/img/bgs_3.png);
}

.main_steps_block .step_list .item:nth-child(4):before {
  background-image: url(/htdocs/img/bgs_4.png);
}

.main_steps_block .step_list .item img {
  margin-bottom: 20px;
}

.main_steps_block .step_list .item span {
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

.main_steps_block .step_list .item span:first-of-type {
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: #447595;
  margin-bottom: 20px;
}


.main_clients_list {
  margin: 0 0 50px;
  width: 1240px;
  max-width: 100%;
}

.main_clients_list .slick-arrow {
  width: 10px;
  height: 18px;
  background-image: url(/htdocs/img/slide_arrow.png);
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
}

.main_clients_list .slick-arrow.prev_clients {
  left: -10px;
}

.main_clients_list .slick-arrow.next_clients {
  right: -10px;
  transform: rotate(180deg);
}

.main_reviews_block {
  margin-bottom: 90px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 50px;
  ;
}


.main_reviews_block .reviews_list {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.main_reviews_block .reviews_list .item {
  width: 322px;
  background: #D8EDF5;
  border-radius: 20px;
  height: 282px;
  position: relative;
  margin-right: 44px;
  padding: 0 26px;
}

.main_reviews_block .reviews_list .item:before {
  content: url(/htdocs/img/r_b.png);
  margin: 26px 0 12px;
  display: block;
}

.main_reviews_block .reviews_list .item span:first-of-type {
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin-bottom: 12px;
  display: block;
  padding-bottom: 32px;
  border-bottom: 1px solid #FEB021;
  max-height: 162px;
  overflow: hidden;
}

.main_reviews_block .reviews_list .item:last-child {
  margin-right: 30px;
}

.main_reviews_block .reviews_list .item:after {
  content: url("/htdocs/img/review_after.png");
  position: absolute;
  left: 68px;
  bottom: -52px;
}

.main_reviews_block .reviews_list.inner_list {
  margin-top: 50px;
  flex-wrap: wrap;
}

.main_reviews_block .reviews_list.inner_list .item {
  margin: 0 60px 70px 0;
  width: 570px;
  max-width: 100%;
  height: 210px;
}

.main_reviews_block .reviews_list.inner_list .item:nth-child(2n) {
  margin-right: 0;
}

.main_reviews_block .more_reviews {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  margin-left: auto;
}

.main_reviews_block .more_reviews span {
  font-size: 14px;
  line-height: 16px;
  color: #447595;
}

.main_reviews_block .more_reviews img {
  margin-top: 12px;
}


footer {
  background: #D8EDF5;
  padding: 50px 0 13px;
  margin-top: auto;
}

footer .top {
  display: flex;
}

footer .top .logo {
  margin-right: 172px;
}

footer .top .contact_menu {
  margin-right: 46px;
  width: 325px;
  max-width: 100%;
}

footer .top .contact_menu .phones,
footer .top .contact_menu .mail,
footer .top .contact_menu .address,
footer .top .contact_menu .social {
  display: flex;
  margin-bottom: 12px;
}

footer .top .contact_menu .phones div {
  margin-left: 10px;
}

footer .top .contact_menu .phones div a {
  display: block;
  text-decoration: none;
  font-size: 14px;
  line-height: 16px;
  color: #447595;
  margin-bottom: 4px;
}

footer .top .contact_menu .mail svg {
  margin-right: 12px;
}

footer .top .contact_menu .mail a {
  text-decoration: none;
  font-size: 14px;
  line-height: 16px;
  color: #447595;
  margin-bottom: 4px;
}

footer .top .contact_menu .address svg {
  margin-right: 13px;
}

footer .top .contact_menu .address span {
  font-size: 14px;
  line-height: 16px;
  color: #447595;
}

footer .top .contact_menu .social {
  display: flex;
  align-items: center;
}

footer .top .contact_menu .social a {
  margin-right: 17px;
}

footer .info_block .title,
footer .contact_menu .title {
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  text-transform: uppercase;
  color: #447595;
  margin-bottom: 16px;
  display: block;
}

footer .info_block>div:first-child {
  margin-bottom: 52px;
}

footer .info_block>div:first-child .content img {
  margin-right: 25px;
}

footer .info_block {
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #447595;
  margin-bottom: 49px;
}

footer .info_block .content {
  display: flex;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: none;
  color: #447595;
}

footer .info_block .content a {
  color: #447595;
}

footer .bottom {
  display: flex;
  justify-content: flex-end;
}

footer .bottom a,
footer .bottom .copyright {
  font-size: 12px;
  line-height: 14px;
  color: #447595;
  opacity: 0.7;
  display: block;
}

footer .bottom a,
footer .bottom .copyright {
  margin-right: 150px;
}

/*СТИЛИ МОДАЛЬНЫХ ОКОН, кнопок задать вопрос, заказать звонок*/
form .input,
form textarea {
  bordeR: 2px solid #c0c9cf;
  border-radius: 13px;
  -moz-border-radius: 13px;
  -webkit-border-radius: 13px;
  -khtml-border-radius: 13px;
  padding: 5px 7px 5px 8px;
  behavior: url(/htdocs/PIE.htc);
  color: #666;
  font-style: italic;
  font-size: 11px;
  background: #f2f4f5;
  width: 90%;
}

form input.submit {
  border-radius: 18px;
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  -khtml-border-radius: 18px;
  text-align: center;
  text-shadow: 1px 1px 1px #fff;
  behavior: url(/htdocs/PIE.htc);
  bordeR: none;
  color: #333;
  font: bold 14px Arial;
  padding: 11px 31px 12px 28px;
  background: transparent url('/htdocs/images1/submit.png') repeat-x 0 0;
  margin-right: 12px;
  margin-top: 17px;
  width: auto;
  cursor: pointer;
}


/*Это всё для формы отправки файлов*/
#feedback-form {
  /* вся форма */
  width: 280px;
  padding: 0px;
  border-radius: 3px;
  background: #f1f1f1;
}

#feedback-form label {
  /* наименование полей */
  float: left;
  display: block;
  clear: right;
}

#feedback-form .w100 {
  /* поля */
  float: left !important;
  max-width: 400px;
  width: 97%;
  margin-bottom: 1em;
  padding: 1.5%;
}

#feedback-form .border {
  /* граница полей */
  border-radius: 1px;
  border-width: 1px;
  border-style: solid;
  border-color: #C0C0C0 #D9D9D9 #D9D9D9;
  box-shadow: 0 1px 1px rgba(255, 255, 255, .5), 0 1px 1px rgba(0, 0, 0, .1) inset;
}

#feedback-form .border:focus {
  outline: none;
  border-color: #abd9f1 #bfe3f7 #bfe3f7;
}

#feedback-form .border:hover {
  border-color: #7eb4ea #97cdea #97cdea;
}

#feedback-form .border:focus::-moz-placeholder {
  /* убрать при фокусе первоначальный текст поля */
  color: transparent;
}

#feedback-form .border:focus::-webkit-input-placeholder {
  color: transparent;
}

#feedback-form .border:not(:focus):not(:hover):valid {
  /* правильно заполненные поля */
  opacity: .8;
}

#submitFF {
  /* кнопка "Отправить" */
  padding: 2%;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .2) inset;
  background: #669acc;
  color: #fff;
}

#feedback-form br {
  height: 0;
  clear: both;
}

#submitFF:hover {
  background: #5c90c2;
}

#submitFF:focus {
  box-shadow: 0 1px 1px #fff, inset 0 1px 2px rgba(0, 0, 0, .8), inset 0 -1px 0 rgba(0, 0, 0, .05);
}


.calculator__result {
  display: none;
}

.calculator__result_title,
.calculator__result_value {
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.calculator__result_value {
  font-weight: bold;
}

/*Форма*/


.open-form__field_error {
  display: none;
  font-size: 12px;
  padding-top: 5px;
  color: #b44;
}


.open-form__field.errors .open-form__field_error {
  display: block;
}


.input-file-list .input-file-list-item span {
  padding: 0;
}

.errors.open-form__field textarea,
.errors.open-form__field input {
  background-color: rgba(230, 110, 110, 0.6);
}


/*Форма*/



/*Прайс лист*/

.payment-block {
  padding: 157px 0 50px 0;
  background: url('/htdocs/img/main_slide_bg.png') center 48px no-repeat, linear-gradient(to bottom, #D8EDF5 87px, #fff 87px);
}

.payment-block.payment-block--blue {
  background: url(/htdocs/img/main_slide_bg.png) center calc(100% - 50px) no-repeat, linear-gradient(to top, #fff 50px, #D8EDF5 50px);
  padding-bottom: 140px;
  padding-top: 50px;
}

.payment-block.payment-block--bottom {
  background: none;
  padding: 0;
  margin-bottom: 50px;
}

.payment-block .container {
  display: flex;
  align-items: center;
}

.payment-block.payment-block--bottom .container {
  align-items: flex-start;
}

.payment-block__info {
  flex-grow: 1;
  padding-right: 30px;
}

.payment-block__form,
.side-form__form {
  width: 335px;
  flex-shrink: 0;
  background: #7799B0;
  border-radius: 10px;
  padding: 30px;
}

.payment-block--bottom .payment-block__form {
  margin-top: 66px;
}

.payment-block__form_title,
.side-form__form_title {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 15px;
}

.payment-block__form_row,
.side-form__form_row {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

input[name="languages"]:focus::-webkit-input-placeholder {
  color: transparent
}

input[name="languages"]:focus::-moz-placeholder {
  color: transparent
}

input[name="languages"]:focus:-moz-placeholder {
  color: transparent
}

input[name="languages"]:focus:-ms-input-placeholder {
  color: transparent
}

input[name="languages"]::-webkit-input-placeholder {
  font-size: 12px;
  opacity: 0.7;
}

input[name="languages"]::-moz-placeholder {
  font-size: 12px;
  opacity: 0.7;
}

input[name="languages"]:-moz-placeholder {
  font-size: 12px;
  opacity: 0.7;
}

input[name="languages"]:-ms-input-placeholder {
  font-size: 12px;
  opacity: 0.7;
}

.payment-block__form_row input,
.side-form__form_row input {
  font-size: 14px;
  line-height: 1.2;
  color: #447595;
  padding: 5px 10px;
  border: 0;
  flex-grow: 1;
  max-width: 210px;
  border-radius: 5px;
}

.side-form__form_row input[name="file"] {
  color: #fff;
  font-size: 11px;
}

.side-form__form_input,
.side-form__form_input input {
  width: 100%;
  max-width: 100%;
}

.payment-block__form_row span,
.side-form__form_row span {
  padding-left: 10px;
  font-size: 14px;
  color: #FFFFFF;
}

.payment-block__form_row label,
.side-form__form_row label {
  font-size: 14px;
  line-height: 1.2;
  display: block;
  margin-bottom: 10px;
  color: #FFFFFF;
  text-align: left;
}

.payment-block__form_input,
.side-form__form_input {
  display: flex;
  align-items: center;
}

.payment-block__form_btn,
.side-form__form_btn {
  margin-top: 0;
  padding: 14px 66px;
}

.payment-block__title {
  font-size: 17px;
  line-height: 1.2;
  color: #447595;
  margin-bottom: 15px;
}

.payment-block__big-title {
  margin-bottom: 26px;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 40px;
  text-transform: uppercase;
  color: #447595;
}


.payment-block__text {
  font-size: 16px;
  line-height: 1.5;
  color: #333333;
}

.payment-block__link {
  display: block;
  margin-top: 50px;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.2;
  text-decoration-line: underline;
  color: #447595;
}

.prices-block {
  background-color: #D8EDF5;
  padding: 50px 0;
  margin-bottom: 50px;
}

.prices-block h1,
.h1-small {
  font-weight: normal;
  font-size: 25px;
  line-height: 40px;
  text-transform: uppercase;
  color: #447595;
  margin-bottom: 25px;
}

.price-list,
.price-list__text {
  margin-bottom: 50px;
}

.price-list:last-child,
.price-list__text:last-child {
  margin-bottom: 0;
}

.price-list__title {
  background: #447595;
  border-radius: 10px;
  padding: 15px;
  font-size: 17px;
  line-height: 20px;
  color: #FFFFFF;
  position: relative;
  cursor: pointer;
  text-align: left;
}

.price-list__title::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 8px;
  background: url('/htdocs/images/topform/price-arrow.svg') center no-repeat;
  right: 15px;
  top: calc(50% - 4px);
}

.price-list__title:hover,
.price-list.opened .price-list__title {
  background: #7799B0;
}

.price-list.opened .price-list__title::after {
  transform: rotate(180deg);
}

.price-list__list {
  padding: 40px 0 15px 0;
  background-color: #fff;
  margin-top: -30px;
  border-radius: 10px;
  display: none;
}

.price-list.opened .price-list__list {
  display: block;
}

.price-list__item {
  padding: 15px;
  background-color: #fff;
  font-size: 16px;
  line-height: 18px;
  color: #333333;
  display: flex;
  justify-content: space-between;
}

.price-list__item:nth-child(odd) {
  background: rgba(119, 153, 176, 0.2);
}

.price-list__item_title {
  padding-right: 30px;
}

.price-list__text {
  background-color: #fff;
  padding: 25px;
  border-radius: 10px;
}

.price-list__text * {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
}

.font-red {
  color: #EA3636;
}

.font-bold {
  font-weight: bold;
}

.font-blue {
  color: #447595;
}


.service-header__text blockquote,
.price-list__text blockquote,
.service-text__column blockquote,
.service-text blockquote {
  position: relative;
  min-height: 105px;
  background-color: rgba(119, 153, 176, 0.2);
  padding: 83px 16px 16px 16px;
  font-weight: 700;
  color: #3d6f91;
  border-radius: 10px;
  font-style: italic;

  &::before {
    content: "";
    position: absolute;
    left: 34px;
    top: 30px;
    display: block;
    width: 60px;
    height: 38px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 34 26'%3E%3Cpath fill='%233d6f91' d='M0 16.07c.06-.47.102-.944.181-1.412.44-2.571 1.521-4.86 3.097-6.924 1.82-2.385 4.047-4.341 6.483-6.07.77-.545 1.57-1.05 2.348-1.582.204-.14.315-.098.43.105.15.263.323.513.492.764.178.265.178.288-.094.465A30.218 30.218 0 0 0 9.161 4.3C7.784 5.545 6.545 6.902 5.648 8.543c-.61 1.115-1.009 2.294-1.094 3.57-.005.06 0 .119 0 .227.2-.12.375-.22.546-.326 3.801-2.316 8.794-.686 10.357 3.48.9 2.395.844 4.814-.595 7.055-.714 1.112-1.724 1.889-2.94 2.39-1.981.82-4.014 1.04-6.098.443-2.774-.794-4.58-2.576-5.34-5.364-.217-.797-.296-1.631-.438-2.448L0 17.319V16.07Zm22.091-3.357c.324-.232.587-.442.87-.621 3.845-2.437 8.93-.783 10.537 3.477.967 2.564.515 4.953-1.17 7.112-1.495 1.916-3.496 2.818-5.884 2.967-1.304.081-2.584-.006-3.806-.502-2.49-1.012-4.048-2.837-4.69-5.441-.791-3.207-.286-6.26 1.15-9.185 1.159-2.36 2.854-4.31 4.77-6.079 1.79-1.652 3.764-3.056 5.813-4.363.194-.124.298-.102.41.094.155.27.322.537.508.786.155.204.12.32-.089.454a29.343 29.343 0 0 0-3.527 2.654c-1.347 1.183-2.585 2.463-3.515 4.01-.794 1.318-1.337 2.718-1.377 4.279-.003.102 0 .204 0 .358Z'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

@media (min-width: 1024px) {

  .service-header__text blockquote,
  .price-list__text blockquote {
    background-color: rgba(119, 153, 176, 0.2);
    padding: 83px 32px 30px 36px;
  }
}

.price-list__text_row {
  margin-bottom: 25px;
}

.price-list__text_row:last-child {
  margin-bottom: 0;
}

.price-list__text_row.columns {
  display: flex;
  justify-content: space-between;
}

.price-list__text_row.columns .price-list__text_column {
  width: calc(50% - 13px)
}

.price-list__text_title {
  font-weight: bold;
  font-size: 17px;
  color: #447595;
  margin-bottom: 15px;
}

.price-list__text ul {
  list-style-type: none;
}

.price-list__text li {
  margin-bottom: 15px;
}


.open-form {
  margin-bottom: 50px;
}

.open-form .success {
  font-size: 30px;
  text-align: center;
  color: #4d4;
  padding: 30px;
}

.open-form__container {
  background: #7799B0;
  border-radius: 10px;
  padding: 36px 36px 16px 36px;
}

.open-form__form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.review_form .open-form__form {
  align-items: flex-start;
}

.review_form .open-form__form .open-form__field:first-child {
  width: 274px;
}

.review_form .open-form__form .open-form__field:first-child label {
  margin-top: 16px;
}

.review_form .open-form__form .open-form__field:first-child label:first-child {
  margin-top: 0;
}

.review_form .open-form__form .open-form__field:nth-child(3) {
  width: 528px;
  max-width: 100%;
  margin-right: 56px;
}

.review_form .open-form__form .open-form__field:nth-child(3) textarea {
  height: 160px;
}

.review_form .open-form__form .open-form__field:last-child {
  width: 200px;
}

.review_form .open-form__form .open-form__field:last-child .info_text {
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #FFFFFF;
}

.review_form .open-form__form .orange-btn {
  width: 100%;
}

.open-form__field {
  width: 23%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.open-form__field label,
.show-more {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.open-form__field input,
.open-form__field select,
.open-form__field textarea {
  color: #447595;
  border-radius: 5px;
  padding: 5px 10px;
  background: #fff;
  border: 0;
}

.open-form__field textarea {
  min-height: 100px;
}

.open-form__field input[type="file"] {
  background: transparent;
  color: #FFFFFF;
}



.open-form__form .orange-btn {
  width: 23%;
  margin: 0 0 20px 0;
}

.open-form__title {
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 15px;
}



/*Прайс лист*/


/*Старая оплата*/


.sumform {
  background: #7799b0;
  width: fit-content;
  border-radius: 18px;
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  -khtml-border-radius: 18px;
  behavior: url(/htdocs/PIE.htc);
  color: #fff;
  text-align: center;
  padding: 13px 16px 16px;
  left: 70%;
  overflow: auto;
  visibility: auto;
}

.payrobocassa .sumform {
  text-align: center;
}

.sumform h4 {
  color: #fff;
  text-align: center;
  margin: 2px 0 16px 0;
  text-shadow: 0px 1px 0px #020202;
}

.sumform p {
  padding: 0 0 6px 7px;
  font-size: 12px;
  margin: 0 0 0 0;
}

form .submit1 {
  border-radius: 18px;
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  -khtml-border-radius: 18px;
  text-align: center;
  text-shadow: 0px 0px 0px #fff;
  behavior: url(/htdocs/PIE.htc);
  bordeR: none;
  color: #333;
  font: bold 14px Arial;
  padding: 5px 5px 5px 5px;
  padding: 9px 31px 11px 28px;
  background: transparent url('/htdocs/images1/submit1.png') repeat-x 0 0;
  margin-right: 6px;
  margin-top: 8px;
  cursor: pointer;
  algin: right;
  margin: 10px 75px 0 0;

}

/*Старая оплата*/


/*Посадочная страница*/
.service-header {

  padding: 70px 0 90px 0;
  background: #D8EDF5 url(/htdocs/img/main_slide_bg.png) center bottom no-repeat;
}

.service-header-new {
  --gap-size: 30px;
  padding: 64px 0 90px 0;
  background: #D8EDF5 url(/htdocs/img/main_slide_bg.png) center bottom no-repeat;
  overflow: hidden;
}

.service-header .success {
  text-align: center;
  font-size: 20px;
  line-height: 1.5;
  color: #5a5;
}

.service-header-new .container {
  display: flex;
  align-items: flex-start;
  column-gap: var(--gap-size);
}

.service-header .container {
  display: flex;
  align-items: flex-start;
}

.service-header__info {
  padding-right: 100px;
}

.service-header__price_title {
  padding-top: 140px;
  line-height: 1;
  text-transform: uppercase;
  margin: 0;
  color: #447595;
  font-size: 30px;
  font-family: Inter, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 2.55px;
}

.service-header__price_h3 {
  max-width: 167px;
  color: #447595;
  font-size: 20px;
  font-family: Inter, Arial, sans-serif;
  font-weight: 800;
  padding-bottom: 0;
  line-height: 1.2;
  letter-spacing: 0.085em;
}

.service-header__block_title {
  padding-block: 18px;
  padding-right: calc(86px + var(--gap-size));
  position: absolute;
  left: 0;
  width: calc(50% + 112px);
  background: #FEB021;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;

}

.service-header__video {
  aspect-ratio: 16/9;
  padding: 13px;
  border-radius: 20px;
  border: 2px solid #FFF;
}

.service-header__video-right {
  position: relative;
  border-radius: 20px;
  grid-row: span 2;
  order: -1;
  z-index: 2;
  object-fit: cover;
  height: 100%;
  width: 100%;
}


.service-header__block_wraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 902px;
  gap: 25px;
}

.service-header__text_h1 {
  text-transform: uppercase;
  font-family: Inter, Arial, sans-serif;
  color: #FFF;
  font-size: 34px;
  font-weight: 800;
  letter-spacing: 2.975px;
}

.service-header__info_left {
  display: grid;
  gap: 40px;
  flex-basis: calc(50% - var(--gap-size)/2);
  flex-shrink: 0;
}

.service-header__info_left .service-header__text_h1 {
  padding: 0 20px 0 40px;
}

.service-header__info_right {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: auto 1fr;
  row-gap: 13px;
  align-content: flex-start;
  align-items: flex-start;
  flex-basis: calc(50% - var(--gap-size)/2);
  flex-shrink: 0;
}

.service-header__text-wraper {
  width: calc(50svw - var(--gap-size)/2 - 310px);
}

.service-header__info_right::before {
  content: '';
  position: absolute;
  top: -28px;
  left: -18px;
  border-radius: 20px;
  border: 2px solid #FFF;
  width: 587px;
  height: 349px;
}

.service-header__video-wrapper {
  order: -1;
  height: 547px;
  width: 300px;
}

.service-header__text-right_wrapper {
  width: 50%;
}

.service-header__text-right {
  position: relative;
  margin: 0;
  padding: 28px 22px 39px 36px;
  background: #447595;
  left: -15px;
}

.service__text_h2 {
  color: #FFF;
  font-size: 20px;
  font-family: Inter, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1.7px;
}

.service__text_h5 {
  color: #FFF;
  font-size: 15px;
  font-family: Inter;
  letter-spacing: 0.675px;
  font-family: Inter, Arial, sans-serif;
  margin: 0;
}

.service-header__form {
  margin-top: 12px;
  z-index: 3;
}


.service-header__info_right .first {
  justify-items: flex-start;
}

.service-header__video-frame {
  height: auto;
  width: 100%;
  border-radius: 20px;
  background: url(<path-to-image>), lightgray 50% / cover no-repeat;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  aspect-ratio: 16/9;
}

.h1-big {
  font-size: 30px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #447595;
}

.service-header__title {
  margin-bottom: 16px;
}

.service-header__price {
  padding-top: 10px;
  line-height: 1;
  text-transform: uppercase;
  /*margin: 0;*/
  margin-bottom: 50px;
  color: #FEB021;
  font-size: 30px;
  font-family: Inter, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 2.55px;
}

.service-header__text {
  font-size: 16px;
  line-height: 1.5;
  color: #333333;
}

.plusses-block {
  padding: 50px 0 0 0;
}

.plusses-block ul,
.service-text__columns ul {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.plusses-block li {
  width: 48%;
  padding-left: 34px;
  position: relative;
  margin-bottom: 50px;
  font-size: 16px;
  line-height: 1.5;
  color: #333333;
}

.service-text__columns li {
  padding-left: 34px;
  padding-right: 10px;
  position: relative;
  margin-bottom: 17px;
  font-size: 16px;
  line-height: 1.5;
  color: #333333;
}

.plusses-block li::before,
.service-text__columns li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  background-image: url('/htdocs/img/check.svg');
  width: 18px;
  height: 20px;
  z-index: 0;
}

.types-block {
  padding: 50px 0 30px 0;
  margin: 50px 0;
  background-color: #D8EDF5;
}

.types-block__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.types-block__item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 30%;
  height: 170px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.types-block__item {
  background-size: cover !important;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  line-height: 1.2;
}

.service-text {
  padding: 50px 0;
  margin: 50px 0;
  background: linear-gradient(to left, rgba(216, 237, 245, 0.8), rgba(216, 237, 245, 0.8)), #EBEBEB url('/htdocs/img/service-text-bg.png') no-repeat center right;

  p img {
    margin: 10px;
  }
}

.service-text__more {
  display: none;
  font-size: 14px;
  line-height: 1;
  color: #447595;
  width: 100%;
  text-align: center;
  cursor: pointer;
  position: relative;
  padding-bottom: 15px;
}

.service-text__more::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('/htdocs/img/text-more.svg') no-repeat center;
  height: 15px;
}


.service-text__columns {
  display: flex;
  justify-content: space-between;
}

.service-text__columns * {
  font-size: 16px;
  line-height: 1.5;
  color: #333333;
}

.service-text__column {
  flex-basis: 48%;
  flex-grow: 1;
}

.service-text p {
  margin-bottom: 25px;
}

.service-text #column_left,
.service-text #column_right {
  float: unset;
}

.service-text__columns *.service-text_subtitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #447595;
  margin-bottom: 15px;
}

.captcha_block>div {
  display: flex;
  margin: 10px 0 30px;
}

.captcha_block>div input {
  width: 100px;
  margin-right: 10px;
  height: 26px;
}


/*Посадочная страница*/

.contact_block {
  width: 738px;
}

.contact_block .subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 25px;
  color: #FEB021;
  margin: 0 0 26px;
}

.contact_block ul {
  background: #fff;
  padding: 25px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 26px;
}

.contact_block ul li {
  display: flex;
  margin-right: 36px;
  margin-bottom: 55px;
  max-width: 215px;
}

.contact_block ul li:nth-child(3),
.contact_block ul li:nth-child(5) {
  margin-right: 0;
}

.contact_block ul li:nth-child(4),
.contact_block ul li:nth-child(5) {
  margin-bottom: 0;
}

.contact_block ul li>div:first-child {
  margin-right: 16px;
}

.contact_block ul li .title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #447595;
  margin: 0 0 10px;
  text-transform: none;
}

.contact_block ul li p a,
.contact_block ul li p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  display: block;
  margin: 0 0 5px;
}

.side-form__form.order_form {
  width: 460px;
  padding: 30px 25px;
}

.service-header__form {
  position: relative;
  top: auto;
  left: -48px;
  width: calc(100% + 48px);
}

.service-header__form .field_list {
  column-gap: 20px;
}

.service-header__form .side-form__form_row {
  width: calc(50% - 10px);
  flex-basis: calc(50% - 10px);
}

.bottom_text {
  color: #0C3A58;
  font-size: 17px;
  font-family: Inter, Arial, sans-serif;
  letter-spacing: 1.445px;
  width: 200%;
  margin: 0;
  margin-top: 20px;
}

.side-form__form.order_form .side-form__form_row {
  width: 190px;
}

.side-form__form.order_form .side-form__form_row>label {
  white-space: pre;
}

.field_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.field_list .side-form__form_btn {
  flex: 1;
  text-align: center;
  padding: 14px 0;
}

.input-file-list {
  display: flex;
  flex-wrap: wrap;
  gap: 6px 12px;
  align-items: center;
}

.input-file-list-item:last-child {
  margin-bottom: 10px;
  flex: 100%;
}

.open-form__form .input-file-list-item:last-child {
  margin-bottom: 0;
}

.input-file-list-item {
  display: flex;
  align-items: center;
  gap: 6px;
}

.input-file-list-remove {
  color: white;
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;
}

.hide_file {
  width: 0;
  height: 0;
  opacity: 0;
}

.side-form__form.order_form .side-form__form_input {
  display: block;
}



.side-form__form.order_form .side-form__form_input label.choose_file+input,
.open-form__field input[type="file"] {
  display: none;
}

.service-header.contacts_header .container {
  align-items: flex-end;
}

.service-header.payments {
  padding: 0px 0 90px 0;
}

.payment_block {
  padding: 70px 0 50px;
}

.payment_block .container {
  display: flex;
  align-items: flex-start;
}

.payment_block .image {
  margin-right: 36px;
  width: 270px;
}

.payment_block .image img {
  max-width: 100%;
}

.payment_block .text {
  width: 500px;
  max-width: 100%;
  margin-right: 43px;
}

.payment_block .text div {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
}

.payment_block .text div:first-child {
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: #447595;
  margin-bottom: 24px;
}

.payment_block .sumform {
  background: #7799B0;
  border-radius: 10px;
  width: 386px;
  max-width: 100%;
  padding: 36px 26px;
  text-align: left;
}

.payment_block .sumform h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin: 0 0 16px;
  text-align: left;
}

.payment_block .sumform form p:first-child {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  margin: 0 0 10px;
}

.payment_block .sumform form p:nth-child(2) {
  color: #FFFFFF;
  display: flex;
  align-items: center;
}

.payment_block .sumform form p:nth-child(2) input {
  width: 312px;
  max-width: 100%;
  padding: 6px 10px;
  background: #fff;
  border: 0;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #447595;
  margin-right: 16px;
}

.payment_block .sumform form input[type="submit"] {
  background: #FEB021;
  border-radius: 35px;
  width: 210px;
  text-align: center;
  padding: 14px 0;
  color: #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  text-shadow: 0 0 black;
}

.payment_list {
  background: #D8EDF5 url(/htdocs/img/main_slide_bg.png) center bottom no-repeat;
  padding: 50px 0 80px;
  margin: 0 0 50px;
}

.payment_list .title {
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 40px;
  text-transform: uppercase;
  color: #447595;
  margin: 0 0 24px;
}

.payment_list .list {
  display: flex;
}

.payment_list .list .item {
  width: 385px;
  padding: 0 26px;
  border-radius: 26px;
  margin-right: 40px;
  background: #fff;
}

.payment_list .list .item img {
  max-width: 100%;
  margin-bottom: 26px;
  display: block;
}

.payment_list .list .item .subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  color: #447595;
}

.payment_list .list .item p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
}

body>iframe {
  display: none;
}

.errors.side-form__form_input textarea,
.errors.side-form__form_input input {
  background-color: rgba(230, 110, 110, 0.6);
}

.side-form__form_input_error {
  display: none;
  font-size: 12px;
  padding-top: 5px;
  color: #b44;
}

.errors.side-form__form_input .side-form__form_input_error {
  display: block;
}

.open_form_success {
  background: #7799b052;
  width: 460px;
  padding: 30px 25px;
  flex-shrink: 0;
}

#wrapper.payrobocassa {
  min-height: auto;
}

.circle_ol li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  width: 10px;
  height: 10px;
  background-color: #FEB021;
  border-radius: 50%;
}

.circle_ol li {
  padding-left: 34px;
  position: relative;
  margin-bottom: 15px;
}

/*Страница 404*/

.page-404 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(to top, #fff 60px, transparent 60px), #D8EDF5 url(/htdocs/img/main_slide_bg.png) center bottom 60px no-repeat;
  padding: 60px 0 160px 0;
}

.page-404__image {
  margin-bottom: 20px;
  width: 400px;
  max-width: calc(100% - 80px);
}

.page-404__text {
  font-size: 24px;
  line-height: 1.2;
  text-align: center;
  color: #5E5E5E;
  font-weight: bold;
  margin-bottom: 20px;
}

.page-404__btn {
  text-decoration: none;
}

/*Страница 404*/


/*О компании*/

.about-header {
  background: rgba(229, 229, 229, 0.3);
}


.about-header__container {
  background: url('/htdocs/img/about-bg.png') right bottom no-repeat;
  background-size: auto 100%;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 70px 0 90px 0;
}

.about-header__content {
  width: 500px;
  max-width: 100%;
  margin-right: 76px;
}

.about-header__title {
  font-weight: bold;
  font-size: 50px;
  text-transform: uppercase;
  color: #447595;
  margin: 0 0 16px 0;
}

.about-header__text {
  font-size: 14px;
  line-height: 1.8;
  color: #333333;
}

.about-header__text p:not(:last-child) {
  margin: 0 0 16px 0;
}

.about-header__catchphrase {
  padding: 20px;
  background: #7799B0;
  border-radius: 10px;
  width: 260px;
  font-size: 35px;
  text-align: center;
  color: #FFFFFF;
}

.about-types {
  background: #D8EDF5;
  padding: 50px 0;
}

.about-types__container {
  line-height: 1.2;
}

.section-title {
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  color: #447595;
  margin-bottom: 50px;
}

.about-types__list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: -11px;
}

.about-types__item {
  margin: 11px;
  width: 230px;
  padding: 36px 55px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
}

.about-types__icon {
  margin-bottom: 36px;
}

.about-types__text {
  font-size: 17px;
  text-align: center;
  color: #447595;
}

/*О компании*/

/*История*/

.history {
  background: #fff;
  padding: 50px 0 100px 0;
}

.history__container {
  line-height: 1.8;
}

.history__list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}

.history__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-width: 240px;
  padding: 0 0 46px 0;
  position: relative;
  height: 256px;
  overflow: hidden;
}

.history__item::before {
  content: '';
  position: absolute;
  bottom: 15px;
  border: 3px solid #447595;
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: calc(50% - 8px);
  z-index: 2;
}

.history__item::after {
  content: '';
  position: absolute;
  bottom: 22.5px;
  height: 3px;
  background: #447595;
  left: 0;
  right: 0;
  z-index: 1;
}


.history__title.section-title {
  margin: 0 0 20px 0;
}

.history__text {
  font-size: 14px;
  margin: 0 0 16px 0;
}

.history__year {
  font-size: 60px;
  line-height: 1;
  text-transform: uppercase;
  color: #FEB021;
}

.history__item:nth-child(even) {
  flex-direction: column-reverse;
  margin-top: 210px;
  padding: 46px 0 0 0;
}

.history__item:nth-child(even)::before {
  bottom: unset;
  top: 15px
}

.history__item:nth-child(even)::after {
  bottom: unset;
  top: 20.5px
}

.history__item:nth-child(even) .history__text {
  margin: 16px 0 0 0;
}


.history__item:first-child::after {
  left: 50%;
}

.history__item:last-child::after {
  right: 50%;
}

/*История*/

/*Отзывы в сервисах*/

.reviews-service__title {
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 28px;
}

.reviews-service__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.reviews-service__item {
  padding: 35px 110px 35px 60px;
  width: calc(50% - 50px);
  border-radius: 10px;
  margin-bottom: 65px;
  text-decoration: none;
}

.reviews-service__item--yandex {
  background: #FFDE00 url('/htdocs/img/reviews/yandex-mobile.png') right -1px top no-repeat;
  background-size: auto 90%;
}

.reviews-service__item--google {
  background: #0086F8 url('/htdocs/img/reviews/google-mobile.png') right -1px top no-repeat;
  background-size: auto 90%;
}

.reviews-service__item--google .reviews-service__text {
  color: #fff;
}

.reviews-service__text {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.price_page_en {
  padding: 40px 0;
}

/*Отзывы в сервисах*/

/*до после*/
.new_trans {
  display: flex;
  align-items: flex-start;
  margin-bottom: 50px;
}

.before-after {
  display: flex;
  flex-direction: column;
  width: 450px;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 60px;
  padding: 0 75px;
  border: 3px solid #D8EDF5;
  border-radius: 10px;
}

.before-after img {
  width: 100%;
  max-width: 300px;
}

.before-after .wrap {
  padding: 0;
}

.before-after .twentytwenty-overlay {
  display: none !important;
}

.before-after .twentytwenty-container {
  overflow: visible;
}

.before-after .before-label,
.before-after .after-label {
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  color: #447595;
  text-align: center;
  position: absolute;
  bottom: -3px;
  background: #D8EDF5;
  z-index: 20;
  padding: 5px 16px;
  border-radius: 10px;
}

.before-after .before-label {
  left: -78px;
}

.before-after .after-label {
  right: -78px;
}

.twentytwenty-horizontal .twentytwenty-handle:before,
.twentytwenty-horizontal .twentytwenty-handle:after {
  background: #D8EDF5;
  margin-left: -2px;
}

.twentytwenty-handle {
  border: 1px solid transparent;
  box-shadow: unset;
  background: url('/htdocs/images/before_after/before-after-handle.svg') no-repeat center center;
  background-size: 100% 100%;
  height: 30px;
  width: 30px;
  border-radius: 0;
  margin-left: -15px;
}

.twentytwenty-left-arrow,
.twentytwenty-right-arrow {
  display: none;
}

.plusses-block .new_trans_desc li {
  width: 100%;
  margin-bottom: 25px;
}

/*до после*/


/*Вопрос ответ*/

.questions-block {
  padding: 50px 0;
}

.questions-block__title {
  font-size: 35px;
  text-transform: uppercase;
  color: #447595;
  font-weight: 400;
  margin-bottom: 25px;
}

.questions-block__item {
  width: 100%;
}

.questions-block__item:not(:last-child) {
  margin-bottom: 25px;
}

.question {
  border-radius: 10px;
  overflow: hidden;
  background-color: rgba(119, 153, 176, .2);
}

.question__header {
  background-color: #7799B0;
  color: #fff;
  font-weight: bold;
  padding: 15px 40px 15px 15px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}

.question__header_text {
  color: inherit;
  font-size: 17px;
}

.question--opened .question__header {
  background-color: rgba(119, 153, 176, .2);
  color: #447595;
}

.question__header_icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.question--opened .question__header_icon {
  transform: translateY(-50%) rotate(180deg);
}

.question__header_icon path {
  fill: #fff;
}

.question--opened .question__header_icon path {
  fill: #447595;
}

.question__body {
  display: none;
  padding: 15px;
  font-size: 16px;
  line-height: 1.55;
}

.question--opened .question__body {
  display: block;
}

.questions-block__legal {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 2px solid #D8EDF5;
}

.questions-block__legal-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  margin-bottom: 40px;
}

.questions-block__legal-title span {
  color: #447595;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.questions-block__legal-title svg {
  flex: 0 0 auto;
}

.question--legal .question__body {
  position: relative;
}

.question--legal .question__body::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("/htdocs/img/question-legal-bg.png");
  background-size: cover;
  background-position: top -145px right;
  background-repeat: no-repeat;
  opacity: .2;
}

.question__main,
.question__source {
  position: relative;
  z-index: 1;
}

.question__main-title {
  font-weight: 700;
  font-size: 16px;
}

.question__main-title {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 15px;
}

.question__main-text {
  font-size: 16px;
  line-height: 1.75;
}

.question__source {
  padding: 20px 0 0 40px;
}

.question__source-title {
  font-weight: 700;
  margin-bottom: 15px;
}

.question__source-text {
  list-style-type: none;
  padding: 0 0 0 15px;
  margin: 0;
  border-left: 2px solid #447595;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

/*Вопрос ответ*/
/*Доставка*/
.delivery_block {
  position: relative;
  overflow: hidden;
  padding: 24px 0 90px 0;
}

.service-header.delivery_header {
  position: relative;
  overflow: hidden;
}

.delivery_header {
  background: unset;
}

.delivery_block::after,
.service-header.delivery_header::after {
  content: url("/htdocs/img/delivery/postman.png");
  position: absolute;
  left: calc(50% + 300px);
  bottom: 0;
  line-height: 0;
}

.delivery_main {
  width: 100%;
}

.delivery_attention {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 24px;
  width: 100%;
  margin-bottom: 36px;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.delivery_attention_image {
  flex-shrink: 0;
  margin-right: 24px;
}

.delivery_attention_text p {
  margin: 0 0 7px;
}

.delivery_attention_text p:last-of-type {
  margin-bottom: 0;
}

.delivery_attention_text .bold {
  font-weight: bold;
}

.delivery_attention_text .blue {
  color: #447595;
}

.delivery_service {
  font-weight: 400;
  font-size: 16px;
  color: #447595;
  margin-bottom: 16px;
}

.delivery_list {
  max-width: calc(100% - 329px);
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
}

.delivery_item {
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: 90px auto;
  gap: 20px;
  padding: 15px 35px;
  background-color: #FFFFFF;
  border-radius: 10px;
  border: 2px solid #7297AD;
}

.delivery_item::before {
  content: '';
  position: absolute;
  background-image: url("/htdocs/img/logo-no-text.png");
  display: block;
  background-repeat: no-repeat;
  background-position: right;
  bottom: 5px;
  top: 15px;
  right: 5px;
  width: 100%;
  background-size: contain;
}

.delivery_item-single .delivery_item_title {
  max-width: 105px;
  line-height: 1.4;
}

.delivery_item_image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.delivery_item_title {
  font-weight: 700;
  font-size: 17px;
  color: #447595;
}

.delivery_item_desc {
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  margin-top: 5px;
}

/*Доставка*/



/*Бюро*/
.cooperation-top {
  position: relative;
  margin: 0;
}

.cooperation-top .container {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1354px;
  width: 100%;
}

.service-header--cooperation {
  position: relative;
  z-index: 0;
  background: url(/htdocs/img/main_slide_bg.png) center bottom no-repeat;
}

.cooperation-top__img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.cooperation-top__img2 {
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.service-header--cooperation .h1-big {
  color: #FEB021;
}

.service-header--cooperation .service-header__text p {
  font-size: 23px;
  line-height: 122%;
  color: #FFFFFF;
}

.service-header--cooperation .service-header__text {
  margin-bottom: 30px;
  max-width: 733px;
  font-weight: 400;
}

.service-header__btn {
  margin-top: 0;
  transition: background-color 0.3s ease-in-out;
}

.service-header__btn:hover,
.service-header__btn:focus {
  background-color: #ed9900;
}

.cooperation-top .side-form__form {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.cooperation-top.opened .side-form__form {
  opacity: 1;
}

.cooperation-top.opened .service-header__text {
  max-width: 655px;
}

.cooperation-top.opened .cooperation-top__img2 {
  display: block;
  opacity: 1;
}

.service-header__btns {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 24px;
  column-gap: 24px;
}

.orange-btn--blue {
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  text-decoration: none;
  background-color: #7799B0;
  transition: background-color 0.3s ease-in-out;
}

.orange-btn--blue:hover,
.orange-btn--blue:focus {
  background-color: #627E91;
}


@media (max-width: 1024px) {

  .cooperation-top .service-header__btn,
  .service-header__btns {
    order: 3;
    margin-bottom: 30px;
  }

  .cooperation-top .container {
    align-items: center;
  }

  .cooperation-top .service-header__info {
    justify-items: center;
    align-items: center;
  }

  .cooperation-top .side-form__form {
    height: 0;
  }

  .cooperation-top.opened .side-form__form {
    height: auto;
  }
}

@media (max-width: 820px) {
  .service-header--cooperation .service-header__text p {
    font-size: 16px;
    line-height: 24px;
  }
}

.conditions {
  margin: 0;
}

.conditions .container {
  padding-top: 70px;
  padding-bottom: 70px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1354px;
  width: 100%;
}

.conditions__title {
  margin-top: 0;
  margin-bottom: 39px;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  text-transform: uppercase;
  color: #447595;
}

.conditions__list {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  -moz-column-gap: 22px;
  column-gap: 22px;
  row-gap: 20px;
  padding-top: 40px;
  padding-left: 40px;
  padding-bottom: 40px;
  padding-right: 47px;
  background: #D8EDF5;
  border-radius: 15px;
}

@media (max-width: 1000px) {
  .conditions__list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding-top: 23px;
    padding-left: 18px;
    padding-bottom: 23px;
    padding-right: 18px;
  }
}

@media (max-width: 820px) {
  .conditions__title {
    margin-bottom: 18px;
    font-size: 18px;
    line-height: 24px;
  }

  .conditions__list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 600px) {
  .conditions__list {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    -moz-column-gap: 15px;
    column-gap: 15px;
  }
}

.conditions-item {
  display: grid;
  grid-template-columns: 38px 1fr;
  row-gap: 13px;
  -moz-column-gap: 10px;
  column-gap: 10px;
  align-items: flex-start;
  align-content: flex-start;
  padding-top: 15px;
  padding-left: 18px;
  padding-bottom: 22px;
  padding-right: 6px;
  color: #ffffff;
  background: #447595;
  border-radius: 15px;
}

.conditions-item__svg {
  margin: 0;
  display: flex;
  justify-content: center;
  color: #FEB021;
  fill: #FEB021;
}

.conditions-item__name {
  align-self: center;
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 129%;
  color: #FFFFFF;
}

.conditions-item__text {
  grid-column: 1/-1;
  padding-top: 9px;
  margin: 0;
  font-size: 12px;
  line-height: 133%;
  color: #FFFFFF;
  border-top: 1px solid rgba(254, 176, 33, 0.2);
}

.cooperation-adv {
  margin: 0;
}

.cooperation-adv .container {
  padding-top: 35px;
  padding-bottom: 100px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1354px;
  width: 100%;
}

.cooperation-adv__title {
  margin-bottom: 21px;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  text-transform: uppercase;
  color: #447595;
}

.cooperation-adv__list {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  -moz-column-gap: 20px;
  column-gap: 20px;
  justify-content: space-between;
}

@media (max-width: 1150px) {
  .cooperation-adv__list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 40px;
  }
}

@media (max-width: 820px) {
  .cooperation-adv .container {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 63px;
  }

  .cooperation-adv__title {
    margin-bottom: 19px;
    font-size: 18px;
    line-height: 24px;
  }
}

@media (max-width: 600px) {
  .cooperation-adv__list {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.adv-item {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 27px;
  justify-items: center;
}

.adv-item__text {
  max-width: 100%;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  color: #000000;
}

.cooperation-options {
  position: relative;
  margin: 0;
  background-color: #D8EDF5;
}

.cooperation-options__wrapper {
  overflow: hidden;
}

.cooperation-options__img2 {
  position: absolute;
  top: 37%;
  left: -18%;
}

.cooperation-options__img2 img {
  position: relative;
  z-index: 1;
}

.cooperation-options__img2::before {
  content: "";
  position: absolute;
  bottom: -79%;
  left: 30%;
  width: 518px;
  height: 518px;
  background-color: #b0d9ee;
  border-radius: 50%;
}

.cooperation-options__img {
  position: absolute;
  right: -4%;
  bottom: -19%;
}

.cooperation-options__img::after {
  content: "";
  position: absolute;
  right: -33%;
  bottom: -69%;
  width: 713px;
  height: 713px;
  background-color: #b0d9ee;
  border-radius: 50%;
}

.cooperation-options__img img {
  position: relative;
  z-index: 1;
  -o-object-fit: contain;
  object-fit: contain;
}

.cooperation-options .container {
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: 55% 1fr;
  padding-top: 105px;
  padding-bottom: 105px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1354px;
  width: 100%;
}

.cooperation-options__content {
  max-width: 698px;
  z-index: 2;
}

.cooperation-options__content h2,
.service-text__column h2 {
  margin-top: 0;
  margin-bottom: 31px;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  text-transform: uppercase;
  color: #447595;
}

.cooperation-options__content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 138%;
  color: #000000;
}

@media (max-width: 1450px) {
  .cooperation-options__img2 {
    position: absolute;
    top: 4%;
    left: -26px;
  }

  .cooperation-options__img2 img {
    position: relative;
    z-index: 1;
  }

  .cooperation-options__img2::before {
    content: "";
    position: absolute;
    bottom: -79%;
    left: 30%;
    width: 518px;
    height: 518px;
    background-color: #b0d9ee;
    border-radius: 50%;
  }

  .cooperation-options__img {
    width: 50%;
  }
}

@media (max-width: 820px) {
  .cooperation-options .container {
    padding-top: 66px;
    padding-bottom: 292px;
    grid-template-columns: 1fr;
  }

  .cooperation-options__content h2 {
    margin-bottom: 11px;
    font-size: 18px;
    line-height: 24px;
  }

  .cooperation-options__img2 {
    top: 52%;
    left: 59px;
  }

  .cooperation-options__img2::before {
    display: none;
  }

  .cooperation-options__img {
    right: 18%;
    bottom: -19%;
    width: 40%;
  }

  .cooperation-options__img img {
    width: 100%;
  }

  .cooperation-options__img::after {
    content: "";
    position: absolute;
    right: -108%;
    bottom: -69%;
    width: 695px;
    height: 695px;
    background-color: #b0d9ee;
    border-radius: 50%;
  }
}

@media (max-width: 600px) {
  .cooperation-options__img {
    right: 18%;
    bottom: -7%;
    width: 282px;
    height: auto;
  }

  .cooperation-options__img::after {
    right: -112%;
    bottom: -405px;
    width: 700px;
    height: 700px;
  }

  .cooperation-options__img2 {
    top: 50%;
    right: 3%;
    width: 70px;
    height: 80px;
    z-index: 1;
    left: auto;
  }

  .cooperation-options__img2 img {
    width: 100%;
    height: 100%;
  }
}

.cooperation-offers {
  margin: 0;
}

.cooperation-offers .container {
  padding-top: 105px;
  padding-bottom: 53px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1354px;
  width: 100%;
}

.cooperation-offers__title {
  margin-top: 0;
  margin-bottom: 39px;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  text-transform: uppercase;
  color: #447595;
}

.cooperation-offers__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
  justify-content: center;
  justify-items: center;
  list-style: none;
  counter-reset: list2;
}

.cooperation-offers__item {
  position: relative;
  overflow: hidden;
}

.cooperation-offers__item::after {
  counter-increment: list2;
  content: "0" counter(list2);
  position: absolute;
  bottom: 8.33px;
  right: 7.5px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 30px;
  line-height: 22px;
  text-transform: uppercase;
  color: #FEB021;
}

.cooperation-offers__item::before {
  content: "";
  position: absolute;
  bottom: -28.6px;
  right: -18.14px;
  width: 76px;
  height: 76px;
  border-radius: 50%;
  background-color: #F28518;
}

@media (max-width: 820px) {
  .cooperation-offers .container {
    padding-top: 67px;
    padding-bottom: 70px;
  }

  .cooperation-offer__h3 {
    margin-bottom: 19px;
    font-size: 18px;
    line-height: 24px;
  }
}

@media (max-width: 534px) {
  .cooperation-offers__list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .cooperation-offers__item {
    position: relative;
    counter-increment: div;
    overflow: hidden;
  }

  .cooperation-offers__item::after {
    bottom: 15px;
    right: 12px;
    font-weight: 700;
    font-size: 36px;
    line-height: 22px;
  }

  .cooperation-offers__item::before {
    bottom: -36px;
    right: -22px;
    width: 95px;
    height: 95px;
  }
}

.cooperation-offer {
  padding: 14px;
  padding-bottom: 29px;
  background: #FFFFFF;
  border: 2px solid #F28518;
  border-radius: 15px;
}

.cooperation-offer__img {
  margin-bottom: 24px;
  position: relative;
  aspect-ratio: 294/154;
  border-radius: 15px;
  overflow: hidden;
}

.cooperation-offer__img img {
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
}

.cooperation-offer__h3 {
  margin-top: 0;
  margin-bottom: 7px;
  padding-bottom: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 138%;
  text-transform: uppercase;
  color: #447595;
}

.cooperation-offer__content div {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 15px;
  font-size: 14px;
  line-height: 129%;
  color: #000000;
}

.cooperation-offer__content ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cooperation-offer__content ul li {
  position: relative;
  padding-left: 13px;
  font-size: 14px;
  line-height: 18px;
}

.cooperation-offer__content ul li::before {
  content: "";
  position: absolute;
  top: 6px;
  left: 0;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #F28518;
}

.cooperation-offer__content p {
  margin: 0;
}

@media (max-width: 534px) {
  .cooperation-offer {
    padding: 17px 16px 44px 16px;
  }

  .cooperation-offer__content {
    padding-right: 13px;
  }
}

.cooperation-txt {
  position: relative;
  margin-top: 0;
  margin-bottom: 105px;
}

.cooperation-txt__wrapper {
  position: relative;
  overflow: hidden;
}

.cooperation-txt__wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: linear-gradient(91.29deg, #D8EDF5 44.98%, rgba(216, 237, 245, 0) 92.44%);
}

.cooperation-txt__img {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  -o-object-fit: cover;
  object-fit: cover;
}

.cooperation-txt .container {
  position: relative;
  z-index: 1;
  padding-top: 106px;
  padding-bottom: 106px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1354px !important;
  width: 100%;
}

.coorperation-text__content h2 {
  margin-bottom: 31px;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  text-transform: uppercase;
  color: #447595;
}

@media (max-width: 820px) {
  .cooperation-txt {
    margin-bottom: 70px;
  }

  .coorperation-text__content h2 {
    margin-bottom: 12px;
    font-size: 18px;
    line-height: 24px;
  }

  .cooperation-txt__img {
    display: none;
  }
}

.cooperation-form {
  margin-top: 0;
  margin-bottom: 105px;
  padding-left: 15px;
  padding-right: 15px;
}

.cooperation-form .open-form {
  margin-bottom: 0;
}

.open-form__field label.choose_file {
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #447595;
  padding: 7px 14px;
  margin-bottom: 0;
}

.open-form__field span {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.open-form__field label.choose_file+input {
  display: none;
}

@media (max-width: 820px) {
  .cooperation-form {
    margin-bottom: 70px;
  }
}

.cooperation-field {
  margin-top: 0;
  margin-bottom: 105px;
}

.cooperation-field .container {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0;
  padding-bottom: 0;
  max-width: 1354px;
  width: 100%;
}

.cooperation-field__h2 {
  margin-bottom: 39px;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  text-transform: uppercase;
  color: #447595;
}

.cooperation-field__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  -moz-column-gap: 23px;
  column-gap: 23px;
  row-gap: 16px;
}

.cooperation-field__item {
  position: relative;
  text-decoration: none;
  padding-top: 26px;
  padding-left: 33px;
  padding-right: 94px;
  padding-bottom: 19px;
  min-height: 196px;
  background: #FFFFFF;
  border-radius: 15px;
  aspect-ratio: 314/196;
}

.cooperation-field__item p {
  position: relative;
  z-index: 1;
  font-weight: 400;
  font-size: 23px;
  line-height: 122%;
  color: #000000;
}

.cooperation-field__item img {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  aspect-ratio: 314/196;
}

@media (max-width: 670px) {
  .cooperation-field__list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    -moz-column-gap: 14px;
    column-gap: 14px;
    row-gap: 14px;
  }

  .cooperation-field__item {
    position: relative;
    padding-top: 16px;
    padding-left: 13px;
    padding-right: 24px;
    padding-bottom: 16px;
    min-height: auto;
  }

  .cooperation-field__item p {
    font-size: 14px;
    line-height: 20px;
  }
}

.work-advs {
  margin: 0;
}

.work-advs .container {
  margin-bottom: 66px;
  padding-top: 50px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  max-width: 1270px;
}

.work-advs__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
  -moz-column-gap: 60px;
  column-gap: 60px;
}

.work-advs__item {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.work-advs__svg {
  margin-bottom: 18px;
  width: 100px;
  height: 100px;
}

.work-advs__name {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 17px;
  line-height: 20px;
  color: #447595;
}

.work-advs__text {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

@media (max-width: 820px) {
  .work-advs__list {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    -moz-column-gap: 30px;
    column-gap: 30px;
    row-gap: 24px;
  }
}

.work-how {
  margin-top: 0;
  margin-bottom: 66px;
}

.work-how .container {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  max-width: 1270px;
}

.work-how__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(0, 200px));
  -moz-column-gap: 60px;
  column-gap: 60px;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  counter-reset: how;
}

.work-how__list li {
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-content: flex-start;
}

.work-how__list li::after {
  counter-increment: how;
  content: counter(how);
  order: -1;
  margin-bottom: 16px;
  font-weight: 900;
  font-size: 100px;
  line-height: 1;
  text-transform: uppercase;
  color: #D8EDF5;
}

.work-how__list li a {
  color: #447595;
}

.work-how__list li:before {
  content: "";
  position: absolute;
  top: 46px;
  left: 114px;
  width: 100px;
  border: 1px dashed #FEB021;
}

.work-how__list li:last-child::before {
  display: none;
}

@media (max-width: 820px) {
  .work-how {
    margin-bottom: 50px;
  }
}

@media (max-width: 600px) {
  .work-how__list {
    grid-template-columns: minmax(0, 1fr);
    row-gap: 10px;
  }

  .work-how__list li {
    grid-template-columns: 67px minmax(0, 1fr);
    align-items: center;
    -moz-column-gap: 10px;
    column-gap: 10px;
  }

  .work-how__list li::before {
    display: none;
  }
}

.work-text-ins {
  position: relative;
  margin-top: 0;
  margin-bottom: 50px;
  background: linear-gradient(to left, rgba(216, 237, 245, 0.8), rgba(216, 237, 245, 0.8)), #EBEBEB;
}

.work-text-ins .container {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 0;
  width: 100%;
  max-width: 1270px;
}

.work-text-ins__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.work-text-ins__content {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  -moz-column-gap: 100px;
  column-gap: 100px;
  z-index: 1;
}

@media (max-width: 820px) {
  .work-text-ins__content {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 50px;
  }

  .work-text-ins__img {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    height: auto;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.work-langs {
  margin-top: 0;
  margin-bottom: 50px;
}

.work-langs .container {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  max-width: 1270px;
}

.work-langs__list {
  display: flex;
  justify-content: space-between;
  justify-items: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.work-langs__item {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 16px;
  flex-basis: 112px;
  justify-items: center;
  text-decoration: none;
}

.work-langs__item span {
  font-size: 17px;
  line-height: 20px;
  color: #447595;
}

.work-langs__item img {
  width: 100%;
}

.work-langs__item:hover span,
.work-langs__item:focus span {
  color: #F28518;
}

.work-types {
  margin-top: 0;
  margin-bottom: 50px;
}

.work-types .container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  -moz-column-gap: 80px;
  column-gap: 80px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  max-width: 1270px;
}

.work-types__block {
  display: grid;
  grid-template-columns: 100px 1fr;
  -moz-column-gap: 50px;
  column-gap: 50px;
  row-gap: 24px;
  align-content: flex-start;
  padding: 36px;
  border: 2px solid #447595;
  border-radius: 10px;
}

.work-types__svg {
  width: 100%;
  height: 100%;
}

.work-types__svg svg {
  width: 100%;
  height: 100%;
}

.work-types__name {
  align-self: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #447595;
}

.work-types__text {
  grid-column: 1/-1;
}

.work-types__text p {
  margin: 0;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 25px;
}

@media (max-width: 1100px) {
  .work-types .container {
    -moz-column-gap: 40px;
    column-gap: 40px;
  }

  .work-types__block {
    -moz-column-gap: 24px;
    column-gap: 24px;
    padding: 24px;
  }
}

@media (max-width: 820px) {
  .work-types .container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 24px;
  }

  .work-types__svg {
    width: 50px;
    height: 50px;
  }

  .work-types__name {
    font-size: 18px;
    line-height: 21px;
  }

  .work-types__block {
    grid-template-columns: 50px 1fr;
  }
}

.work-service-header {
  margin: 0;
}

.work-feedback {
  margin: 0;
  padding-left: 15px;
  padding-right: 15px;
}

.work-feedback .container {
  width: 100%;
  max-width: 1270px;
}

.work-tabs {
  margin-top: 0;
  margin-bottom: 66px;
}

.work-tabs .container {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  max-width: 1270px;
}

.work-tabs .price-list {
  margin-bottom: 24px;
}

.work-tabs .price-list__list {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 34px;
  padding-top: 54px;
  background-color: rgba(119, 153, 176, 0.2);
}

@media (max-width: 820px) {
  .price-list {
    margin-left: 0;
    margin-right: 0;
  }

  .work-tabs .price-list__list {
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 24px;
  }
}

.work-bottom {
  margin: 0;
}

.work-bottom .container {
  display: grid;
  grid-template-columns: 7fr 6fr;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  max-width: 1270px;
}

.work-bottom__content {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-items: flex-start;
  align-content: flex-start;
}

.work-bottom__content .text {
  margin-bottom: 30px;
}

.work-bottom__btn {
  justify-self: flex-start;
  padding: 20px;
  font-weight: 400;
  font-size: 35px;
  line-height: 40px;
  color: #FFFFFF;
  text-decoration: none;
  border: 0;
  background: #7799B0;
  border-radius: 10px;
}

.work-bottom__img {
  display: flex;
  justify-content: flex-end;
}

.work-bottom__img img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 820px) {
  .work-bottom .container {
    grid-template-columns: minmax(0, 1fr);
  }

  .work-bottom__content {
    margin-bottom: 24px;
  }

  .work-bottom__img {
    justify-content: center;
  }
}

.text {
  font-size: 16px;
  line-height: 138%;
  color: #000000;
}

.text p {
  margin-top: 0;
}

.text p:not(:last-child) {
  margin-bottom: 19px;
}

.text ol {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 11px;
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: li;
}

.text ol li {
  position: relative;
  padding-left: 24px;
}

.text ol li::before {
  counter-increment: li;
  content: counter(li) ".";
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 138%;
  color: #F28518;
}

.text ol:not(:last-child) {
  margin-bottom: 24px;
}

.text blockquote {
  position: relative;
  padding: 0;
  padding-left: 23px;
  font-weight: 400;
  font-size: 16px;
  line-height: 138%;
  color: #F28518;
}

.text blockquote:not(:last-child) {
  margin-bottom: 24px;
}

.text blockquote::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 7px;
  height: 43px;
  background-color: #F28518;
  border-radius: 2px;
}

.text ul,
.work-types__text ul {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 16px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.text ul li,
.work-types__text ul li {
  position: relative;
  padding-left: 34px;
}

.work-types__text ul li {
  font-size: inherit;
  line-height: inherit;
}

.work-types__text ul {
  font-size: 16px;
  line-height: 25px;
}

.text ul li::before,
.work-types__text ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-image: url("/htdocs/img/check.svg");
  width: 18px;
  height: 20px;
}

.text ul:not(:last-child),
.work-types__text ul:not(:last-child) {
  margin-bottom: 12px;
}

.text p+h4 {
  padding-top: 4px;
}

.text h3 {
  margin-top: 0;
  margin-bottom: 50px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #447595;
}

.text p {
  margin-top: 0;
  font-size: 12px;
  line-height: 156%;
}

.text p:not(:last-child) {
  margin-bottom: 16px;
}

.text h4 {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
}

.text h2 {
  margin-bottom: 50px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #447595;
}

.text--colls {
  -moz-column-width: 48%;
  column-width: 48%;
  -moz-column-count: 2;
  column-count: 2;
  -moz-column-gap: 23px;
  column-gap: 23px;
}

@media (max-width: 820px) {
  .text {
    font-size: 14px;
    line-height: 143%;
  }

  .text p:not(:last-child) {
    margin-bottom: 17px;
  }

  .text ol {
    row-gap: 9px;
    font-size: 14px;
    line-height: 20px;
  }

  .text blockquote {
    font-size: 14px;
    line-height: 20px;
  }

  .text img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .text img:not(:last-child) {
    margin-bottom: 16px;
  }

  .text p+h2 {
    padding-top: 30px;
  }
}

@media (max-width: 740px) {
  .text--colls {
    -moz-column-width: 100%;
    column-width: 100%;
    -moz-column-count: 1;
    column-count: 1;
  }
}

.buro-slider img {
  height: unset;
}

/*# sourceMappingURL=style.css.map */
/*Бюро*/

.cookie-city,
.choose-city {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  height: 100vh;
  width: 100vw;

  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 1000;
}

.popup__overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.33);
}

.cookie-city__popup {
  width: 203px;

  background: #F2F4F5;
  border-radius: 20px;
  padding: 16px 9px 20px;
  display: flex;
  flex-direction: column;
}

.cookie-city__popup p {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  color: #1B1D1D;
  margin: 0;
}

p.cookie-city__city {
  font-weight: 700;
  margin-top: 1px;
}

.cookie-city__yes {
  margin: 12px 0 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;

  background: #FEAF22;
  border-radius: 200px;

  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  text-align: center;

  color: #FFFFFF;

  border-style: unset;
  cursor: pointer;
}

.cookie-city__no {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;

  border: 1px solid #437595;
  border-radius: 200px;

  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  text-align: center;

  color: #437595;
  cursor: pointer;
}

.choose-city__popup {
  background: #F2F4F5;
  border-radius: 20px;

  padding: 70px 90px 55px;
  width: 1240px;

  display: flex;
  flex-direction: column;
  gap: 46px;
  margin: 50px;
}

.choose-city__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #4E7D9B;

  position: relative;
}

.choose-city__title::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -13px;
  border-top: 1px solid #E0E0E0;
  width: 100%;
}

.choose-city__cities {
  display: flex;
  flex-wrap: wrap;
  gap: 15px 70px;
}

.choose-city__item {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  /* identical to box height */
  width: calc(calc(100% - 70px * 3) / 4);


  color: #000000;
  cursor: pointer;
}

.text-short-block,
.text-short-block--main {
  margin: 30px 0;
}

.text-short,
.text-short--main {
  padding: 50px 78px 54px 36px;
  background-image: url("/htdocs/images/text-short-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
}

.text-short>h2,
.text-long>h2,
.text-short--main>h2 {
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  /* identical to box height */

  text-transform: uppercase;

  color: #447595;
  margin: 0 0 24px;
}

.text-long>h2 {
  margin-bottom: 9px;
}

.text-short>p,
.text-long>p,
.text-short--main>p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* or 138% */
  color: #000000;
}

.text-long-block {
  padding: 40px 0 0;
}

.text-long div {
  width: calc(calc(100% - 26px) / 2);
  display: inline-block;
  margin-top: 20px;
}

.text-long div:last-child {
  float: right;
}

.text-long h2 span,
.text-short--main h2 span {
  color: inherit;
  font-size: inherit;
}

.text-long p {
  margin: 0;
}

#js_before_after a {
  display: none;
}

.bottom_text.tight-text {
  letter-spacing: unset;
}

.open-form__form--review {
  align-items: flex-start;
}

.payment_list .list .item p.subtitle-prices {
  margin-top: 30px;
  color: #447595;
  font-size: 17px;
  font-weight: 700;
}

.payment_list .list .item p.text-little {
  width: 50%;
  font-size: 14px;
  margin-bottom: 30px;
}