.top-nav__link {
  display: flex;
  padding-block: 7px;
  color: var(--text);
  font-weight: 700;
  line-height: 1.15;
  text-transform: uppercase;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: var(--blue);
  }
}

@media (min-width: $desktop) {
  .top-nav__list {
    display: flex;
    gap: 15px;
  }
}