.langs__bubble {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media (max-width: calc($desktop - 1px)) {
  .langs__bubble {
    height: 0;
    max-height: 0;

    transition: max-height 0.3s;
    overflow: hidden;
  }

  .langs__bubble-inside {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    padding-block: 10px;
  }

  .langs__active.opened {
    svg:last-child {
      transform: rotate(180deg);
    }
  }

  .langs__bubble.opened {
    height: auto;
    max-height: 2000px;
  }
}

@media (min-width: $desktop) {
  .langs {
    position: relative;
  }

  .langs__active span {
    display: none;
  }

  .langs__bubble {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 4;
    opacity: 0;
    border-radius: 10px;
    visibility: hidden;
    transition: opacity 0.3s;
  }

  .lang__btn {
    padding: 10px 20px;
    width: 100%;
    justify-content: flex-start;
    background-color: var(--white);
    transition: background-color 0.3s;

    &:hover {
      background-color: var(--blue-light);
    }
  }

  .langs__bubble-inside{
    margin-top: 10px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 59.1px 0px rgba(2, 58, 94, 0.20);
  }

  .langs:hover {
    .langs__bubble {
      visibility: visible;
      opacity: 1;
    }
  }
}