
.main_lng_block {
  background: #D8EDF5;
  padding: 50px 0;
}

.main_lng_block.lng_page {
  margin-bottom: 30px;
}

.main_lng_block .list {
  display: flex;
  justify-content: space-between;
}

.main_lng_block.lng_page .list {
  flex-wrap: wrap;
  justify-content: flex-start;
}


.main_lng_block .list .item {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 17px;
  line-height: 20px;
  color: #447595;
  text-decoration: none;
}

.main_lng_block.lng_page .list .item {
  padding: 10px;
  width: calc(100%/5);
  text-align: center;
}

.main_lng_block .list .item img {
  margin-bottom: 16px;
}

.main_lng_block .list .item span {
  color: #447595;
}

.main_lng_block .list .item:hover {
  text-decoration: underline;
}

@media (max-width: 1024px) {
  
  .main_lng_block .list {
    flex-wrap: wrap;
  }

  .main_lng_block .list .item {
    width: 45%;
    margin: 0 0 26px;
  }

  .main_lng_block.lng_page .list .item {
    width: 50%;
  }
}

@media (max-width: 800px) {
  .main_lng_block {
    padding: 26px 0;
  }
}