@charset "UTF-8";
:root {
  --blue-light: #D8EDF5;
  --blue: #447595;
  --blue-opacity: rgba(68, 117, 149, 0.10);
  --border-color: #E1F1F7;
  --white: #fff;
  --yellow: #FEAF22;
  --orange: #F28518;
  --text: #333;
  --padding: 15px;
}

.bg-blue {
  background-color: var(--blue);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

body {
  display: flex;
  flex-direction: column;
  color: var(--text);
}

.container {
  width: 100%;
  max-width: 1270px;
  margin: 0 auto;
  padding-inline: var(--padding);
}

img {
  display: block;
}

section {
  margin: 0;
}

.prices-button {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #FEB021;
  border-radius: 35px;
  padding: 14px 38px;
  margin-right: 77px;
  cursor: pointer;
  white-space: nowrap;
}

.main_slide {
  background: #D8EDF5;
  position: relative;
  padding-top: 70px;
  overflow: hidden;
}

.main_slide .container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 1520px;
}

.main_slide .container > img {
  align-self: flex-end;
}

.main_slide .text {
  width: 100%;
  max-width: 660px;
}

.main_slide::after {
  content: url("/htdocs/img/main_slide_bg.png");
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
}

.main_slide .text span {
  display: block;
}

.main_slide .text h1,
.main_slide .text span:first-child {
  font-size: 35px;
  line-height: 40px;
  text-transform: uppercase;
  color: #447595;
  text-decoration: underline;
  margin-bottom: 16px;
}

.main_slide .text span:nth-child(2) {
  font-weight: bold;
  font-size: 30px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #447595;
  margin-bottom: 16px;
}

.main_slide .text span:nth-child(3) {
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  margin-bottom: 70px;
}

.main_slide .text div {
  width: 397px;
  height: 109px;
  background: #7799B0;
  border-radius: 10px;
  font-size: 30px;
  line-height: 1.2;
  color: #FFFFFF;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.main_slide .calculate-new {
  width: 282px;
  max-width: 100%;
  background: #7799B0;
  border-radius: 10px;
  padding: 25px 35px;
  max-height: 360px;
}

.main_slide .calculate-new .title {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 16px;
}

.main_slide .calculate-new label {
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  margin-bottom: 10px;
  display: block;
}

.main_slide .calculate-new select {
  width: 100%;
  margin-bottom: 16px;
  color: #447595;
  border-radius: 5px;
  padding: 5px 10px;
  background: #fff;
  border: 0;
}

.open_calc_form,
.orange-btn {
  background: #FEB021;
  border-radius: 35px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 14px 43px;
  display: inline-block;
  cursor: pointer;
  margin-top: 24px;
  border: 0;
}

.advantages {
  display: flex;
  margin: 70px 0 50px;
  justify-content: space-between;
  width: 100%;
  max-width: 1100px;
}

.advantages div {
  display: flex;
  align-items: center;
}

.advantages div img {
  margin-right: 16px;
  display: block;
}

.advantages div span {
  font-size: 17px;
  line-height: 20px;
  color: #447595;
  max-width: 200px;
}

.text_block {
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  display: flex;
  gap: 20px;
  background: url("/htdocs/img/bg_txt.png") no-repeat;
  margin: 0 0 50px;
  padding: 50px 50px 44px;
  width: 100%;
  max-width: 1240px;
}

.text_block.sec {
  background: url("/htdocs/img/bg_sec_txt.png") no-repeat;
}

.text_block span {
  width: 430px;
  max-width: 100%;
}

.text_block span:first-child {
  font-weight: bold;
  width: 350px;
  margin-right: 45px;
}

.services_block {
  padding: 50px 0;
  background: #D8EDF5;
  border-radius: 10px;
}

.services_block .title,
.container .title {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #447595;
  margin-bottom: 50px;
}

.services_block .list {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(3, minmax(0, 350px));
  gap: 20px;
}

.services_block .list .item {
  display: flex;
  align-items: center;
  width: 350px;
  max-width: 100%;
  padding-inline: 16px;
  height: 80px;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  background: #447595;
  border-radius: 10px;
  text-decoration: none;
}

.services_block .list .item:hover {
  background: #7799B0;
}

.services_block .list .item span {
  color: #fff;
}

.services_block .list .item img {
  display: block;
  margin-right: 16px;
}

.advantages_block {
  margin: 50px 0;
}

.ad_list {
  display: flex;
  flex-wrap: wrap;
}

.ad_list .item {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.ad_list .item img {
  margin-bottom: 16px;
}

.ad_list .item span {
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #447595;
  text-decoration: underline;
  max-width: 200px;
}

.main_steps_block {
  margin: 50px 0;
}

.main_steps_block .step_list {
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
}

.main_steps_block .step_list .item {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  height: 260px;
  padding: 33px 0 0 98px;
  width: 237px;
}

.main_steps_block .step_list .item:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position-x: right;
  content: "";
}

.main_steps_block .step_list .item:nth-child(1):before {
  background-image: url(/htdocs/img/bgs_1.png);
}

.main_steps_block .step_list .item:nth-child(2):before {
  background-image: url(/htdocs/img/bgs_2.png);
}

.main_steps_block .step_list .item:nth-child(3):before {
  background-image: url(/htdocs/img/bgs_3.png);
}

.main_steps_block .step_list .item:nth-child(4):before {
  background-image: url(/htdocs/img/bgs_4.png);
}

.main_steps_block .step_list .item img {
  margin-bottom: 20px;
}

.main_steps_block .step_list .item span {
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

.main_steps_block .step_list .item span:first-of-type {
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: #447595;
  margin-bottom: 20px;
}

.main_clients_list {
  margin: 0 0 50px;
  width: 1240px;
  max-width: 100%;
}

.main_clients_list .slick-arrow {
  width: 10px;
  height: 18px;
  background-image: url(/htdocs/img/slide_arrow.png);
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
}

.main_clients_list .slick-arrow.prev_clients {
  left: -10px;
}

.main_clients_list .slick-arrow.next_clients {
  right: -10px;
  transform: rotate(180deg);
}

.main_reviews_block {
  margin-bottom: 90px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 50px;
}

.main_reviews_block .reviews_list {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.main_reviews_block .reviews_list .item {
  width: 322px;
  background: #D8EDF5;
  border-radius: 20px;
  height: 282px;
  position: relative;
  margin-right: 44px;
  padding: 0 26px;
}

.main_reviews_block .reviews_list .item:before {
  content: url(/htdocs/img/r_b.png);
  margin: 26px 0 12px;
  display: block;
}

.main_reviews_block .reviews_list .item span:first-of-type {
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin-bottom: 12px;
  display: block;
  padding-bottom: 32px;
  border-bottom: 1px solid #FEB021;
  max-height: 162px;
  overflow: hidden;
}

.main_reviews_block .reviews_list .item:last-child {
  margin-right: 30px;
}

.main_reviews_block .reviews_list .item:after {
  content: url("/htdocs/img/review_after.png");
  position: absolute;
  left: 68px;
  bottom: -52px;
}

.main_reviews_block .reviews_list.inner_list {
  margin-top: 50px;
  flex-wrap: wrap;
}

.main_reviews_block .reviews_list.inner_list .item {
  margin: 0 60px 70px 0;
  width: 570px;
  max-width: 100%;
  height: 210px;
}

.main_reviews_block .reviews_list.inner_list .item:nth-child(2n) {
  margin-right: 0;
}

.main_reviews_block .more_reviews {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  margin-left: auto;
}

.main_reviews_block .more_reviews span {
  font-size: 14px;
  line-height: 16px;
  color: #447595;
}

.main_reviews_block .more_reviews img {
  margin-top: 12px;
}

footer {
  background: #D8EDF5;
  padding: 50px 0 13px;
  margin-top: auto;
}

footer .top {
  display: flex;
}

footer .top .logo {
  margin-right: 172px;
}

footer .top .contact_menu {
  margin-right: 46px;
  width: 325px;
  max-width: 100%;
}

footer .top .contact_menu .phones,
footer .top .contact_menu .mail,
footer .top .contact_menu .address,
footer .top .contact_menu .social {
  display: flex;
  margin-bottom: 12px;
}

footer .top .contact_menu .phones div {
  margin-left: 10px;
}

footer .top .contact_menu .phones div a {
  display: block;
  text-decoration: none;
  font-size: 14px;
  line-height: 16px;
  color: #447595;
  margin-bottom: 4px;
}

footer .top .contact_menu .mail svg {
  margin-right: 12px;
}

footer .top .contact_menu .mail a {
  text-decoration: none;
  font-size: 14px;
  line-height: 16px;
  color: #447595;
  margin-bottom: 4px;
}

footer .top .contact_menu .address svg {
  margin-right: 13px;
}

footer .top .contact_menu .address span {
  font-size: 14px;
  line-height: 16px;
  color: #447595;
}

footer .top .contact_menu .social {
  display: flex;
  align-items: center;
}

footer .top .contact_menu .social a {
  margin-right: 17px;
}

footer .info_block .title,
footer .contact_menu .title {
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  text-transform: uppercase;
  color: #447595;
  margin-bottom: 16px;
  display: block;
}

footer .info_block > div:first-child {
  margin-bottom: 52px;
}

footer .info_block > div:first-child .content img {
  margin-right: 25px;
}

footer .info_block {
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #447595;
  margin-bottom: 49px;
}

footer .info_block .content {
  display: flex;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: none;
  color: #447595;
}

footer .info_block .content a {
  color: #447595;
}

footer .bottom {
  display: flex;
  justify-content: flex-end;
}

footer .bottom a,
footer .bottom .copyright {
  font-size: 12px;
  line-height: 14px;
  color: #447595;
  opacity: 0.7;
  display: block;
}

footer .bottom a,
footer .bottom .copyright {
  margin-right: 150px;
}

/*СТИЛИ МОДАЛЬНЫХ ОКОН, кнопок задать вопрос, заказать звонок*/
form .input,
form textarea {
  bordeR: 2px solid #c0c9cf;
  border-radius: 13px;
  -moz-border-radius: 13px;
  -webkit-border-radius: 13px;
  -khtml-border-radius: 13px;
  padding: 5px 7px 5px 8px;
  behavior: url(/htdocs/PIE.htc);
  color: #666;
  font-style: italic;
  font-size: 11px;
  background: #f2f4f5;
  width: 90%;
}

form input.submit {
  border-radius: 18px;
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  -khtml-border-radius: 18px;
  text-align: center;
  text-shadow: 1px 1px 1px #fff;
  behavior: url(/htdocs/PIE.htc);
  bordeR: none;
  color: #333;
  font: bold 14px Arial;
  padding: 11px 31px 12px 28px;
  background: transparent url("/htdocs/images1/submit.png") repeat-x 0 0;
  margin-right: 12px;
  margin-top: 17px;
  width: auto;
  cursor: pointer;
}

/*Это всё для формы отправки файлов*/
#feedback-form {
  /* вся форма */
  width: 280px;
  padding: 0px;
  border-radius: 3px;
  background: #f1f1f1;
}

#feedback-form label {
  /* наименование полей */
  float: left;
  display: block;
  clear: right;
}

#feedback-form .w100 {
  /* поля */
  float: left !important;
  max-width: 400px;
  width: 97%;
  margin-bottom: 1em;
  padding: 1.5%;
}

#feedback-form .border {
  /* граница полей */
  border-radius: 1px;
  border-width: 1px;
  border-style: solid;
  border-color: #C0C0C0 #D9D9D9 #D9D9D9;
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.5), 0 1px 1px rgba(0, 0, 0, 0.1) inset;
}

#feedback-form .border:focus {
  outline: none;
  border-color: #abd9f1 #bfe3f7 #bfe3f7;
}

#feedback-form .border:hover {
  border-color: #7eb4ea #97cdea #97cdea;
}

#feedback-form .border:focus::-moz-placeholder {
  /* убрать при фокусе первоначальный текст поля */
  color: transparent;
}

#feedback-form .border:focus::-webkit-input-placeholder {
  color: transparent;
}

#feedback-form .border:not(:focus):not(:hover):valid {
  /* правильно заполненные поля */
  opacity: 0.8;
}

#submitFF {
  /* кнопка "Отправить" */
  padding: 2%;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2) inset;
  background: #669acc;
  color: #fff;
}

#feedback-form br {
  height: 0;
  clear: both;
}

#submitFF:hover {
  background: #5c90c2;
}

#submitFF:focus {
  box-shadow: 0 1px 1px #fff, inset 0 1px 2px rgba(0, 0, 0, 0.8), inset 0 -1px 0 rgba(0, 0, 0, 0.05);
}

.calculator__result {
  display: none;
}

.calculator__result_title,
.calculator__result_value {
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.calculator__result_value {
  font-weight: bold;
}

/*Форма*/
.open-form__field_error {
  display: none;
  font-size: 12px;
  padding-top: 5px;
  color: #b44;
}

.open-form__field.errors .open-form__field_error {
  display: block;
}

.input-file-list .input-file-list-item span {
  padding: 0;
}

.errors.open-form__field textarea,
.errors.open-form__field input {
  background-color: rgba(230, 110, 110, 0.6);
}

/*Форма*/
/*Прайс лист*/
.payment-block {
  padding: 157px 0 50px 0;
  background: url("/htdocs/img/main_slide_bg.png") center 48px no-repeat, linear-gradient(to bottom, #D8EDF5 87px, #fff 87px);
}

.payment-block.payment-block--blue {
  background: url(/htdocs/img/main_slide_bg.png) center calc(100% - 50px) no-repeat, linear-gradient(to top, #fff 50px, #D8EDF5 50px);
  padding-bottom: 140px;
  padding-top: 50px;
}

.payment-block.payment-block--bottom {
  background: none;
  padding: 0;
  margin-bottom: 50px;
}

.payment-block .container {
  display: flex;
  align-items: center;
}

.payment-block.payment-block--bottom .container {
  align-items: flex-start;
}

.payment-block__info {
  flex-grow: 1;
  padding-right: 30px;
}

.payment-block__form,
.side-form__form {
  width: 335px;
  flex-shrink: 0;
  background: #7799B0;
  border-radius: 10px;
  padding: 30px;
}

.payment-block--bottom .payment-block__form {
  margin-top: 66px;
}

.payment-block__form_title,
.side-form__form_title {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 15px;
}

.payment-block__form_row,
.side-form__form_row {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

input[name=languages]:focus::-webkit-input-placeholder {
  color: transparent;
}

input[name=languages]:focus::-moz-placeholder {
  color: transparent;
}

input[name=languages]:focus:-moz-placeholder {
  color: transparent;
}

input[name=languages]:focus:-ms-input-placeholder {
  color: transparent;
}

input[name=languages]::-webkit-input-placeholder {
  font-size: 12px;
  opacity: 0.7;
}

input[name=languages]::-moz-placeholder {
  font-size: 12px;
  opacity: 0.7;
}

input[name=languages]:-moz-placeholder {
  font-size: 12px;
  opacity: 0.7;
}

input[name=languages]:-ms-input-placeholder {
  font-size: 12px;
  opacity: 0.7;
}

.payment-block__form_row input,
.side-form__form_row input {
  font-size: 14px;
  line-height: 1.2;
  color: #447595;
  padding: 5px 10px;
  border: 0;
  flex-grow: 1;
  max-width: 210px;
  border-radius: 5px;
}

.side-form__form_row input[name=file] {
  color: #fff;
  font-size: 11px;
}

.side-form__form_input,
.side-form__form_input input {
  width: 100%;
  max-width: 100%;
}

.payment-block__form_row span,
.side-form__form_row span {
  padding-left: 10px;
  font-size: 14px;
  color: #FFFFFF;
}

.payment-block__form_row label,
.side-form__form_row label {
  font-size: 14px;
  line-height: 1.2;
  display: block;
  margin-bottom: 10px;
  color: #FFFFFF;
  text-align: left;
}

.payment-block__form_input,
.side-form__form_input {
  display: flex;
  align-items: center;
}

.payment-block__form_btn,
.side-form__form_btn {
  margin-top: 0;
  padding: 14px 66px;
}

.payment-block__title {
  font-size: 17px;
  line-height: 1.2;
  color: #447595;
  margin-bottom: 15px;
}

.payment-block__big-title {
  margin-bottom: 26px;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 40px;
  text-transform: uppercase;
  color: #447595;
}

.payment-block__text {
  font-size: 16px;
  line-height: 1.5;
  color: #333333;
}

.payment-block__link {
  display: block;
  margin-top: 50px;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.2;
  text-decoration-line: underline;
  color: #447595;
}

.prices-block {
  background-color: #D8EDF5;
  padding: 50px 0;
  margin-bottom: 50px;
}

.prices-block h1,
.h1-small {
  font-weight: normal;
  font-size: 25px;
  line-height: 40px;
  text-transform: uppercase;
  color: #447595;
  margin-bottom: 25px;
}

.price-list,
.price-list__text {
  margin-bottom: 50px;
}

.price-list:last-child,
.price-list__text:last-child {
  margin-bottom: 0;
}

.price-list__title {
  background: #447595;
  border-radius: 10px;
  padding: 15px;
  font-size: 17px;
  line-height: 20px;
  color: #FFFFFF;
  position: relative;
  cursor: pointer;
  text-align: left;
}

.price-list__title::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 8px;
  background: url("/htdocs/images/topform/price-arrow.svg") center no-repeat;
  right: 15px;
  top: calc(50% - 4px);
}

.price-list__title:hover,
.price-list.opened .price-list__title {
  background: #7799B0;
}

.price-list.opened .price-list__title::after {
  transform: rotate(180deg);
}

.price-list__list {
  padding: 40px 0 15px 0;
  background-color: #fff;
  margin-top: -30px;
  border-radius: 10px;
  display: none;
}

.price-list.opened .price-list__list {
  display: block;
}

.price-list__item {
  padding: 15px;
  background-color: #fff;
  font-size: 16px;
  line-height: 18px;
  color: #333333;
  display: flex;
  justify-content: space-between;
}

.price-list__item:nth-child(odd) {
  background: rgba(119, 153, 176, 0.2);
}

.price-list__item_title {
  padding-right: 30px;
}

.price-list__text {
  background-color: #fff;
  padding: 25px;
  border-radius: 10px;
}

.price-list__text * {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
}

.font-red {
  color: #EA3636;
}

.font-bold {
  font-weight: bold;
}

.font-blue {
  color: #447595;
}

.service-header__text blockquote,
.price-list__text blockquote,
.service-text__column blockquote,
.service-text blockquote {
  position: relative;
  min-height: 105px;
  background-color: rgba(119, 153, 176, 0.2);
  padding: 83px 16px 16px 16px;
  font-weight: 700;
  color: #3d6f91;
  border-radius: 10px;
  font-style: italic;
}
.service-header__text blockquote::before,
.price-list__text blockquote::before,
.service-text__column blockquote::before,
.service-text blockquote::before {
  content: "";
  position: absolute;
  left: 34px;
  top: 30px;
  display: block;
  width: 60px;
  height: 38px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 34 26'%3E%3Cpath fill='%233d6f91' d='M0 16.07c.06-.47.102-.944.181-1.412.44-2.571 1.521-4.86 3.097-6.924 1.82-2.385 4.047-4.341 6.483-6.07.77-.545 1.57-1.05 2.348-1.582.204-.14.315-.098.43.105.15.263.323.513.492.764.178.265.178.288-.094.465A30.218 30.218 0 0 0 9.161 4.3C7.784 5.545 6.545 6.902 5.648 8.543c-.61 1.115-1.009 2.294-1.094 3.57-.005.06 0 .119 0 .227.2-.12.375-.22.546-.326 3.801-2.316 8.794-.686 10.357 3.48.9 2.395.844 4.814-.595 7.055-.714 1.112-1.724 1.889-2.94 2.39-1.981.82-4.014 1.04-6.098.443-2.774-.794-4.58-2.576-5.34-5.364-.217-.797-.296-1.631-.438-2.448L0 17.319V16.07Zm22.091-3.357c.324-.232.587-.442.87-.621 3.845-2.437 8.93-.783 10.537 3.477.967 2.564.515 4.953-1.17 7.112-1.495 1.916-3.496 2.818-5.884 2.967-1.304.081-2.584-.006-3.806-.502-2.49-1.012-4.048-2.837-4.69-5.441-.791-3.207-.286-6.26 1.15-9.185 1.159-2.36 2.854-4.31 4.77-6.079 1.79-1.652 3.764-3.056 5.813-4.363.194-.124.298-.102.41.094.155.27.322.537.508.786.155.204.12.32-.089.454a29.343 29.343 0 0 0-3.527 2.654c-1.347 1.183-2.585 2.463-3.515 4.01-.794 1.318-1.337 2.718-1.377 4.279-.003.102 0 .204 0 .358Z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
}

@media (min-width: 1024px) {
  .service-header__text blockquote,
  .price-list__text blockquote {
    background-color: rgba(119, 153, 176, 0.2);
    padding: 83px 32px 30px 36px;
  }
}
.price-list__text_row {
  margin-bottom: 25px;
}

.price-list__text_row:last-child {
  margin-bottom: 0;
}

.price-list__text_row.columns {
  display: flex;
  justify-content: space-between;
}

.price-list__text_row.columns .price-list__text_column {
  width: calc(50% - 13px);
}

.price-list__text_title {
  font-weight: bold;
  font-size: 17px;
  color: #447595;
  margin-bottom: 15px;
}

.price-list__text ul {
  list-style-type: none;
}

.price-list__text li {
  margin-bottom: 15px;
}

.open-form {
  margin-bottom: 50px;
}

.open-form .success {
  font-size: 30px;
  text-align: center;
  color: #4d4;
  padding: 30px;
}

.open-form__container {
  background: #7799B0;
  border-radius: 10px;
  padding: 36px 36px 16px 36px;
}

.open-form__form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.review_form .open-form__form {
  align-items: flex-start;
}

.review_form .open-form__form .open-form__field:first-child {
  width: 274px;
}

.review_form .open-form__form .open-form__field:first-child label {
  margin-top: 16px;
}

.review_form .open-form__form .open-form__field:first-child label:first-child {
  margin-top: 0;
}

.review_form .open-form__form .open-form__field:nth-child(3) {
  width: 528px;
  max-width: 100%;
  margin-right: 56px;
}

.review_form .open-form__form .open-form__field:nth-child(3) textarea {
  height: 160px;
}

.review_form .open-form__form .open-form__field:last-child {
  width: 200px;
}

.review_form .open-form__form .open-form__field:last-child .info_text {
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #FFFFFF;
}

.review_form .open-form__form .orange-btn {
  width: 100%;
}

.open-form__field {
  width: 23%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.open-form__field label,
.show-more {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.open-form__field input,
.open-form__field select,
.open-form__field textarea {
  color: #447595;
  border-radius: 5px;
  padding: 5px 10px;
  background: #fff;
  border: 0;
}

.open-form__field textarea {
  min-height: 100px;
}

.open-form__field input[type=file] {
  background: transparent;
  color: #FFFFFF;
}

.open-form__form .orange-btn {
  width: 23%;
  margin: 0 0 20px 0;
}

.open-form__title {
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 15px;
}

/*Прайс лист*/
/*Старая оплата*/
.sumform {
  background: #7799b0;
  width: fit-content;
  border-radius: 18px;
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  -khtml-border-radius: 18px;
  behavior: url(/htdocs/PIE.htc);
  color: #fff;
  text-align: center;
  padding: 13px 16px 16px;
  left: 70%;
  overflow: auto;
  visibility: auto;
}

.payrobocassa .sumform {
  text-align: center;
}

.sumform h4 {
  color: #fff;
  text-align: center;
  margin: 2px 0 16px 0;
  text-shadow: 0px 1px 0px #020202;
}

.sumform p {
  padding: 0 0 6px 7px;
  font-size: 12px;
  margin: 0 0 0 0;
}

form .submit1 {
  border-radius: 18px;
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  -khtml-border-radius: 18px;
  text-align: center;
  text-shadow: 0px 0px 0px #fff;
  behavior: url(/htdocs/PIE.htc);
  bordeR: none;
  color: #333;
  font: bold 14px Arial;
  padding: 5px 5px 5px 5px;
  padding: 9px 31px 11px 28px;
  background: transparent url("/htdocs/images1/submit1.png") repeat-x 0 0;
  margin-right: 6px;
  margin-top: 8px;
  cursor: pointer;
  algin: right;
  margin: 10px 75px 0 0;
}

/*Старая оплата*/
/*Посадочная страница*/
.service-header {
  padding: 70px 0 90px 0;
  background: #D8EDF5 url(/htdocs/img/main_slide_bg.png) center bottom no-repeat;
}

.service-header-new {
  --gap-size: 30px;
  padding: 64px 0 90px 0;
  background: #D8EDF5 url(/htdocs/img/main_slide_bg.png) center bottom no-repeat;
  overflow: hidden;
}

.service-header .success {
  text-align: center;
  font-size: 20px;
  line-height: 1.5;
  color: #5a5;
}

.service-header-new .container {
  display: flex;
  align-items: flex-start;
  column-gap: var(--gap-size);
}

.service-header .container {
  display: flex;
  align-items: flex-start;
}

.service-header__info {
  padding-right: 100px;
}

.service-header__price_title {
  padding-top: 140px;
  line-height: 1;
  text-transform: uppercase;
  margin: 0;
  color: #447595;
  font-size: 30px;
  font-family: Inter, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 2.55px;
}

.service-header__price_h3 {
  max-width: 167px;
  color: #447595;
  font-size: 20px;
  font-family: Inter, Arial, sans-serif;
  font-weight: 800;
  padding-bottom: 0;
  line-height: 1.2;
  letter-spacing: 0.085em;
}

.service-header__block_title {
  padding-block: 18px;
  padding-right: calc(86px + var(--gap-size));
  position: absolute;
  left: 0;
  width: calc(50% + 112px);
  background: #FEB021;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;
}

.service-header__video {
  aspect-ratio: 16/9;
  padding: 13px;
  border-radius: 20px;
  border: 2px solid #FFF;
}

.service-header__video-right {
  position: relative;
  border-radius: 20px;
  grid-row: span 2;
  order: -1;
  z-index: 2;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.service-header__block_wraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 902px;
  gap: 25px;
}

.service-header__text_h1 {
  text-transform: uppercase;
  font-family: Inter, Arial, sans-serif;
  color: #FFF;
  font-size: 34px;
  font-weight: 800;
  letter-spacing: 2.975px;
}

.service-header__info_left {
  display: grid;
  gap: 40px;
  flex-basis: calc(50% - var(--gap-size) / 2);
  flex-shrink: 0;
}

.service-header__info_left .service-header__text_h1 {
  padding: 0 20px 0 40px;
}

.service-header__info_right {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: auto 1fr;
  row-gap: 13px;
  align-content: flex-start;
  align-items: flex-start;
  flex-basis: calc(50% - var(--gap-size) / 2);
  flex-shrink: 0;
}

.service-header__text-wraper {
  width: calc(50svw - var(--gap-size) / 2 - 310px);
}

.service-header__info_right::before {
  content: "";
  position: absolute;
  top: -28px;
  left: -18px;
  border-radius: 20px;
  border: 2px solid #FFF;
  width: 587px;
  height: 349px;
}

.service-header__video-wrapper {
  order: -1;
  height: 547px;
  width: 300px;
}

.service-header__text-right_wrapper {
  width: 50%;
}

.service-header__text-right {
  position: relative;
  margin: 0;
  padding: 28px 22px 39px 36px;
  background: #447595;
  left: -15px;
}

.service__text_h2 {
  color: #FFF;
  font-size: 20px;
  font-family: Inter, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1.7px;
}

.service__text_h5 {
  color: #FFF;
  font-size: 15px;
  font-family: Inter;
  letter-spacing: 0.675px;
  font-family: Inter, Arial, sans-serif;
  margin: 0;
}

.service-header__form {
  margin-top: 12px;
  z-index: 3;
}

.service-header__info_right .first {
  justify-items: flex-start;
}

.service-header__video-frame {
  height: auto;
  width: 100%;
  border-radius: 20px;
  background: url(<path-to-image>), lightgray 50%/cover no-repeat;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  aspect-ratio: 16/9;
}

.h1-big {
  font-size: 30px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #447595;
}

.service-header__title {
  margin-bottom: 16px;
}

.service-header__price {
  padding-top: 10px;
  line-height: 1;
  text-transform: uppercase;
  /*margin: 0;*/
  margin-bottom: 50px;
  color: #FEB021;
  font-size: 30px;
  font-family: Inter, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 2.55px;
}

.service-header__text {
  font-size: 16px;
  line-height: 1.5;
  color: #333333;
}

.plusses-block {
  padding: 50px 0 0 0;
}

.plusses-block ul,
.service-text__columns ul {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.plusses-block li {
  width: 48%;
  padding-left: 34px;
  position: relative;
  margin-bottom: 50px;
  font-size: 16px;
  line-height: 1.5;
  color: #333333;
}

.service-text__columns li {
  padding-left: 34px;
  padding-right: 10px;
  position: relative;
  margin-bottom: 17px;
  font-size: 16px;
  line-height: 1.5;
  color: #333333;
}

.plusses-block li::before,
.service-text__columns li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  background-image: url("/htdocs/img/check.svg");
  width: 18px;
  height: 20px;
  z-index: 0;
}

.types-block {
  padding: 50px 0 30px 0;
  margin: 50px 0;
  background-color: #D8EDF5;
}

.types-block__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.types-block__item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 30%;
  height: 170px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.types-block__item {
  background-size: cover !important;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  line-height: 1.2;
}

.service-text {
  padding: 50px 0;
  margin: 50px 0;
  background: linear-gradient(to left, rgba(216, 237, 245, 0.8), rgba(216, 237, 245, 0.8)), #EBEBEB url("/htdocs/img/service-text-bg.png") no-repeat center right;
}
.service-text p img {
  margin: 10px;
}

.service-text__more {
  display: none;
  font-size: 14px;
  line-height: 1;
  color: #447595;
  width: 100%;
  text-align: center;
  cursor: pointer;
  position: relative;
  padding-bottom: 15px;
}

.service-text__more::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("/htdocs/img/text-more.svg") no-repeat center;
  height: 15px;
}

.service-text__columns {
  display: flex;
  justify-content: space-between;
}

.service-text__columns * {
  font-size: 16px;
  line-height: 1.5;
  color: #333333;
}

.service-text__column {
  flex-basis: 48%;
  flex-grow: 1;
}

.service-text p {
  margin-bottom: 25px;
}

.service-text #column_left,
.service-text #column_right {
  float: unset;
}

.service-text__columns *.service-text_subtitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #447595;
  margin-bottom: 15px;
}

.captcha_block > div {
  display: flex;
  margin: 10px 0 30px;
}

.captcha_block > div input {
  width: 100px;
  margin-right: 10px;
  height: 26px;
}

/*Посадочная страница*/
.contact_block {
  width: 738px;
}

.contact_block .subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 25px;
  color: #FEB021;
  margin: 0 0 26px;
}

.contact_block ul {
  background: #fff;
  padding: 25px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 26px;
}

.contact_block ul li {
  display: flex;
  margin-right: 36px;
  margin-bottom: 55px;
  max-width: 215px;
}

.contact_block ul li:nth-child(3),
.contact_block ul li:nth-child(5) {
  margin-right: 0;
}

.contact_block ul li:nth-child(4),
.contact_block ul li:nth-child(5) {
  margin-bottom: 0;
}

.contact_block ul li > div:first-child {
  margin-right: 16px;
}

.contact_block ul li .title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #447595;
  margin: 0 0 10px;
  text-transform: none;
}

.contact_block ul li p a,
.contact_block ul li p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  display: block;
  margin: 0 0 5px;
}

.side-form__form.order_form {
  width: 460px;
  padding: 30px 25px;
}

.service-header__form {
  position: relative;
  top: auto;
  left: -48px;
  width: calc(100% + 48px);
}

.service-header__form .field_list {
  column-gap: 20px;
}

.service-header__form .side-form__form_row {
  width: calc(50% - 10px);
  flex-basis: calc(50% - 10px);
}

.bottom_text {
  color: #0C3A58;
  font-size: 17px;
  font-family: Inter, Arial, sans-serif;
  letter-spacing: 1.445px;
  width: 200%;
  margin: 0;
  margin-top: 20px;
}

.side-form__form.order_form .side-form__form_row {
  width: 190px;
}

.side-form__form.order_form .side-form__form_row > label {
  white-space: pre;
}

.field_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.field_list .side-form__form_btn {
  flex: 1;
  text-align: center;
  padding: 14px 0;
}

.input-file-list {
  display: flex;
  flex-wrap: wrap;
  gap: 6px 12px;
  align-items: center;
}

.input-file-list-item:last-child {
  margin-bottom: 10px;
  flex: 100%;
}

.open-form__form .input-file-list-item:last-child {
  margin-bottom: 0;
}

.input-file-list-item {
  display: flex;
  align-items: center;
  gap: 6px;
}

.input-file-list-remove {
  color: white;
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;
}

.hide_file {
  width: 0;
  height: 0;
  opacity: 0;
}

.side-form__form.order_form .side-form__form_input {
  display: block;
}

.side-form__form.order_form .side-form__form_input label.choose_file + input,
.open-form__field input[type=file] {
  display: none;
}

.service-header.contacts_header .container {
  align-items: flex-end;
}

.service-header.payments {
  padding: 0px 0 90px 0;
}

.payment_block {
  padding: 70px 0 50px;
}

.payment_block .container {
  display: flex;
  align-items: flex-start;
}

.payment_block .image {
  margin-right: 36px;
  width: 270px;
}

.payment_block .image img {
  max-width: 100%;
}

.payment_block .text {
  width: 500px;
  max-width: 100%;
  margin-right: 43px;
}

.payment_block .text div {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
}

.payment_block .text div:first-child {
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: #447595;
  margin-bottom: 24px;
}

.payment_block .sumform {
  background: #7799B0;
  border-radius: 10px;
  width: 386px;
  max-width: 100%;
  padding: 36px 26px;
  text-align: left;
}

.payment_block .sumform h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin: 0 0 16px;
  text-align: left;
}

.payment_block .sumform form p:first-child {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  margin: 0 0 10px;
}

.payment_block .sumform form p:nth-child(2) {
  color: #FFFFFF;
  display: flex;
  align-items: center;
}

.payment_block .sumform form p:nth-child(2) input {
  width: 312px;
  max-width: 100%;
  padding: 6px 10px;
  background: #fff;
  border: 0;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #447595;
  margin-right: 16px;
}

.payment_block .sumform form input[type=submit] {
  background: #FEB021;
  border-radius: 35px;
  width: 210px;
  text-align: center;
  padding: 14px 0;
  color: #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  text-shadow: 0 0 black;
}

.payment_list {
  background: #D8EDF5 url(/htdocs/img/main_slide_bg.png) center bottom no-repeat;
  padding: 50px 0 80px;
  margin: 0 0 50px;
}

.payment_list .title {
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 40px;
  text-transform: uppercase;
  color: #447595;
  margin: 0 0 24px;
}

.payment_list .list {
  display: flex;
}

.payment_list .list .item {
  width: 385px;
  padding: 0 26px;
  border-radius: 26px;
  margin-right: 40px;
  background: #fff;
}

.payment_list .list .item img {
  max-width: 100%;
  margin-bottom: 26px;
  display: block;
}

.payment_list .list .item .subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  color: #447595;
}

.payment_list .list .item p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
}

body > iframe {
  display: none;
}

.errors.side-form__form_input textarea,
.errors.side-form__form_input input {
  background-color: rgba(230, 110, 110, 0.6);
}

.side-form__form_input_error {
  display: none;
  font-size: 12px;
  padding-top: 5px;
  color: #b44;
}

.errors.side-form__form_input .side-form__form_input_error {
  display: block;
}

.open_form_success {
  background: rgba(119, 153, 176, 0.3215686275);
  width: 460px;
  padding: 30px 25px;
  flex-shrink: 0;
}

#wrapper.payrobocassa {
  min-height: auto;
}

.circle_ol li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 10px;
  height: 10px;
  background-color: #FEB021;
  border-radius: 50%;
}

.circle_ol li {
  padding-left: 34px;
  position: relative;
  margin-bottom: 15px;
}

/*Страница 404*/
.page-404 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(to top, #fff 60px, transparent 60px), #D8EDF5 url(/htdocs/img/main_slide_bg.png) center bottom 60px no-repeat;
  padding: 60px 0 160px 0;
}

.page-404__image {
  margin-bottom: 20px;
  width: 400px;
  max-width: calc(100% - 80px);
}

.page-404__text {
  font-size: 24px;
  line-height: 1.2;
  text-align: center;
  color: #5E5E5E;
  font-weight: bold;
  margin-bottom: 20px;
}

.page-404__btn {
  text-decoration: none;
}

/*Страница 404*/
/*О компании*/
.about-header {
  background: rgba(229, 229, 229, 0.3);
}

.about-header__container {
  background: url("/htdocs/img/about-bg.png") right bottom no-repeat;
  background-size: auto 100%;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 70px 0 90px 0;
}

.about-header__content {
  width: 500px;
  max-width: 100%;
  margin-right: 76px;
}

.about-header__title {
  font-weight: bold;
  font-size: 50px;
  text-transform: uppercase;
  color: #447595;
  margin: 0 0 16px 0;
}

.about-header__text {
  font-size: 14px;
  line-height: 1.8;
  color: #333333;
}

.about-header__text p:not(:last-child) {
  margin: 0 0 16px 0;
}

.about-header__catchphrase {
  padding: 20px;
  background: #7799B0;
  border-radius: 10px;
  width: 260px;
  font-size: 35px;
  text-align: center;
  color: #FFFFFF;
}

.about-types {
  background: #D8EDF5;
  padding: 50px 0;
}

.about-types__container {
  line-height: 1.2;
}

.section-title {
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  color: #447595;
  margin-bottom: 50px;
}

.about-types__list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: -11px;
}

.about-types__item {
  margin: 11px;
  width: 230px;
  padding: 36px 55px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
}

.about-types__icon {
  margin-bottom: 36px;
}

.about-types__text {
  font-size: 17px;
  text-align: center;
  color: #447595;
}

/*О компании*/
/*История*/
.history {
  background: #fff;
  padding: 50px 0 100px 0;
}

.history__container {
  line-height: 1.8;
}

.history__list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}

.history__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-width: 240px;
  padding: 0 0 46px 0;
  position: relative;
  height: 256px;
  overflow: hidden;
}

.history__item::before {
  content: "";
  position: absolute;
  bottom: 15px;
  border: 3px solid #447595;
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: calc(50% - 8px);
  z-index: 2;
}

.history__item::after {
  content: "";
  position: absolute;
  bottom: 22.5px;
  height: 3px;
  background: #447595;
  left: 0;
  right: 0;
  z-index: 1;
}

.history__title.section-title {
  margin: 0 0 20px 0;
}

.history__text {
  font-size: 14px;
  margin: 0 0 16px 0;
}

.history__year {
  font-size: 60px;
  line-height: 1;
  text-transform: uppercase;
  color: #FEB021;
}

.history__item:nth-child(even) {
  flex-direction: column-reverse;
  margin-top: 210px;
  padding: 46px 0 0 0;
}

.history__item:nth-child(even)::before {
  bottom: unset;
  top: 15px;
}

.history__item:nth-child(even)::after {
  bottom: unset;
  top: 20.5px;
}

.history__item:nth-child(even) .history__text {
  margin: 16px 0 0 0;
}

.history__item:first-child::after {
  left: 50%;
}

.history__item:last-child::after {
  right: 50%;
}

/*История*/
/*Отзывы в сервисах*/
.reviews-service__title {
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 28px;
}

.reviews-service__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.reviews-service__item {
  padding: 35px 110px 35px 60px;
  width: calc(50% - 50px);
  border-radius: 10px;
  margin-bottom: 65px;
  text-decoration: none;
}

.reviews-service__item--yandex {
  background: #FFDE00 url("/htdocs/img/reviews/yandex-mobile.png") right -1px top no-repeat;
  background-size: auto 90%;
}

.reviews-service__item--google {
  background: #0086F8 url("/htdocs/img/reviews/google-mobile.png") right -1px top no-repeat;
  background-size: auto 90%;
}

.reviews-service__item--google .reviews-service__text {
  color: #fff;
}

.reviews-service__text {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.price_page_en {
  padding: 40px 0;
}

/*Отзывы в сервисах*/
/*до после*/
.new_trans {
  display: flex;
  align-items: flex-start;
  margin-bottom: 50px;
}

.before-after {
  display: flex;
  flex-direction: column;
  width: 450px;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 60px;
  padding: 0 75px;
  border: 3px solid #D8EDF5;
  border-radius: 10px;
}

.before-after img {
  width: 100%;
  max-width: 300px;
}

.before-after .wrap {
  padding: 0;
}

.before-after .twentytwenty-overlay {
  display: none !important;
}

.before-after .twentytwenty-container {
  overflow: visible;
}

.before-after .before-label,
.before-after .after-label {
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  color: #447595;
  text-align: center;
  position: absolute;
  bottom: -3px;
  background: #D8EDF5;
  z-index: 20;
  padding: 5px 16px;
  border-radius: 10px;
}

.before-after .before-label {
  left: -78px;
}

.before-after .after-label {
  right: -78px;
}

.twentytwenty-horizontal .twentytwenty-handle:before,
.twentytwenty-horizontal .twentytwenty-handle:after {
  background: #D8EDF5;
  margin-left: -2px;
}

.twentytwenty-handle {
  border: 1px solid transparent;
  box-shadow: unset;
  background: url("/htdocs/images/before_after/before-after-handle.svg") no-repeat center center;
  background-size: 100% 100%;
  height: 30px;
  width: 30px;
  border-radius: 0;
  margin-left: -15px;
}

.twentytwenty-left-arrow,
.twentytwenty-right-arrow {
  display: none;
}

.plusses-block .new_trans_desc li {
  width: 100%;
  margin-bottom: 25px;
}

/*до после*/
/*Вопрос ответ*/
.questions-block {
  padding: 50px 0;
}

.questions-block__title {
  font-size: 35px;
  text-transform: uppercase;
  color: #447595;
  font-weight: 400;
  margin-bottom: 25px;
}

.questions-block__item {
  width: 100%;
}

.questions-block__item:not(:last-child) {
  margin-bottom: 25px;
}

.question {
  border-radius: 10px;
  overflow: hidden;
  background-color: rgba(119, 153, 176, 0.2);
}

.question__header {
  background-color: #7799B0;
  color: #fff;
  font-weight: bold;
  padding: 15px 40px 15px 15px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}

.question__header_text {
  color: inherit;
  font-size: 17px;
}

.question--opened .question__header {
  background-color: rgba(119, 153, 176, 0.2);
  color: #447595;
}

.question__header_icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.question--opened .question__header_icon {
  transform: translateY(-50%) rotate(180deg);
}

.question__header_icon path {
  fill: #fff;
}

.question--opened .question__header_icon path {
  fill: #447595;
}

.question__body {
  display: none;
  padding: 15px;
  font-size: 16px;
  line-height: 1.55;
}

.question--opened .question__body {
  display: block;
}

.questions-block__legal {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 2px solid #D8EDF5;
}

.questions-block__legal-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  margin-bottom: 40px;
}

.questions-block__legal-title span {
  color: #447595;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.questions-block__legal-title svg {
  flex: 0 0 auto;
}

.question--legal .question__body {
  position: relative;
}

.question--legal .question__body::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("/htdocs/img/question-legal-bg.png");
  background-size: cover;
  background-position: top -145px right;
  background-repeat: no-repeat;
  opacity: 0.2;
}

.question__main,
.question__source {
  position: relative;
  z-index: 1;
}

.question__main-title {
  font-weight: 700;
  font-size: 16px;
}

.question__main-title {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 15px;
}

.question__main-text {
  font-size: 16px;
  line-height: 1.75;
}

.question__source {
  padding: 20px 0 0 40px;
}

.question__source-title {
  font-weight: 700;
  margin-bottom: 15px;
}

.question__source-text {
  list-style-type: none;
  padding: 0 0 0 15px;
  margin: 0;
  border-left: 2px solid #447595;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

/*Вопрос ответ*/
/*Доставка*/
.delivery_block {
  position: relative;
  overflow: hidden;
  padding: 24px 0 90px 0;
}

.service-header.delivery_header {
  position: relative;
  overflow: hidden;
}

.delivery_header {
  background: unset;
}

.delivery_block::after,
.service-header.delivery_header::after {
  content: url("/htdocs/img/delivery/postman.png");
  position: absolute;
  left: calc(50% + 300px);
  bottom: 0;
  line-height: 0;
}

.delivery_main {
  width: 100%;
}

.delivery_attention {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 24px;
  width: 100%;
  margin-bottom: 36px;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.delivery_attention_image {
  flex-shrink: 0;
  margin-right: 24px;
}

.delivery_attention_text p {
  margin: 0 0 7px;
}

.delivery_attention_text p:last-of-type {
  margin-bottom: 0;
}

.delivery_attention_text .bold {
  font-weight: bold;
}

.delivery_attention_text .blue {
  color: #447595;
}

.delivery_service {
  font-weight: 400;
  font-size: 16px;
  color: #447595;
  margin-bottom: 16px;
}

.delivery_list {
  max-width: calc(100% - 329px);
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
}

.delivery_item {
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: 90px auto;
  gap: 20px;
  padding: 15px 35px;
  background-color: #FFFFFF;
  border-radius: 10px;
  border: 2px solid #7297AD;
}

.delivery_item::before {
  content: "";
  position: absolute;
  background-image: url("/htdocs/img/logo-no-text.png");
  display: block;
  background-repeat: no-repeat;
  background-position: right;
  bottom: 5px;
  top: 15px;
  right: 5px;
  width: 100%;
  background-size: contain;
}

.delivery_item-single .delivery_item_title {
  max-width: 105px;
  line-height: 1.4;
}

.delivery_item_image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.delivery_item_title {
  font-weight: 700;
  font-size: 17px;
  color: #447595;
}

.delivery_item_desc {
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  margin-top: 5px;
}

/*Доставка*/
/*Бюро*/
.cooperation-top {
  position: relative;
  margin: 0;
}

.cooperation-top .container {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1354px;
  width: 100%;
}

.service-header--cooperation {
  position: relative;
  z-index: 0;
  background: url(/htdocs/img/main_slide_bg.png) center bottom no-repeat;
}

.cooperation-top__img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.cooperation-top__img2 {
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.service-header--cooperation .h1-big {
  color: #FEB021;
}

.service-header--cooperation .service-header__text p {
  font-size: 23px;
  line-height: 122%;
  color: #FFFFFF;
}

.service-header--cooperation .service-header__text {
  margin-bottom: 30px;
  max-width: 733px;
  font-weight: 400;
}

.service-header__btn {
  margin-top: 0;
  transition: background-color 0.3s ease-in-out;
}

.service-header__btn:hover,
.service-header__btn:focus {
  background-color: #ed9900;
}

.cooperation-top .side-form__form {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.cooperation-top.opened .side-form__form {
  opacity: 1;
}

.cooperation-top.opened .service-header__text {
  max-width: 655px;
}

.cooperation-top.opened .cooperation-top__img2 {
  display: block;
  opacity: 1;
}

.service-header__btns {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 24px;
  column-gap: 24px;
}

.orange-btn--blue {
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  text-decoration: none;
  background-color: #7799B0;
  transition: background-color 0.3s ease-in-out;
}

.orange-btn--blue:hover,
.orange-btn--blue:focus {
  background-color: #627E91;
}

@media (max-width: 1024px) {
  .cooperation-top .service-header__btn,
  .service-header__btns {
    order: 3;
    margin-bottom: 30px;
  }
  .cooperation-top .container {
    align-items: center;
  }
  .cooperation-top .service-header__info {
    justify-items: center;
    align-items: center;
  }
  .cooperation-top .side-form__form {
    height: 0;
  }
  .cooperation-top.opened .side-form__form {
    height: auto;
  }
}
@media (max-width: 820px) {
  .service-header--cooperation .service-header__text p {
    font-size: 16px;
    line-height: 24px;
  }
}
.conditions {
  margin: 0;
}

.conditions .container {
  padding-top: 70px;
  padding-bottom: 70px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1354px;
  width: 100%;
}

.conditions__title {
  margin-top: 0;
  margin-bottom: 39px;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  text-transform: uppercase;
  color: #447595;
}

.conditions__list {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  -moz-column-gap: 22px;
  column-gap: 22px;
  row-gap: 20px;
  padding-top: 40px;
  padding-left: 40px;
  padding-bottom: 40px;
  padding-right: 47px;
  background: #D8EDF5;
  border-radius: 15px;
}

@media (max-width: 1000px) {
  .conditions__list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding-top: 23px;
    padding-left: 18px;
    padding-bottom: 23px;
    padding-right: 18px;
  }
}
@media (max-width: 820px) {
  .conditions__title {
    margin-bottom: 18px;
    font-size: 18px;
    line-height: 24px;
  }
  .conditions__list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (max-width: 600px) {
  .conditions__list {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    -moz-column-gap: 15px;
    column-gap: 15px;
  }
}
.conditions-item {
  display: grid;
  grid-template-columns: 38px 1fr;
  row-gap: 13px;
  -moz-column-gap: 10px;
  column-gap: 10px;
  align-items: flex-start;
  align-content: flex-start;
  padding-top: 15px;
  padding-left: 18px;
  padding-bottom: 22px;
  padding-right: 6px;
  color: #ffffff;
  background: #447595;
  border-radius: 15px;
}

.conditions-item__svg {
  margin: 0;
  display: flex;
  justify-content: center;
  color: #FEB021;
  fill: #FEB021;
}

.conditions-item__name {
  align-self: center;
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 129%;
  color: #FFFFFF;
}

.conditions-item__text {
  grid-column: 1/-1;
  padding-top: 9px;
  margin: 0;
  font-size: 12px;
  line-height: 133%;
  color: #FFFFFF;
  border-top: 1px solid rgba(254, 176, 33, 0.2);
}

.cooperation-adv {
  margin: 0;
}

.cooperation-adv .container {
  padding-top: 35px;
  padding-bottom: 100px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1354px;
  width: 100%;
}

.cooperation-adv__title {
  margin-bottom: 21px;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  text-transform: uppercase;
  color: #447595;
}

.cooperation-adv__list {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  -moz-column-gap: 20px;
  column-gap: 20px;
  justify-content: space-between;
}

@media (max-width: 1150px) {
  .cooperation-adv__list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 40px;
  }
}
@media (max-width: 820px) {
  .cooperation-adv .container {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 63px;
  }
  .cooperation-adv__title {
    margin-bottom: 19px;
    font-size: 18px;
    line-height: 24px;
  }
}
@media (max-width: 600px) {
  .cooperation-adv__list {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.adv-item {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 27px;
  justify-items: center;
}

.adv-item__text {
  max-width: 100%;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  color: #000000;
}

.cooperation-options {
  position: relative;
  margin: 0;
  background-color: #D8EDF5;
}

.cooperation-options__wrapper {
  overflow: hidden;
}

.cooperation-options__img2 {
  position: absolute;
  top: 37%;
  left: -18%;
}

.cooperation-options__img2 img {
  position: relative;
  z-index: 1;
}

.cooperation-options__img2::before {
  content: "";
  position: absolute;
  bottom: -79%;
  left: 30%;
  width: 518px;
  height: 518px;
  background-color: #b0d9ee;
  border-radius: 50%;
}

.cooperation-options__img {
  position: absolute;
  right: -4%;
  bottom: -19%;
}

.cooperation-options__img::after {
  content: "";
  position: absolute;
  right: -33%;
  bottom: -69%;
  width: 713px;
  height: 713px;
  background-color: #b0d9ee;
  border-radius: 50%;
}

.cooperation-options__img img {
  position: relative;
  z-index: 1;
  -o-object-fit: contain;
  object-fit: contain;
}

.cooperation-options .container {
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: 55% 1fr;
  padding-top: 105px;
  padding-bottom: 105px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1354px;
  width: 100%;
}

.cooperation-options__content {
  max-width: 698px;
  z-index: 2;
}

.cooperation-options__content h2,
.service-text__column h2 {
  margin-top: 0;
  margin-bottom: 31px;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  text-transform: uppercase;
  color: #447595;
}

.cooperation-options__content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 138%;
  color: #000000;
}

@media (max-width: 1450px) {
  .cooperation-options__img2 {
    position: absolute;
    top: 4%;
    left: -26px;
  }
  .cooperation-options__img2 img {
    position: relative;
    z-index: 1;
  }
  .cooperation-options__img2::before {
    content: "";
    position: absolute;
    bottom: -79%;
    left: 30%;
    width: 518px;
    height: 518px;
    background-color: #b0d9ee;
    border-radius: 50%;
  }
  .cooperation-options__img {
    width: 50%;
  }
}
@media (max-width: 820px) {
  .cooperation-options .container {
    padding-top: 66px;
    padding-bottom: 292px;
    grid-template-columns: 1fr;
  }
  .cooperation-options__content h2 {
    margin-bottom: 11px;
    font-size: 18px;
    line-height: 24px;
  }
  .cooperation-options__img2 {
    top: 52%;
    left: 59px;
  }
  .cooperation-options__img2::before {
    display: none;
  }
  .cooperation-options__img {
    right: 18%;
    bottom: -19%;
    width: 40%;
  }
  .cooperation-options__img img {
    width: 100%;
  }
  .cooperation-options__img::after {
    content: "";
    position: absolute;
    right: -108%;
    bottom: -69%;
    width: 695px;
    height: 695px;
    background-color: #b0d9ee;
    border-radius: 50%;
  }
}
@media (max-width: 600px) {
  .cooperation-options__img {
    right: 18%;
    bottom: -7%;
    width: 282px;
    height: auto;
  }
  .cooperation-options__img::after {
    right: -112%;
    bottom: -405px;
    width: 700px;
    height: 700px;
  }
  .cooperation-options__img2 {
    top: 50%;
    right: 3%;
    width: 70px;
    height: 80px;
    z-index: 1;
    left: auto;
  }
  .cooperation-options__img2 img {
    width: 100%;
    height: 100%;
  }
}
.cooperation-offers {
  margin: 0;
}

.cooperation-offers .container {
  padding-top: 105px;
  padding-bottom: 53px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1354px;
  width: 100%;
}

.cooperation-offers__title {
  margin-top: 0;
  margin-bottom: 39px;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  text-transform: uppercase;
  color: #447595;
}

.cooperation-offers__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
  justify-content: center;
  justify-items: center;
  list-style: none;
  counter-reset: list2;
}

.cooperation-offers__item {
  position: relative;
  overflow: hidden;
}

.cooperation-offers__item::after {
  counter-increment: list2;
  content: "0" counter(list2);
  position: absolute;
  bottom: 8.33px;
  right: 7.5px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 30px;
  line-height: 22px;
  text-transform: uppercase;
  color: #FEB021;
}

.cooperation-offers__item::before {
  content: "";
  position: absolute;
  bottom: -28.6px;
  right: -18.14px;
  width: 76px;
  height: 76px;
  border-radius: 50%;
  background-color: #F28518;
}

@media (max-width: 820px) {
  .cooperation-offers .container {
    padding-top: 67px;
    padding-bottom: 70px;
  }
  .cooperation-offer__h3 {
    margin-bottom: 19px;
    font-size: 18px;
    line-height: 24px;
  }
}
@media (max-width: 534px) {
  .cooperation-offers__list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .cooperation-offers__item {
    position: relative;
    counter-increment: div;
    overflow: hidden;
  }
  .cooperation-offers__item::after {
    bottom: 15px;
    right: 12px;
    font-weight: 700;
    font-size: 36px;
    line-height: 22px;
  }
  .cooperation-offers__item::before {
    bottom: -36px;
    right: -22px;
    width: 95px;
    height: 95px;
  }
}
.cooperation-offer {
  padding: 14px;
  padding-bottom: 29px;
  background: #FFFFFF;
  border: 2px solid #F28518;
  border-radius: 15px;
}

.cooperation-offer__img {
  margin-bottom: 24px;
  position: relative;
  aspect-ratio: 294/154;
  border-radius: 15px;
  overflow: hidden;
}

.cooperation-offer__img img {
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
}

.cooperation-offer__h3 {
  margin-top: 0;
  margin-bottom: 7px;
  padding-bottom: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 138%;
  text-transform: uppercase;
  color: #447595;
}

.cooperation-offer__content div {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 15px;
  font-size: 14px;
  line-height: 129%;
  color: #000000;
}

.cooperation-offer__content ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cooperation-offer__content ul li {
  position: relative;
  padding-left: 13px;
  font-size: 14px;
  line-height: 18px;
}

.cooperation-offer__content ul li::before {
  content: "";
  position: absolute;
  top: 6px;
  left: 0;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #F28518;
}

.cooperation-offer__content p {
  margin: 0;
}

@media (max-width: 534px) {
  .cooperation-offer {
    padding: 17px 16px 44px 16px;
  }
  .cooperation-offer__content {
    padding-right: 13px;
  }
}
.cooperation-txt {
  position: relative;
  margin-top: 0;
  margin-bottom: 105px;
}

.cooperation-txt__wrapper {
  position: relative;
  overflow: hidden;
}

.cooperation-txt__wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: linear-gradient(91.29deg, #D8EDF5 44.98%, rgba(216, 237, 245, 0) 92.44%);
}

.cooperation-txt__img {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  -o-object-fit: cover;
  object-fit: cover;
}

.cooperation-txt .container {
  position: relative;
  z-index: 1;
  padding-top: 106px;
  padding-bottom: 106px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1354px !important;
  width: 100%;
}

.coorperation-text__content h2 {
  margin-bottom: 31px;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  text-transform: uppercase;
  color: #447595;
}

@media (max-width: 820px) {
  .cooperation-txt {
    margin-bottom: 70px;
  }
  .coorperation-text__content h2 {
    margin-bottom: 12px;
    font-size: 18px;
    line-height: 24px;
  }
  .cooperation-txt__img {
    display: none;
  }
}
.cooperation-form {
  margin-top: 0;
  margin-bottom: 105px;
  padding-left: 15px;
  padding-right: 15px;
}

.cooperation-form .open-form {
  margin-bottom: 0;
}

.open-form__field label.choose_file {
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #447595;
  padding: 7px 14px;
  margin-bottom: 0;
}

.open-form__field span {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.open-form__field label.choose_file + input {
  display: none;
}

@media (max-width: 820px) {
  .cooperation-form {
    margin-bottom: 70px;
  }
}
.cooperation-field {
  margin-top: 0;
  margin-bottom: 105px;
}

.cooperation-field .container {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0;
  padding-bottom: 0;
  max-width: 1354px;
  width: 100%;
}

.cooperation-field__h2 {
  margin-bottom: 39px;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  text-transform: uppercase;
  color: #447595;
}

.cooperation-field__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  -moz-column-gap: 23px;
  column-gap: 23px;
  row-gap: 16px;
}

.cooperation-field__item {
  position: relative;
  text-decoration: none;
  padding-top: 26px;
  padding-left: 33px;
  padding-right: 94px;
  padding-bottom: 19px;
  min-height: 196px;
  background: #FFFFFF;
  border-radius: 15px;
  aspect-ratio: 314/196;
}

.cooperation-field__item p {
  position: relative;
  z-index: 1;
  font-weight: 400;
  font-size: 23px;
  line-height: 122%;
  color: #000000;
}

.cooperation-field__item img {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  aspect-ratio: 314/196;
}

@media (max-width: 670px) {
  .cooperation-field__list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    -moz-column-gap: 14px;
    column-gap: 14px;
    row-gap: 14px;
  }
  .cooperation-field__item {
    position: relative;
    padding-top: 16px;
    padding-left: 13px;
    padding-right: 24px;
    padding-bottom: 16px;
    min-height: auto;
  }
  .cooperation-field__item p {
    font-size: 14px;
    line-height: 20px;
  }
}
.work-advs {
  margin: 0;
}

.work-advs .container {
  margin-bottom: 66px;
  padding-top: 50px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  max-width: 1270px;
}

.work-advs__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
  -moz-column-gap: 60px;
  column-gap: 60px;
}

.work-advs__item {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.work-advs__svg {
  margin-bottom: 18px;
  width: 100px;
  height: 100px;
}

.work-advs__name {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 17px;
  line-height: 20px;
  color: #447595;
}

.work-advs__text {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

@media (max-width: 820px) {
  .work-advs__list {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    -moz-column-gap: 30px;
    column-gap: 30px;
    row-gap: 24px;
  }
}
.work-how {
  margin-top: 0;
  margin-bottom: 66px;
}

.work-how .container {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  max-width: 1270px;
}

.work-how__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(0, 200px));
  -moz-column-gap: 60px;
  column-gap: 60px;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  counter-reset: how;
}

.work-how__list li {
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-content: flex-start;
}

.work-how__list li::after {
  counter-increment: how;
  content: counter(how);
  order: -1;
  margin-bottom: 16px;
  font-weight: 900;
  font-size: 100px;
  line-height: 1;
  text-transform: uppercase;
  color: #D8EDF5;
}

.work-how__list li a {
  color: #447595;
}

.work-how__list li:before {
  content: "";
  position: absolute;
  top: 46px;
  left: 114px;
  width: 100px;
  border: 1px dashed #FEB021;
}

.work-how__list li:last-child::before {
  display: none;
}

@media (max-width: 820px) {
  .work-how {
    margin-bottom: 50px;
  }
}
@media (max-width: 600px) {
  .work-how__list {
    grid-template-columns: minmax(0, 1fr);
    row-gap: 10px;
  }
  .work-how__list li {
    grid-template-columns: 67px minmax(0, 1fr);
    align-items: center;
    -moz-column-gap: 10px;
    column-gap: 10px;
  }
  .work-how__list li::before {
    display: none;
  }
}
.work-text-ins {
  position: relative;
  margin-top: 0;
  margin-bottom: 50px;
  background: linear-gradient(to left, rgba(216, 237, 245, 0.8), rgba(216, 237, 245, 0.8)), #EBEBEB;
}

.work-text-ins .container {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 0;
  width: 100%;
  max-width: 1270px;
}

.work-text-ins__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.work-text-ins__content {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  -moz-column-gap: 100px;
  column-gap: 100px;
  z-index: 1;
}

@media (max-width: 820px) {
  .work-text-ins__content {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 50px;
  }
  .work-text-ins__img {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    height: auto;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
.work-langs {
  margin-top: 0;
  margin-bottom: 50px;
}

.work-langs .container {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  max-width: 1270px;
}

.work-langs__list {
  display: flex;
  justify-content: space-between;
  justify-items: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.work-langs__item {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 16px;
  flex-basis: 112px;
  justify-items: center;
  text-decoration: none;
}

.work-langs__item span {
  font-size: 17px;
  line-height: 20px;
  color: #447595;
}

.work-langs__item img {
  width: 100%;
}

.work-langs__item:hover span,
.work-langs__item:focus span {
  color: #F28518;
}

.work-types {
  margin-top: 0;
  margin-bottom: 50px;
}

.work-types .container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  -moz-column-gap: 80px;
  column-gap: 80px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  max-width: 1270px;
}

.work-types__block {
  display: grid;
  grid-template-columns: 100px 1fr;
  -moz-column-gap: 50px;
  column-gap: 50px;
  row-gap: 24px;
  align-content: flex-start;
  padding: 36px;
  border: 2px solid #447595;
  border-radius: 10px;
}

.work-types__svg {
  width: 100%;
  height: 100%;
}

.work-types__svg svg {
  width: 100%;
  height: 100%;
}

.work-types__name {
  align-self: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #447595;
}

.work-types__text {
  grid-column: 1/-1;
}

.work-types__text p {
  margin: 0;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 25px;
}

@media (max-width: 1100px) {
  .work-types .container {
    -moz-column-gap: 40px;
    column-gap: 40px;
  }
  .work-types__block {
    -moz-column-gap: 24px;
    column-gap: 24px;
    padding: 24px;
  }
}
@media (max-width: 820px) {
  .work-types .container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 24px;
  }
  .work-types__svg {
    width: 50px;
    height: 50px;
  }
  .work-types__name {
    font-size: 18px;
    line-height: 21px;
  }
  .work-types__block {
    grid-template-columns: 50px 1fr;
  }
}
.work-service-header {
  margin: 0;
}

.work-feedback {
  margin: 0;
  padding-left: 15px;
  padding-right: 15px;
}

.work-feedback .container {
  width: 100%;
  max-width: 1270px;
}

.work-tabs {
  margin-top: 0;
  margin-bottom: 66px;
}

.work-tabs .container {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  max-width: 1270px;
}

.work-tabs .price-list {
  margin-bottom: 24px;
}

.work-tabs .price-list__list {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 34px;
  padding-top: 54px;
  background-color: rgba(119, 153, 176, 0.2);
}

@media (max-width: 820px) {
  .price-list {
    margin-left: 0;
    margin-right: 0;
  }
  .work-tabs .price-list__list {
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 24px;
  }
}
.work-bottom {
  margin: 0;
}

.work-bottom .container {
  display: grid;
  grid-template-columns: 7fr 6fr;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  max-width: 1270px;
}

.work-bottom__content {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-items: flex-start;
  align-content: flex-start;
}

.work-bottom__content .text {
  margin-bottom: 30px;
}

.work-bottom__btn {
  justify-self: flex-start;
  padding: 20px;
  font-weight: 400;
  font-size: 35px;
  line-height: 40px;
  color: #FFFFFF;
  text-decoration: none;
  border: 0;
  background: #7799B0;
  border-radius: 10px;
}

.work-bottom__img {
  display: flex;
  justify-content: flex-end;
}

.work-bottom__img img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 820px) {
  .work-bottom .container {
    grid-template-columns: minmax(0, 1fr);
  }
  .work-bottom__content {
    margin-bottom: 24px;
  }
  .work-bottom__img {
    justify-content: center;
  }
}
.text {
  font-size: 16px;
  line-height: 138%;
  color: #000000;
}

.text p {
  margin-top: 0;
}

.text p:not(:last-child) {
  margin-bottom: 19px;
}

.text ol {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 11px;
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: li;
}

.text ol li {
  position: relative;
  padding-left: 24px;
}

.text ol li::before {
  counter-increment: li;
  content: counter(li) ".";
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 138%;
  color: #F28518;
}

.text ol:not(:last-child) {
  margin-bottom: 24px;
}

.text blockquote {
  position: relative;
  padding: 0;
  padding-left: 23px;
  font-weight: 400;
  font-size: 16px;
  line-height: 138%;
  color: #F28518;
}

.text blockquote:not(:last-child) {
  margin-bottom: 24px;
}

.text blockquote::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 7px;
  height: 43px;
  background-color: #F28518;
  border-radius: 2px;
}

.text ul,
.work-types__text ul {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 16px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.text ul li,
.work-types__text ul li {
  position: relative;
  padding-left: 34px;
}

.work-types__text ul li {
  font-size: inherit;
  line-height: inherit;
}

.work-types__text ul {
  font-size: 16px;
  line-height: 25px;
}

.text ul li::before,
.work-types__text ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-image: url("/htdocs/img/check.svg");
  width: 18px;
  height: 20px;
}

.text ul:not(:last-child),
.work-types__text ul:not(:last-child) {
  margin-bottom: 12px;
}

.text p + h4 {
  padding-top: 4px;
}

.text h3 {
  margin-top: 0;
  margin-bottom: 50px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #447595;
}

.text p {
  margin-top: 0;
  font-size: 12px;
  line-height: 156%;
}

.text p:not(:last-child) {
  margin-bottom: 16px;
}

.text h4 {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
}

.text h2 {
  margin-bottom: 50px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #447595;
}

.text--colls {
  -moz-column-width: 48%;
  column-width: 48%;
  -moz-column-count: 2;
  column-count: 2;
  -moz-column-gap: 23px;
  column-gap: 23px;
}

@media (max-width: 820px) {
  .text {
    font-size: 14px;
    line-height: 143%;
  }
  .text p:not(:last-child) {
    margin-bottom: 17px;
  }
  .text ol {
    row-gap: 9px;
    font-size: 14px;
    line-height: 20px;
  }
  .text blockquote {
    font-size: 14px;
    line-height: 20px;
  }
  .text img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  .text img:not(:last-child) {
    margin-bottom: 16px;
  }
  .text p + h2 {
    padding-top: 30px;
  }
}
@media (max-width: 740px) {
  .text--colls {
    -moz-column-width: 100%;
    column-width: 100%;
    -moz-column-count: 1;
    column-count: 1;
  }
}
.buro-slider img {
  height: unset;
}


/*Бюро*/
.cookie-city,
.choose-city {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 1000;
}

.popup__overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.33);
}

.cookie-city__popup {
  width: 203px;
  background: #F2F4F5;
  border-radius: 20px;
  padding: 16px 9px 20px;
  display: flex;
  flex-direction: column;
}

.cookie-city__popup p {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #1B1D1D;
  margin: 0;
}

p.cookie-city__city {
  font-weight: 700;
  margin-top: 1px;
}

.cookie-city__yes {
  margin: 12px 0 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
  background: #FEAF22;
  border-radius: 200px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  text-align: center;
  color: #FFFFFF;
  border-style: unset;
  cursor: pointer;
}

.cookie-city__no {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: 1px solid #437595;
  border-radius: 200px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  text-align: center;
  color: #437595;
  cursor: pointer;
}

.choose-city__popup {
  background: #F2F4F5;
  border-radius: 20px;
  padding: 70px 90px 55px;
  width: 1240px;
  display: flex;
  flex-direction: column;
  gap: 46px;
  margin: 50px;
}

.choose-city__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #4E7D9B;
  position: relative;
}

.choose-city__title::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -13px;
  border-top: 1px solid #E0E0E0;
  width: 100%;
}

.choose-city__cities {
  display: flex;
  flex-wrap: wrap;
  gap: 15px 70px;
}

.choose-city__item {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  /* identical to box height */
  width: calc((100% - 210px) / 4);
  color: #000000;
  cursor: pointer;
}

.text-short-block,
.text-short-block--main {
  margin: 30px 0;
}

.text-short,
.text-short--main {
  padding: 50px 78px 54px 36px;
  background-image: url("/htdocs/images/text-short-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
}

.text-short > h2,
.text-long > h2,
.text-short--main > h2 {
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  /* identical to box height */
  text-transform: uppercase;
  color: #447595;
  margin: 0 0 24px;
}

.text-long > h2 {
  margin-bottom: 9px;
}

.text-short > p,
.text-long > p,
.text-short--main > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* or 138% */
  color: #000000;
}

.text-long-block {
  padding: 40px 0 0;
}

.text-long div {
  width: calc((100% - 26px) / 2);
  display: inline-block;
  margin-top: 20px;
}

.text-long div:last-child {
  float: right;
}

.text-long h2 span,
.text-short--main h2 span {
  color: inherit;
  font-size: inherit;
}

.text-long p {
  margin: 0;
}

#js_before_after a {
  display: none;
}

.bottom_text.tight-text {
  letter-spacing: unset;
}

.open-form__form--review {
  align-items: flex-start;
}

.payment_list .list .item p.subtitle-prices {
  margin-top: 30px;
  color: #447595;
  font-size: 17px;
  font-weight: 700;
}

.payment_list .list .item p.text-little {
  width: 50%;
  font-size: 14px;
  margin-bottom: 30px;
}

@media (max-width: 1580px) {
  .main_slide .text {
    max-width: 500px;
  }
  .main_slide .text h1,
  .main_slide .text span:first-child {
    font-size: 20px;
  }
  .main_slide .text span:nth-child(2) {
    font-size: 30px;
    line-height: 40px;
  }
}
@media (max-width: 1300px) {
  .history__list {
    flex-direction: column;
  }
  .history__item,
  .history__item:nth-child(even) {
    flex-direction: column-reverse;
    padding: 0 0 36px 64px;
    height: unset;
    align-items: flex-start;
    margin: 0;
  }
  .history__item:last-child {
    padding-bottom: 0;
  }
  .history__item::before,
  .history__item:nth-child(even)::before {
    left: 22px;
    bottom: unset;
    top: 10px;
  }
  .history__item::after,
  .history__item:nth-child(even)::after {
    width: 3px;
    height: unset;
    top: 0;
    bottom: 0;
    left: 29px;
    right: unset;
  }
  .history__item:first-child::after {
    left: 29px;
    top: 10px;
  }
  .history__item:last-child::after {
    bottom: calc(100% - 10px);
    right: unset;
  }
  .history__text {
    margin: 0;
  }
  .history__year {
    font-size: 40px;
  }
  .delivery_item {
    padding: 16px;
  }
  .delivery_blockr::after,
  .service-header.delivery_header::after {
    left: auto;
    right: 24px;
  }
}
@media (max-width: 1200px) {
  .payment_list .list {
    flex-wrap: wrap;
  }
  .container,
  .main_slide .container {
    max-width: 100%;
  }
  .main_reviews_block {
    flex-wrap: wrap;
  }
  .main_reviews_block .reviews_list .item {
    margin-bottom: 16px;
    overflow: hidden;
  }
  .main_reviews_block .reviews_list .item:after {
    content: "";
    display: none;
  }
  .about-header__container {
    background: unset;
  }
  .about-header {
    background: unset;
  }
  .about-header__container {
    flex-direction: column;
  }
  .about-header__content {
    width: 100%;
    margin: 0 0 16px 0;
  }
  .about-header__catchphrase {
    width: 100%;
    padding: 20px 10px;
    font-size: 30px;
  }
  .about-header {
    padding: 16px 0 40px 0;
  }
  .choose-city__cities {
    gap: 15px 27px;
  }
  .choose-city__item {
    width: calc((100% - 81px) / 4);
  }
}
@media (max-width: 1470px) {
  .main_slide .side-form__form.order_form {
    width: 460px;
  }
}
@media (max-width: 1370px) {
  .side-form__form.order_form {
    width: 460px;
  }
  .service-header__text-right_wrapper {
    width: 100%;
  }
  .service-header__video-wrapper {
    display: none;
  }
  .bottom_text {
    width: 100%;
  }
  .service-header__info_right::before {
    display: none;
  }
  .service-header__text-wraper {
    width: 100%;
  }
  .service-header__form {
    left: 0;
  }
  .service-header__info_right {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    justify-items: center;
  }
  .service-header__block_title {
    width: 50%;
    justify-content: center;
    padding: 19px 0;
  }
  .service-header__price_h3 {
    font-size: 15px;
  }
  .service-header__text_h1 {
    font-size: 25px;
    padding-left: 15px;
  }
}
@media (max-width: 1200px) {
  .service-header__text_h1 {
    font-size: 20px;
  }
}
@media (max-width: 950px) {
  .service-header-new .container {
    display: grid;
    gap: 40px;
  }
  .service-header__text_h1 {
    font-size: 33px;
  }
  .service-header__video {
    width: 800px;
    height: 450px;
  }
  .service-header__block_title {
    width: 100%;
    justify-content: center;
    padding-right: unset;
  }
  .service-header__info_left {
    justify-content: center;
  }
  .service-header__info_right {
    justify-items: center;
    grid-template-columns: unset;
  }
  .service-header__info_right::before {
    display: none;
  }
  .service-header__video-right {
    display: none;
  }
  .service-header__video-wrapper {
    width: 0;
    height: 0;
  }
  .service-header__text-wraper {
    width: 100%;
  }
  .bottom_text {
    width: unset;
  }
  .service-header__form {
    left: 0;
    margin-left: 16px;
  }
  .service-header__text-right {
    padding-bottom: 120px;
    margin-bottom: -100px;
  }
  .service-header__price_title {
    padding-top: 100px;
  }
}
@media (max-width: 810px) {
  .service-header__video {
    width: 640px;
    height: 360px;
  }
}
@media (max-width: 730px) {
  .service-header__text_h1 {
    font-size: 25px;
  }
  .service-header__price_h3 {
    font-size: 13px;
  }
  .service-header__price_title {
    font-size: 20px;
  }
  .delivery_list {
    grid-template-columns: auto;
  }
}
@media (max-width: 670px) {
  .service-header__video {
    width: 480px;
    height: 270px;
  }
}
@media (max-width: 630px) {
  .service-header__video {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .service-header__form {
    margin-left: 0;
  }
  .side-form__form.service-header__form.order_form .side-form__form_row > label {
    white-space: unset;
  }
  .service-header__form .field_list {
    align-items: flex-end;
  }
}
@media (max-width: 530px) {
  .service-header__text_h1 {
    font-size: 20px;
  }
  .service-header__price_h3 {
    font-size: 8px;
    margin-right: 10px;
  }
  .service-header__price_title {
    padding-top: 85px;
    font-size: 15px;
  }
}
@media (max-width: 500px) {
  .service-header__video {
    aspect-ratio: 16/9;
    width: unset;
    height: unset;
  }
}
@media (max-width: 430px) {
  .service-header__price_title {
    padding-top: 74px;
  }
  .service-header__text_h1 {
    font-size: 17px;
  }
}
@media (max-width: 400px) {
  .service-header__video-frame {
    min-width: unset;
  }
}
@media (max-width: 1400px) {
  .service-header__info {
    padding-right: 50px;
  }
}
@media (max-width: 1024px) {
  .open-form__field,
  .open-form__form .orange-btn {
    width: 48%;
  }
  .service-header .container {
    flex-direction: column;
  }
  .service-header__info {
    padding-right: 0;
  }
  .side-form__form {
    width: 100%;
  }
  .service-header__info {
    display: flex;
    flex-direction: column;
  }
  .service-header__title {
    order: 1;
  }
  .service-header__text {
    order: 2;
    margin-bottom: 15px;
  }
  .plusses-block li {
    width: 100%;
    margin-bottom: 20px;
  }
  .service-header__price {
    order: 3;
  }
  .types-block__item {
    width: 48%;
  }
  .service-text__columns {
    flex-direction: column;
  }
  .service-text__column {
    flex-basis: 100%;
  }
  .service-text__columns li:nth-child(odd),
  .service-text__columns li:nth-child(even) {
    width: 100%;
  }
  .service-text {
    background: rgba(216, 237, 245, 0.8);
  }
  .service-text .container > *:nth-child(n+3) {
    display: none !important;
  }
  .service-text .container > *.service-text__more {
    display: block !important;
  }
  .service-text__columns > *:last-child {
    display: none !important;
  }
  .service-text__column > * {
    display: none !important;
  }
  .service-text__column > *:first-child,
  .service-text__more {
    display: block !important;
  }
  .service-text .container.opened > *:nth-child(n+3) {
    display: block !important;
  }
  .service-text .container.opened .service-text__columns > *:last-child {
    display: block !important;
  }
  .service-text .container.opened .service-text__column > * {
    display: block !important;
  }
  .service-text .opened.container > *.service-text__more {
    display: none !important;
  }
  .reviews-service__list {
    flex-direction: column;
  }
  .reviews-service__item {
    width: 100%;
    padding: 43px 170px 32px 12px;
    margin-bottom: 33px;
  }
  .reviews-service__text {
    font-size: 16px;
  }
  .main_slide .side-form__form.order_form {
    display: none;
  }
  .delivery_block,
  .service-header.delivery_header {
    padding: 70px 0 320px 0;
  }
  .delivery_block::after,
  .service-header.delivery_header::after {
    left: 50%;
    right: auto;
    transform: translate(-50%, 0);
  }
  .delivery_list {
    max-width: 100%;
  }
  .social {
    display: none;
  }
  .choose-city__cities {
    gap: 15px 30px;
  }
  .choose-city__item {
    width: calc((100% - 60px) / 3);
  }
  .choose-city__popup {
    padding: 30px;
  }
  .text-short-block,
  .text-short-block--main {
    margin: 20px 0;
  }
  .text-short,
  .text-short--main {
    padding: 30px 50px 30px 20px;
  }
  .service-header-new {
    padding-top: 14px;
  }
}
@media (max-width: 900px) {
  footer .top {
    flex-wrap: wrap;
  }
}
@media (max-width: 800px) {
  .payment_list .title {
    font-size: 30px;
  }
  .payment_block .container {
    flex-wrap: wrap;
  }
  .payment_block {
    padding: 26px 0;
  }
  .main_reviews_block .reviews_list.inner_list .item {
    height: auto;
    margin: 0 0 30px;
    width: 100%;
  }
  .payment_block .image {
    width: 100%;
    margin: 0 0 26px;
  }
  .payment_block .text {
    margin: 0 0 26px;
  }
  .payment_block .text div:first-child {
    margin-bottom: 16px;
  }
  .payment_list .list .item {
    width: 100%;
    margin: 0 0 26px;
  }
  .review_form .open-form__form .open-form__field:nth-child(2) {
    width: 100%;
    margin-right: 0;
  }
  .review_form .open-form__form .open-form__field:nth-child(2) textarea {
    width: 100%;
  }
  .review_form .open-form__form .open-form__field:first-child {
    width: 100%;
  }
  .review_form .open-form__form .open-form__field:first-child input {
    width: 100%;
  }
  .main_slide .container {
    width: 100%;
    padding: 0 18px;
  }
  .main_slide {
    padding-top: 45px;
    width: 100%;
    overflow: hidden;
  }
  .main_slide img,
  .main_slide .calculate-new {
    display: none;
  }
  .main_slide .text h1,
  .main_slide .text span:first-child {
    font-size: 30px;
  }
  .main_slide .text span:nth-child(2) {
    font-size: 30px;
    line-height: 1.2;
  }
  .main_slide .text div {
    width: 100%;
    font-size: 30px;
  }
  .main_slide:after {
    bottom: -4px;
    width: 100%;
    display: block;
  }
  .main_slide .text span:nth-child(3) {
    margin-bottom: 18px;
  }
  .advantages {
    flex-wrap: wrap;
    margin: 25px 0;
  }
  .advantages div {
    margin-bottom: 16px;
  }
  .text_block.sec,
  .text_block {
    background: transparent;
    display: block;
    padding: 0;
  }
  .text_block span:first-child {
    width: 100%;
    margin: 0 0 15px;
  }
  .text_block span {
    display: block;
    width: 100%;
  }
  .services_block .list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .services_block .list .item {
    width: 100%;
    margin-right: 0;
  }
  .services_block {
    padding: 26px 0;
  }
  .services_block .title,
  .container .title {
    margin-bottom: 26px;
  }
  .advantages_block {
    margin: 26px 0;
  }
  .advantages_block .ad_list {
    justify-content: space-between;
  }
  .advantages_block .ad_list .item {
    width: 45%;
    margin-bottom: 26px;
  }
  .ad_list .item span {
    text-decoration: none;
  }
  .main_steps_block .step_list {
    flex-wrap: wrap;
    margin-bottom: 10px;
    counter-reset: number;
  }
  .main_steps_block .step_list .item {
    width: 48%;
    padding: 0;
    margin-bottom: 20px;
    height: auto;
  }
  .main_steps_block .step_list .item img {
    padding-left: 40px;
  }
  .main_steps_block .step_list .item:before {
    background-image: none !important;
    counter-increment: number;
    content: counter(number) ". ";
    width: unset;
    height: unset;
    font-size: 30px;
    color: #FEB021;
    font-weight: bold;
  }
  .main_reviews_block {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 26px;
  }
  .main_reviews_block .reviews_list {
    flex-wrap: wrap;
  }
  .main_reviews_block .reviews_list .item:after,
  .main_reviews_block .reviews_list .item:before {
    content: "";
    display: none;
  }
  .main_reviews_block .reviews_list .item {
    padding: 0;
    margin: 0 0 16px;
    height: auto;
    overflow: hidden;
  }
  .main_reviews_block .reviews_list .item:last-child {
    display: none;
  }
  .main_reviews_block .reviews_list .item span:first-of-type {
    padding: 25px;
    border: 0;
    margin: 0;
    max-height: none;
  }
  .main_reviews_block .reviews_list .item span:last-of-type {
    display: block;
    width: 100%;
    padding: 12px 25px;
    background: rgba(119, 153, 176, 0.1);
  }
  footer {
    padding: 26px 0 13px;
  }
  footer .top > div {
    margin: 0 0 26px;
  }
  footer .bottom {
    flex-direction: column;
  }
  footer .bottom a {
    margin: 0 0 10px;
  }
  .open-form__container {
    padding: 35px 25px;
  }
  .open-form__field,
  .open-form__form .orange-btn {
    width: 100%;
  }
  .payment-block .container {
    flex-direction: column;
  }
  .payment-block__big-title,
  .prices-block h1 {
    font-size: 30px;
  }
  .payment-block--bottom .payment-block__form {
    margin-top: 0;
  }
  .payment-block__link,
  .payment-block__text {
    margin: 0 0 25px 0;
  }
  .price-list__text_row.columns {
    flex-direction: column;
  }
  .price-list__text_row.columns .price-list__text_column {
    width: 100%;
  }
  .price-list__text {
    margin-bottom: 25px;
  }
  .types-block__item {
    width: 100%;
  }
  .h1-big {
    font-size: 30px;
  }
  .h1-small {
    font-size: 20px;
  }
  .choose-city__cities {
    gap: 15px 40px;
  }
  .choose-city__item {
    width: calc((100% - 40px) / 2);
  }
  .choose-city__popup {
    padding: 40px;
  }
}
@media (max-width: 768px) {
  .new_trans {
    flex-direction: column;
  }
  .before-after {
    max-width: 100%;
    margin: 0 auto 50px;
  }
  .service-header.delivery_header {
    padding: 50px 0 300px 0;
  }
  .delivery_list {
    flex-direction: column;
  }
  .delivery_item:last-child {
    margin-bottom: 0;
  }
  .question__source {
    padding-left: 0;
  }
  .question--legal .question__body::before {
    display: none;
  }
}
@media (max-width: 600px) {
  .contact_block {
    width: 100%;
  }
  .contact_block ul li:nth-child(4),
  .contact_block ul li:nth-child(5),
  .contact_block ul li {
    margin-right: 0;
    margin-bottom: 25px;
  }
  .open_form_success,
  .side-form__form.order_form {
    width: 100%;
  }
  .side-form__form.order_form .side-form__form_row {
    width: 100%;
  }
  .side-form__form.order_form {
    padding: 30px 25px;
  }
  .field_list {
    justify-content: center;
  }
  .sumform {
    margin: 50px 0;
  }
  .about-types__item {
    flex-direction: row;
    width: 100%;
    padding: 15px 25px;
  }
  .about-types__icon {
    margin: 0 15px 0 0;
  }
  .before-after {
    padding: 0 40px;
  }
  .before-after .after-label {
    right: -43px;
  }
  .before-after .before-label {
    left: -43px;
  }
  .delivery_attention {
    padding: 24px;
    margin-bottom: 36px;
    flex-direction: column;
    align-items: center;
  }
  .delivery_attention_image {
    margin: 0 0 24px;
  }
  .choose-city__cities {
    gap: 15px 20px;
  }
  .choose-city__item {
    width: calc((100% - 20px) / 2);
  }
  .choose-city__popup {
    padding: 30px;
  }
  .text-short,
  .text-short--main {
    padding: 30px 20px 30px 20px;
  }
  .text-long div {
    width: 100%;
    display: block;
    margin-top: 20px;
  }
  .service-header__info_left .service-header__text_h1 {
    padding: 0 0 0 20px;
  }
}
@media (max-width: 500px) {
  .choose-city__cities {
    gap: 15px 20px;
  }
  .choose-city__item {
    width: calc((100% - 20px) / 2);
  }
  .choose-city__popup {
    padding: 20px;
    margin: 20px;
    gap: 30px;
  }
  .services_block .list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (max-width: 400px) {
  .choose-city__cities {
    gap: 15px 10px;
  }
  .choose-city__item {
    width: calc((100% - 10px) / 2);
  }
  .choose-city__popup {
    padding: 10px;
    margin: 10px;
    gap: 30px;
  }
}
article,
aside,
figure,
footer,
hgroup,
nav,
section {
  display: block;
}

/*  common styles */
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  /*text-align: center;*/
}

body,
td,
th,
h1,
h2,
h3,
h4,
h5,
input,
select,
textarea {
  color: #333;
  font: normal 14px Arial, Helvetica, sans-serif;
}

table,
td,
th {
  border: none;
  border-collapse: collapse;
}

form,
img,
div,
td,
th {
  padding: 0;
  margin: 0;
  border: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  text-align: left;
}

h1 {
  color: #3d6f91;
  padding: 0;
  font: normal 36px Arial, Helvetica, sans-serif;
  margin: 0 0 -3px -1px;
}

h2 {
  font: normal 18px Arial, Helvetica, sans-serif;
  color: #3d6f91;
  padding: 0;
  margin: 0 0 11px 0;
}

h3 {
  font: normal 18px Arial, Helvetica, sans-serif;
  padding: 0 0 11px 0;
  margin: 0 0;
  color: #4c4c4c;
}

h4 {
  font: normal 18px Arial, Helvetica, sans-serif;
  padding: 0;
  color: #3d6f91;
}

h5 {
  font: normal 24px Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 10px 0 48px 0;
  color: #3d6f91;
  text-align: center;
}

/*
h5 a {
	color: #924328;
	font: normal 24px 'Georgia', Tahoma, Arial, Helvetica, sans-serif;
	font-style: italic;
	text-decoration: none;
}
h5 a:hover {
	color: #447595;
	text-decoration: underline;
}
*/
h6 {
  color: #4d4d4d;
  font: bold 12px Arial, Helvetica, sans-serif;
}

p {
  padding: 0 0 0 0;
  margin: 20px 0 17px 0;
  line-height: 143%;
  font-size: inherit;
  font-family: inherit;
}

/*section{*/
/*margin: 0 0 20px;*/
/*}*/
.clear {
  clear: both;
  display: block;
  font-size: 1px;
  height: 1px;
  line-height: 1px;
  margin: 0 0 -1px;
}

.br {
  clear: both;
  font-size: 1px;
  height: 24px;
  line-height: 1px;
  margin: 0;
}

.brindex {
  clear: both;
  font-size: 1px;
  height: 9px;
  line-height: 1px;
  margin: 0;
}

.display_none {
  display: none;
}

a {
  color: #447595;
  text-decoration: underline;
  outline: none;
}

a:hover,
a.active {
  /*	
  	color: #843525;
  */
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0 0 0 0;
  list-style: none;
}

ul li {
  padding: 0;
  margin: 0;
}

.error {
  text-align: left;
  font-size: 10px;
  color: #850600;
}

.float_right {
  float: right;
}

.float_left {
  float: left;
}

.align_left {
  text-align: left;
}

.align_center {
  text-align: center;
}

.align_right {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

/*  /common styles  */
#wrapper {
  text-align: center;
  width: 100%;
  min-height: 100%;
  height: 100%;
  height: auto !important;
  position: relative;
}

#wrapper #page {
  margin: 0 auto;
  text-align: left;
  padding: 40px 0 0 0px;
  width: 980px;
  right: 4px;
  position: relative;
  /*	z-index: 4;*/
}

/*
.girl {
    background: transparent url('../images1/girl.png') no-repeat 0 0;
	width: 299px;
	height: 388px;
}
*/
.wind1 {
  background: transparent url("/htdocs/images1/wind.png") no-repeat 2% 0;
  width: 100%;
  height: 100%;
  position: relative;
}

.wind2 {
  background: transparent url("/htdocs/images1/wind2.png") no-repeat 98% 0;
  width: 100%;
  height: 100%;
}

.lightcloud {
  background: transparent url("/htdocs/images1/lightcloud.png") repeat-x 0 0;
  height: 39px;
  width: 100%;
  position: absolute;
  float: left;
  lefT: 0;
  bottom: 0;
  z-index: 2;
  z-index: 0;
}

.wellcome {
  width: 322px;
  padding-top: 52px;
}

.wellcome h1 {
  font-size: 28px;
  text-shadow: 0px 1px 1px #000;
  margin: 6px 0 -1px 0;
}

.wellcome ul {
  margin: -13px 0 0 4px;
}

.wellcome li {
  background: transparent url("/htdocs/images1/level1.png") no-repeat 0 12px;
  padding: 8px 0 6px 16px;
}

.wellcome li a {
  text-decoration: none;
}

.wellcome li a:hover {
  text-decoration: underline;
}

#logo {
  display: block;
  background: transparent url("/htdocs/images1/logo.png") no-repeat 0 0;
  width: 198px;
  height: 81px;
  float: left;
  margin-top: 17px;
}

#topnav {
  float: left;
  font-size: 13px;
  width: 60%;
  height: 104px;
  margin-left: 5px;
  margin-top: 16px;
}

#topnav ul {
  padding: 0;
  margin: 0;
  float: left;
}

#topnav ul li {
  margin-right: 0px;
  padding-left: 14px;
  margin-bottom: -1px;
}

#topnav ul li a {
  text-decoration: none;
  display: block;
  background: transparent url("/htdocs/images1/level1.png") no-repeat 0 12px;
  padding: 7px 22px 7px 8px;
}

#topnav ul li:hover {
  background: transparent url("/htdocs/images1/roundleft.png") no-repeat 0 0;
}

#topnav ul li:hover a {
  color: #c2ecff;
  background: transparent url("/htdocs/images1/roundright.png") no-repeat 100% 0;
}

#lang {
  min-height: 10px;
  overflow: hidden;
  position: relative;
  /*
  float: right;
  */
  font-size: 12px;
  margin-right: 36px;
  text-align: right;
  width: 153px;
  margin-top: -107px;
  /*
  display: inline-block;
  //display: inline;
  zoom: 1;
  */
}

#lang2 {
  min-height: 10px;
  overflow: hidden;
  position: relative;
  /*
  float: right;
  */
  font-size: 12px;
  margin-right: 36px;
  text-align: right;
  width: 153px;
  margin-top: -107px;
  /*
  display: inline-block;
  //display: inline;
  zoom: 1;
  */
}

#lang a {
  position: relative;
  display: inline-block;
  zoom: 1;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
}

#lang2 a {
  position: relative;
  display: inline-block;
  zoom: 1;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
}

.ru {
  padding: 4px 0 0 8px;
  color: #a8e5ff;
  background: transparent url("/htdocs/images1/langdark.png") no-repeat 0 0;
  width: 26px;
  height: 29px;
}

.en {
  width: 25px;
  height: 27px;
  top: 10px;
  color: #4e737f;
  background: transparent url("/htdocs/images1/langlight.png") no-repeat 0 0;
  padding: 3px 0 8px 6px;
  margin-lefT: 5px;
}

#phone {
  font: bold 20px Arial;
  margin-right: 28px;
  margin-top: 7px;
  margin-bottom: 9px;
}

#auth {
  padding: 1px 0 8px 19px;
  display: block;
  font-size: 11px;
  color: #3d6f91;
  background: transparent url("/htdocs/images1/auth.png") no-repeat 0 2px;
  clear: right;
  font-size: 12px;
  margin-left: 6px;
  /*
  float: right;
  width: 131px;
  */
}

#breadcrumbs {
  padding: 0;
  margin: 6px 0 0 -1px;
  font-size: 11px;
  float: left;
  clear: left;
  width: 100%;
}

#breadcrumbs li,
#breadcrumbs a,
#breadcrumbs span {
  display: inline-block;
  zoom: 1;
  color: #8eb1c5;
}

#breadcrumbs a {
  padding-right: 5px;
  margin-right: 0px;
  background: transparent url("/htdocs/images1/level3.png") no-repeat 100% 3px;
}

/*
#online {
	display: block;
    background: transparent url('../images1/onlinezakaz.png') no-repeat 0 0;
}
*/
/*
.online {
	background: red;
	width: 0px;
	height: 284px;
	position: absolute;
	right: 0;
	top: 7%;
	margin-top: 5px;
}
*/
.online {
  position: fixed;
  top: 24%;
  right: 0;
  margin-top: 5px;
  z-index: 10;
}

.online form h6 {
  display: block;
}

.online ul li {
  margin-top: 5px;
  margin-bottom: 12px;
  position: relative;
}

#page .online .textarea {
  margin-top: 5px;
  min-height: 54px;
  margin-bottom: 12px;
}

.slide-out-div {
  width: 264px;
  z-index: 4;
  background: #f2f4f5;
  border: 1px solid #cacaca;
  border-radius: 0 0 0 18px;
  -moz-border-radius: 0 0 0 18px;
  -webkit-border-radius: 0 0 0 18px;
  -khtml-border-radius: 0 0 0 18px;
  behavior: url(/htdocs/PIE.htc);
  padding: 28px 15px 1px 19px;
}

.handle {
  margin-top: -2px;
}

#content {
  min-height: 50px;
  overflow: hidden;
  padding-bottom: 280px;
}

#content.new-content {
  padding-bottom: 0;
}

.offer {
  margin-top: 12px;
  min-height: 50px;
  overflow: hidden;
  color: #4c4c4c;
}

.offer li {
  width: 290px;
  margin-right: 29px;
  float: left;
  /*
  	width: 290px;
  	margin-right: 29px;
  	padding-left: 11px;
  	bordeR: 1px solid blue;
  	height: 17px;
  }
  form .input {
  	height: 17px;
  	*/
}

.offer li p {
  margin-left: 11px;
  margin-top: 12px;
}

.offer li img {
  float: left;
  margin-top: 18px;
}

.offer li.offerfirst img {
  margin-top: 0;
}

.offer li h2 {
  margin: 39px 0 0 0;
  line-height: 112%;
  position: relative;
  left: 10px;
  width: 70%;
}

.offer li.offerfirst h2 {
  width: 80%;
}

.offer li.offerlast {
  margin-right: 0;
  margin-left: 1px;
}

.offer li.offerlast h2 {
  width: 96%;
}

/*
.offer li img, .offer li h2 {
	float: left;
}
.offer li h2 {
	display: inline-block;
	//display: inline;
	zoom: 1;
}
.offer li p{
	border: 1px solid red;
}
*/
#column_left,
#column_right {
  float: left;
}

#column_left {
  width: 240px;
  margin-bottom: 22px;
}

#column_left ul {
  margin-top: 17px;
  margin-left: 1px;
}

#column_left ul li {
  background: transparent url("/htdocs/images1/level1.png") no-repeat 0 7px;
  padding: 2px 0 9px 16px;
  line-height: 138%;
}

#column_left ul li a {
  text-decoration: none;
}

#column_left ul li a:hover {
  text-decoration: underline;
}

#column_right {
  width: 700px;
  margin-bottom: 15px;
}

#column_right ul {
  margin-top: 21px;
}

#column_right ul li {
  /*background: transparent url('../images1/level2.png') no-repeat 0 7px;*/
  padding: 0 0 0 15px;
  line-height: 143%;
}

#column_right ul.contacts {
  width: 100%;
  margin: 57px 0 0 0;
  padding: 0;
  min-height: 50px;
  overflow: hidden;
}

#column_right ul.contacts li {
  background: none;
  padding: 0 0 14px 7px;
  margin: 0;
  float: left;
  width: 40%;
}

#column_right ul.contacts li.even {
  width: 57%;
}

#column_right ul.contacts li img {
  margin-top: 4px;
  margin-right: 16px;
  margin-bottom: 42px;
  float: left;
}

#column_right ul.contacts li p {
  margin: 0;
  padding: 0;
}

#column_right ul.contacts li h6,
.online h6 {
  display: inline-block;
  zoom: 1;
  padding: 0 0 0 9px;
}

#column_right ul.contacts li li {
  background: none;
  padding: 0;
  margin: 0;
  float: left;
  width: 40%;
}

#column_right ul.contacts li ul {
  margin-top: 3px;
}

#column_right ul.contacts li.last {
  clear: left;
  width: 100%;
}

#column_right ul.contacts li.last img {
  margin-bottom: 222px;
}

#column_right ul.contacts li.last li {
  width: 45%;
  margin-right: 31px;
}

#column_right ul.contacts li.last li .input {
  width: 100%;
}

/*
#column_right ul.contacts li.last li {
	width: 45%;
	margin-right: 39px;
}
*/
#column_right ul.contacts li form {
  margin-top: 15px;
}

#column_right form {
  margin-bottom: 17px;
}

form .input,
form textarea {
  bordeR: 2px solid #c0c9cf;
  border-radius: 13px;
  -moz-border-radius: 13px;
  -webkit-border-radius: 13px;
  -khtml-border-radius: 13px;
  padding: 5px 7px 5px 8px;
  behavior: url(/htdocs/PIE.htc);
  color: #666;
  font-style: italic;
  font-size: 11px;
  background: #f2f4f5;
  width: 90%;
}

#column_right form ul {
  min-height: 37px;
  overflow: hidden;
}

#column_right ul.contacts li.last textarea {
  margin-top: 3px;
  min-height: 94px;
  width: 85%;
  padding-right: 5px;
}

form input.submit {
  border-radius: 18px;
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  -khtml-border-radius: 18px;
  text-align: center;
  text-shadow: 1px 1px 1px #fff;
  behavior: url(/htdocs/PIE.htc);
  bordeR: none;
  color: #333;
  font: bold 14px Arial;
  padding: 11px 31px 12px 28px;
  background: transparent url("/htdocs/images1/submit.png") repeat-x 0 0;
  margin-right: 12px;
  margin-top: 17px;
  width: auto;
  cursor: pointer;
}

#footer {
  background: #d8edf5 url("/htdocs/images1/foot.png") repeat-x 0 0;
  width: 100%;
  position: absolute;
  bottom: 0px;
  float: left;
  lefT: 0;
  bottom: 0;
  z-index: 2;
}

#foot {
  width: 980px;
  margin: 0 auto;
  text-align: left;
  /*	height: 182px;*/
  color: #4d4d4d;
}

#foot .block {
  float: left;
  width: 30%;
  width: 279px;
  padding: 22px 0 0 41px;
  height: 136px;
}

#foot .block:first-child {
  width: 279px;
  padding-left: 0;
}

#foot .block ul {
  margin-top: 14px;
  margin-left: -2px;
}

#foot .block ul li {
  display: inline-block;
  zoom: 1;
  margin-right: 7px;
}

#foot .coal {
  background: transparent url("/htdocs/images1/coal.png") no-repeat 0 0;
}

#foot .align_center {
  font-size: 11px;
  margin: 5px 0 20px 0;
}

#foot p {
  padding: 0;
  margin: 0;
}

#foot a {
  color: #4c4c4c;
}

/* change select */
.calculate {
  background: #7799b0;
  width: 232px;
  position: absolute;
  float: right;
  right: 17%;
  /*	
  	right: 20%;
  	margin-right: 3px;
  	height: 300px;
  */
  top: 31%;
  margin-top: 64px;
  z-index: 1;
  z-index: 0;
  border-radius: 18px;
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  -khtml-border-radius: 18px;
  behavior: url(/htdocs/PIE.htc);
  color: #fff;
  text-align: left;
  padding: 13px 16px 16px 16px;
}

.calculate h4 {
  color: #fff;
  text-align: center;
  margin: 2px 0 16px 0;
  text-shadow: 0px 1px 0px #020202;
}

.calculate p {
  padding: 0 0 6px 7px;
  font-size: 12px;
  margin: 0 0 0 0;
}

.calculate .input,
.calculate textarea {
  background: #546b7b;
  bordeR: none;
  color: #fff;
  font-style: normal;
  overflow: auto;
}

.calculate textarea {
  width: 218px;
}

.calculate .float_left {
  position: relative;
  z-indeX: 10;
}

.hidden {
  display: none;
}

#curr_list,
#curr_list1,
#curr_list2 {
  position: absolute;
  left: 0px;
  background: #495e6c;
  border-radius: 0 0 18px 18px;
  -moz-border-radius: 0 0 18px 18px;
  -webkit-border-radius: 0 0 18px 18px;
  -khtml-border-radius: 0 0 18px 18px;
  behavior: url(/htdocs/PIE.htc);
  top: 13px;
  width: 232px;
  /*	padding-bottom: 14px;*/
  z-index: 2;
}

#curr_list,
#curr_list1 {
  width: 110px;
}

#curr_list a.last,
#curr_list1 a.last,
#curr_list2 a.last,
#curr_list a.last span,
#curr_list1 a.last span,
#curr_list2 a.last span {
  bordeR: none;
}

#curr_list a.last span,
#curr_list1 a.last span,
#curr_list2 a.last span {
  padding-bottom: 10px;
}

#curr_list a.last:hover,
#curr_list1 a.last:hover,
#curr_list2 a.last:hover {
  border-radius: 0 0 18px 18px;
  -moz-border-radius: 0 0 18px 18px;
  -webkit-border-radius: 0 0 18px 18px;
  -khtml-border-radius: 0 0 18px 18px;
  behavior: url(/htdocs/PIE.htc);
}

#curr_list a.first span,
#curr_list1 a.first span,
#curr_list2 a.first span {
  padding-top: 19px;
}

.float_left .dropdown {
  width: 114px;
}

.dropdown {
  position: relative;
  width: 212px;
  lefT: 0;
  z-index: 6;
  font-size: 11px;
}

.dropdown a {
  display: block;
  color: #fff;
  text-decoration: none;
  position: relative;
  border-bottom: 1px solid #6a7b87;
}

.dropdown a span {
  display: block;
  border-bottom: 1px solid #3a4b56;
  padding: 4px 10px 5px 10px;
}

.dropdown a:hover {
  background: #5e7381;
}

#curr_selected,
#curr_selected1,
#curr_selected2 {
  width: 100%;
  color: #fff;
  padding: 5px 10px 7px 10px;
  cursor: pointer;
  height: 12px;
  background: #546b7b;
  background: #546b7b url("/htdocs/images1/arrow.png") no-repeat 97% 3px;
  border-radius: 18px;
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  -khtml-border-radius: 18px;
  behavior: url(/htdocs/PIE.htc);
  position: relative;
  margin: 0 0 4px 0;
  z-index: 3;
}

#curr_selected2 {
  background: #546b7b url("/htdocs/images1/arrow.png") no-repeat 99% 3px;
  width: 212px;
}

#curr_selected,
#curr_selected1 {
  width: 90px;
}

#curr_selected1б #curr_list1 {
  margin-lefT: 8px;
}

#curr_selected a,
#curr_selected1 a,
#curr_selected2 a {
  background: none;
  padding: 0;
}

#dd_currency,
#dd_currency1,
#dd_currency2 {
  /*
  width: 171px;
  background-position: 138px 8px;
  height: 15px;
  margin-top: 5px;
  */
}

/*****************************/
#amount {
  background: none;
  bordeR: none;
  color: #fff;
  font-style: italic;
  text-align: center;
  width: 100%;
  margin: 4px 0 0 0;
  font-size: 11px;
}

.ui-slider {
  position: relative;
  text-align: left;
  margin: 9px 0 0 0;
  text-indent: -900px;
  font-size: 0px;
  line-height: 0%;
}

.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 18px;
  height: 19px;
}

.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: 0.7em;
  display: block;
  border: 0;
  background-position: 0 0;
}

.ui-slider-horizontal {
  height: 8px;
  border-radius: 18px;
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  -khtml-border-radius: 18px;
  behavior: url(/htdocs/PIE.htc);
}

.ui-slider-horizontal .ui-slider-handle {
  top: -5px;
  margin-left: -0.6em;
}

.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
  border-radius: 18px;
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  -khtml-border-radius: 18px;
  behavior: url(/htdocs/PIE.htc);
  border-left: 1px solid #546b7b;
}

.ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}

.ui-slider-vertical {
  width: 0.8em;
  height: 100px;
}

.ui-slider-vertical .ui-slider-handle {
  left: -0.3em;
  margin-left: 0;
  margin-bottom: -0.6em;
}

.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%;
}

.ui-slider-vertical .ui-slider-range-min {
  bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max {
  top: 0;
}

.ui-widget .ui-widget {
  font-size: 1em;
}

.ui-widget-content {
  background: #546b7b;
  color: #222222;
}

.ui-widget-content a {
  color: #222222;
}

.ui-widget-header {
  background: transparent url("/htdocs/images1/polzunokbg.png") repeat 0 0;
  color: #222222;
}

.ui-widget-header a {
  color: #222222;
}

/* Interaction states
----------------------------------*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background: transparent url("/htdocs/images1/arrowdouble.png") no-repeat 0 0;
  font-weight: normal;
  color: #555555;
}

.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
  color: #555555;
  text-decoration: none;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  background: transparent url("/htdocs/images1/arrowdouble.png") no-repeat 0 0;
  font-weight: normal;
  color: #212121;
}

.ui-state-hover a,
.ui-state-hover a:hover {
  color: #212121;
  text-decoration: none;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  background: transparent url("/htdocs/images1/arrowdouble.png") no-repeat 0 0;
  font-weight: normal;
  color: #212121;
}

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  color: #212121;
  text-decoration: none;
}

.ui-widget :active {
  outline: none;
}

div.fileinputs {
  position: relative;
  left: -8px;
}

div.fakebtn {
  position: absolute;
  top: 0px;
  left: 10px;
  z-index: 1;
  cursor: pointer;
}

input.file {
  position: absolute;
  top: 0px;
  left: 20px;
  z-index: 3;
}

input.fakefile {
  position: relative;
  -moz-opacity: 0;
  opacity: 0;
  z-index: 3;
}

div.faketext {
  position: absolute;
  top: 0px;
  left: 20px;
  z-index: 5;
}

input.browsetext {
  background: transparent;
  /*
  height: 26px;
  */
  width: 160px;
  border: none;
  font-size: 11px;
  font-style: italic;
  color: #666;
  padding-top: 7px;
}

/*FIX*/
/*** Стили относящиеся к модальному окну и содержанию ***/
.popup h1 {
  /* заголовок 1 */
  color: #008000;
  text-align: left;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  font: 24px "Trebuchet MS", Helvetica, sans-serif;
  font-weight: bold;
}

.popup h2 {
  /* заголовок 2 */
  color: #008000;
  text-align: left;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  font: 22px "Trebuchet MS", Helvetica, sans-serif;
}

/* Базовые стили слоя затемнения и модального окна  */
.overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  visibility: hidden;
  /* фон затемнения */
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  position: fixed;
  /* фиксированное поцизионирование */
  cursor: default;
  /* тип курсара */
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}

.is-image {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  width: 100%;
  height: auto;
  /* скругление углов встроенных картинок */
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

/* встроенные элементы м-медиа, фреймы */
embed,
iframe {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  min-width: 320px;
  /*max-width: 600px;*/
  width: 100%;
}

/* стили модального окна */
.popup {
  top: 0;
  right: 0;
  left: 0;
  font-size: 14px;
  z-index: 10;
  display: block;
  visibility: hidden;
  margin: 0 auto;
  width: 90%;
  min-width: 250px;
  max-width: 320px;
  /* фиксированное позиционирование, окно стабильно при прокрутке */
  position: fixed;
  padding: 15px;
  border: 1px solid #383838;
  /* скругление углов */
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  background-color: #FFFFFF;
  /* внешняя тень */
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
  -ms-box-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
  -o-box-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.8);
  /* полная прозрачность окна, появление при клике */
  opacity: 0;
  /* эффект перехода (появление) */
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  -ms-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.overlay:target + .popup {
  top: 20%;
  visibility: visible;
  opacity: 1;
}

/* формируем кнопку закрытия */
.close {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
  background-color: rgba(61, 61, 61, 0.8);
  -webkit-box-shadow: 0px 0px 10px #000;
  -moz-box-shadow: 0px 0px 10px #000;
  box-shadow: 0px 0px 10px #000;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  line-height: 20px;
  -webkit-transition: all ease 0.8s;
  -moz-transition: all ease 0.8s;
  -ms-transition: all ease 0.8s;
  -o-transition: all ease 0.8s;
  transition: all ease 0.8s;
}

.close:before {
  color: rgba(255, 255, 255, 0.9);
  content: "X";
  text-shadow: 0 -1px rgba(0, 0, 0, 0.9);
  font-size: 12px;
}

.close:hover {
  background-color: rgba(252, 20, 0, 0.8);
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
  border: 5px solid #ff0000;
}

.popup p,
.popup div {
  margin-bottom: 10px;
}

/*СТИЛИ МОДАЛЬНЫХ ОКОН, кнопок задать вопрос, заказать звонок*/
/*Заказать звонок*/
#popup-form {
  display: none;
  height: 100%;
  background-image: url("qsc.png");
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  /*эффект затемнённого фона*/
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

#popup-form-inner {
  background: #ffffff;
  padding: 10px;
  position: fixed;
  top: 50%;
  left: 55%;
  margin-top: -275px;
  margin-left: -225px;
  border-radius: 5px;
}

a#closeX {
  padding: 0 4px;
  float: right;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  background: #4166A7;
  font-family: Calibri;
  font-weight: bold;
}

/*Задать вопрос*/
#popup-form2 {
  display: none;
  height: 100%;
  background-image: url("qsc.png");
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  /*эффект затемнённого фона*/
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

#popup-form-inner2 {
  background: #ffffff;
  padding: 10px;
  position: fixed;
  top: 50%;
  left: 55%;
  margin-top: -275px;
  margin-left: -225px;
  border-radius: 5px;
}

a#closeX2 {
  padding: 0 4px;
  float: right;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  background: #4166A7;
  font-family: Calibri;
  font-weight: bold;
}

/*Задать вопрос*/
#popup-form3 {
  display: none;
  height: 100%;
  background-image: url("qsc.png");
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  /*эффект затемнённого фона*/
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

#popup-form-inner3 {
  background: #ffffff;
  padding: 10px;
  position: fixed;
  top: 50%;
  left: 55%;
  margin-top: -275px;
  margin-left: -225px;
  border-radius: 5px;
}

a#closeX3 {
  padding: 0 4px;
  float: right;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  background: #4166A7;
  font-family: Calibri;
  font-weight: bold;
}

#closeX,
#closeX2 {
  -webkit-transition: all ease 0.8s;
  -moz-transition: all ease 0.8s;
  -ms-transition: all ease 0.8s;
  -o-transition: all ease 0.8s;
  transition: all ease 0.8s;
  border: 5px solid #fff;
}

#closeX:hover,
#closeX2:hover {
  background-color: rgba(252, 20, 0, 0.8);
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
  border: 5px solid #ff0000;
  border-radius: 5px;
}

/*Это всё для формы отправки файлов*/
#feedback-form {
  /* вся форма */
  width: 280px;
  padding: 0px;
  border-radius: 3px;
  background: #f1f1f1;
}

#feedback-form label {
  /* наименование полей */
  float: left;
  display: block;
  clear: right;
}

#feedback-form .w100 {
  /* поля */
  float: left !important;
  max-width: 400px;
  width: 97%;
  margin-bottom: 1em;
  padding: 1.5%;
}

#feedback-form .border {
  /* граница полей */
  border-radius: 1px;
  border-width: 1px;
  border-style: solid;
  border-color: #C0C0C0 #D9D9D9 #D9D9D9;
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.5), 0 1px 1px rgba(0, 0, 0, 0.1) inset;
}

#feedback-form .border:focus {
  outline: none;
  border-color: #abd9f1 #bfe3f7 #bfe3f7;
}

#feedback-form .border:hover {
  border-color: #7eb4ea #97cdea #97cdea;
}

#feedback-form .border:focus::-moz-placeholder {
  /* убрать при фокусе первоначальный текст поля */
  color: transparent;
}

#feedback-form .border:focus::-webkit-input-placeholder {
  color: transparent;
}

#feedback-form .border:not(:focus):not(:hover):valid {
  /* правильно заполненные поля */
  opacity: 0.8;
}

#submitFF {
  /* кнопка "Отправить" */
  padding: 2%;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2) inset;
  background: #669acc;
  color: #fff;
}

#feedback-form br {
  height: 0;
  clear: both;
}

#submitFF:hover {
  background: #5c90c2;
}

#submitFF:focus {
  box-shadow: 0 1px 1px #fff, inset 0 1px 2px rgba(0, 0, 0, 0.8), inset 0 -1px 0 rgba(0, 0, 0, 0.05);
}

.uslugi_perevod {
  width: 100%;
  margin: 50px 0;
}

.uslugi_perevod tr {
  height: 40px;
  text-transform: uppercase;
}

h1 a {
  text-decoration: none;
  color: #3d6f91 !important;
}

@media (max-width: 1000px) {
  #topnav,
  .head,
  .head div:nth-child(5),
  .girl,
  #content,
  tr,
  #foot ul {
    display: flex !important;
    flex-direction: column;
  }
  .head .float_right {
    margin-top: 280px;
  }
  .calculate,
  #wrapper #page {
    position: static !important;
    order: 1;
    margin-top: 0;
  }
  .calculate {
    margin-bottom: 400px;
  }
  .head div:nth-child(5),
  #foot .align_center {
    display: none !important;
  }
  .head,
  .wellcome,
  #wrapper #page,
  .big1,
  #foot ul li,
  #foot,
  p,
  #ot,
  .otzyvy1,
  #column_right,
  .tablefooter {
    width: 100% !important;
  }
  #ot {
    height: 100% !important;
  }
  #footer {
    position: static !important;
  }
  tr {
    align-items: center;
  }
  /*body {*/
  /*    padding: 0 5px;*/
  /*}*/
  .block ul {
    display: flex;
    flex-direction: row !important;
  }
  #foot .coal,
  #footer {
    background: none !important;
  }
  #foot .block,
  #content {
    padding: 0 !important;
  }
  #column_right {
    height: auto;
  }
  .slide-out-div {
    padding-top: 5px !important;
  }
  .submit,
  .online p {
    margin-top: 5px !important;
  }
  .input1 {
    margin-bottom: 2px !important;
  }
  body .cbk-phone,
  body .cbk-phone .cbk-phone-bgr {
    width: 40px !important;
    height: 40px !important;
  }
  .cbk-phone .cbk-phone-phone .ws-icon-phone {
    font-size: 20px !important;
  }
  html body .cbk-phone .cbk-phone-phone {
    width: 10px !important;
    margin: 0px !important;
    margin-left: 10px !important;
    margin-top: 10px !important;
  }
  .online .slide-out-div,
  .online {
    position: static !important;
  }
}
.lng_form .lng_block {
  margin-bottom: 7px;
  text-align: center;
}

.lng_form .lng_block a {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #447595;
  text-decoration: none;
  width: 29px;
  height: 24px;
  display: inline-block;
}

.lng_form .lng_block .en {
  background: url(/htdocs/img/en.png) no-repeat;
  padding: 7px 0 0 0;
  width: 28px;
}

.lng_form .lng_block .ru {
  color: #fff;
  background: url(/htdocs/img/ru.png) no-repeat;
  padding: 7px 0 0 0;
  margin-right: 0;
}

.lng_form .call_btn {
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  padding: 14px 20px;
  border: 1px solid #447595;
  border-radius: 35px;
  color: #447595;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.lng_form .call_btn svg {
  display: none;
}

.lng-vk {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}

@media (max-width: 1580px) {
  .lng_form {
    margin-right: 20px;
  }
  .lng_form .call_btn {
    padding: 14px 10px;
  }
}
@media (max-width: 1200px) {
  .lng_form {
    margin-right: 10px;
  }
  .lng_form .call_btn {
    font-size: 12px;
    padding: 7px 10px;
  }
}
@media (max-width: 1024px) {
  .lng_form {
    display: none;
  }
  .lng_form .call_btn {
    border: 0;
    width: 24px;
    height: 24px;
    color: transparent;
    padding: 0;
  }
  .lng_form .call_btn svg {
    display: block;
  }
}
.main_lng_block {
  background: #D8EDF5;
  padding: 50px 0;
}

.main_lng_block.lng_page {
  margin-bottom: 30px;
}

.main_lng_block .list {
  display: flex;
  justify-content: space-between;
}

.main_lng_block.lng_page .list {
  flex-wrap: wrap;
  justify-content: flex-start;
}

.main_lng_block .list .item {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 17px;
  line-height: 20px;
  color: #447595;
  text-decoration: none;
}

.main_lng_block.lng_page .list .item {
  padding: 10px;
  width: 20%;
  text-align: center;
}

.main_lng_block .list .item img {
  margin-bottom: 16px;
}

.main_lng_block .list .item span {
  color: #447595;
}

.main_lng_block .list .item:hover {
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .main_lng_block .list {
    flex-wrap: wrap;
  }
  .main_lng_block .list .item {
    width: 45%;
    margin: 0 0 26px;
  }
  .main_lng_block.lng_page .list .item {
    width: 50%;
  }
}
@media (max-width: 800px) {
  .main_lng_block {
    padding: 26px 0;
  }
}
.popup-form__btn {
  background: #FEB021;
  border-radius: 35px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 14px 43px;
  display: inline-block;
  cursor: pointer;
  margin-top: 24px;
  border: 0;
}

/*Заказать звонок*/
#popup-form {
  display: none;
  height: 100%;
  background-image: url("qsc.png");
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  /*эффект затемнённого фона*/
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

#popup-form-inner {
  background: #ffffff;
  padding: 10px;
  position: fixed;
  top: 50%;
  left: 55%;
  margin-top: -275px;
  margin-left: -225px;
  border-radius: 5px;
}

a#closeX {
  padding: 0 4px;
  float: right;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  background: #4166A7;
  font-family: Calibri;
  font-weight: bold;
}

/*Задать вопрос*/
#popup-form2 {
  display: none;
  height: 100%;
  background-image: url("qsc.png");
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  /*эффект затемнённого фона*/
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

#popup-form-inner2 {
  background: #ffffff;
  padding: 10px;
  position: fixed;
  top: 50%;
  left: 55%;
  margin-top: -275px;
  margin-left: -225px;
  border-radius: 5px;
}

a#closeX2 {
  padding: 0 4px;
  float: right;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  background: #4166A7;
  font-family: Calibri;
  font-weight: bold;
}

/*Задать вопрос*/
#popup-form3 {
  display: none;
  height: 100%;
  background-image: url("qsc.png");
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  /*эффект затемнённого фона*/
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

#popup-form-inner3 {
  background: #ffffff;
  padding: 10px;
  position: fixed;
  top: 50%;
  left: 55%;
  margin-top: -275px;
  margin-left: -225px;
  border-radius: 5px;
}

a#closeX3 {
  padding: 0 4px;
  float: right;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  background: #4166A7;
  font-family: Calibri;
  font-weight: bold;
}

#closeX,
#closeX2 {
  -webkit-transition: all ease 0.8s;
  -moz-transition: all ease 0.8s;
  -ms-transition: all ease 0.8s;
  -o-transition: all ease 0.8s;
  transition: all ease 0.8s;
  border: 5px solid #fff;
}

#closeX:hover,
#closeX2:hover {
  background-color: rgba(252, 20, 0, 0.8);
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
  border: 5px solid #ff0000;
  border-radius: 5px;
}

/*Форма*/
.popup-form__container {
  display: none;
  background-image: url("qsc.png");
  position: fixed;
  z-index: 1002;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.popup-form {
  width: 310px;
  padding: 25px;
  background: #F2F4F5;
  border-radius: 10px;
  position: relative;
}

.popup-form .success {
  text-align: center;
  font-size: 16px;
  color: #4b4;
  font-weight: bold;
}

.popup-form__container.opened {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-form__title {
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #447595;
  margin-bottom: 32px;
  padding-right: 40px;
}

.popup-form__close {
  width: 15px;
  height: 15px;
  background-image: url("/htdocs/images/topform/popup-close.svg");
  background-size: cover;
  position: absolute;
  top: 25px;
  right: 25px;
  opacity: 0.5;
  cursor: pointer;
  transition: 1s;
}

.popup-form__close:hover {
  transform: rotate(180deg);
}

.popup-form__row {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.popup-form__row_error {
  display: none;
  font-size: 12px;
  padding-top: 5px;
  color: #b44;
}

.popup-form__row.errors .popup-form__row_error {
  display: block;
}

.popup-form__row label,
.popup-form__section label {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  color: #447595;
  margin-bottom: 10px;
  text-align: left;
}

.popup-form__row input[type=text],
.popup-form__row input[type=tel],
.popup-form__row input[type=email],
.popup-form__row textarea {
  padding: 10px;
  background: #FFFFFF;
  border-radius: 5px;
  border: 0;
  width: 100%;
}

.errors.popup-form__row input[type=text],
.errors.popup-form__row input[type=tel],
.errors.popup-form__row input[type=email],
.errors.popup-form__row textarea {
  background-color: rgba(230, 110, 110, 0.2);
}

.errors.popup-form__row .side-form__form_input_error {
  display: block;
}

.popup-form__row textarea {
  resize: vertical;
}

.popup-form__btn {
  margin: 25px auto 0 auto;
  display: block;
}

.popup-form__section {
  margin-bottom: 25px;
}

.popup-form__section_title {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.2;
  color: #333333;
  margin-bottom: 15px;
}

.popup-form__personal {
  font-family: Arial;
  font-size: 10px;
  line-height: 1.2;
  margin-top: 25px;
}

.popup-form__personal a {
  color: #447595;
  font-size: 10px;
  line-height: 1.2;
}

.popup-form__container.side-form {
  display: flex;
  left: unset;
  background: unset;
  bottom: unset;
  top: 10%;
  align-items: flex-start;
  right: -317px;
  transition: 1s;
  z-index: 1001;
}

.popup-form__container.side-form .popup-form {
  border-radius: 10px 0 0 10px;
}

.popup-form__container.side-form.opened {
  right: 0;
}

.popup-form .input-file-list-remove {
  color: black;
}

.popup-form .input-file-list-item:last-child {
  margin-bottom: 0;
  flex-basis: 100%;
}

.side-form__form.order_form .side-form__form_row label.choose_file,
.popup-form__row .choose_file {
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #447595;
  padding: 7px 0;
  text-align: center;
  margin-bottom: 0;
}

.popup-form .popup-form__row .choose_file {
  margin-top: 5px;
}

.popup-form__row .choose_file + input {
  display: none;
}

.popup-form__row .choose_file {
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #447595;
  padding: 7px 0;
  text-align: center;
  margin-bottom: 0;
}

@media (max-width: 800px) {
  .popup-form__container.side-form {
    top: 80px;
  }
  .popup-form__container.side-form .popup-form__section_title {
    display: none;
  }
  .popup-form__container.side-form .popup-form__section {
    margin-bottom: 0;
  }
  .popup-form__container.side-form .popup-form__row {
    margin-bottom: 8px;
  }
}
.popup-form__container.side-form::before {
  content: "";
  position: absolute;
  bottom: -7px;
  right: 0;
  width: calc(100% + 7px);
  height: calc(100% - 4px);
  background-color: var(--blue);
  border-radius: 0 0 0 10px;
}

.side-form__btn {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 10px;
  left: -40px;
  width: 40px;
  height: 180px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  border: 0;
  background: var(--orange);
  border-radius: 30px 0 0 30px;
  text-transform: uppercase;
  color: var(--white);
  cursor: pointer;
  overflow: hidden;
}

.side-form__btn-text {
  padding: 10px 28px 10px 10px;
  background: var(--orange);
  height: 40px;
  width: 140px;
  transform: rotate(-90deg) translateX(-42px) translateY(-48px);
}

.side-form__btn-svg {
  display: flex;
  padding-left: 13px;
  padding-top: 7px;
  margin-top: auto;
  width: 40px;
  height: 40px;
  background-color: var(--blue);
}

.side-form__toggler.btn {
  margin-bottom: 15px;
  width: 100%;
}

.side-form__wrap {
  display: flex;
}

.popup-form__form {
  flex-basis: 100%;
  flex-shrink: 0;
  opacity: 0;
}

.sub-moved .side-form__toggler svg {
  transform: scale(-1, 1);
}

.sub-moved .side-form__contacts {
  opacity: 0;
}

.sub-moved .side-form__wrap {
  transform: translateX(-260px);
}

.sub-moved .popup-form__form {
  opacity: 1;
}

@media (max-width: 800px) {
  .side-form__btn {
    top: 190px;
  }
}
/* CSS Document */
.sidebarForm {
  /*background: #f6f6f6;*/
  background: #fff;
  box-shadow: 0 0 5px #ccc;
  -webkit-box-shadow: 0 0 5px #ccc;
  -moz-box-shadow: 0 0 5px #ccc;
  /*width: 420px;*/
}

#cor5 {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.sidebarForm h4 {
  font: 14px Verdana;
  color: #333333;
  text-align: left;
  padding: 10px 10px 0 10px;
}

.sidebarForm label {
  font: 12px Verdana;
  color: #333;
  padding: 10px;
}

.sidebarForm .input {
  width: 250px;
  height: 20px;
  overflow: hidden;
  background: white;
  border: solid 1px #DFE2E5;
  margin: 5px 0 10px 10px;
  box-shadow: 0 0 5px #ccc;
  -webkit-box-shadow: 0 0 5px #ccc;
  -moz-box-shadow: 0 0 5px #ccc;
}

.sidebarForm .input input {
  width: 248px;
  height: 26px;
  background: white;
  margin: -3px 0 0 -8px;
  padding: 0px 8px;
  border: none;
  background: transparent;
}

.sidebarForm .textarea {
  height: 70px;
  width: 320px;
  overflow: hidden;
  background: white;
  border: solid 1px #DFE2E5;
  margin: 5px 0 10px 10px;
  box-shadow: 0 0 5px #ccc;
  -webkit-box-shadow: 0 0 5px #ccc;
  -moz-box-shadow: 0 0 5px #ccc;
}

.sidebarForm .textarea textarea {
  width: 248px;
  height: 250px;
  background: white;
  border: none;
  resize: none;
}

.sidebarForm button {
  border: 1px #ccc solid;
  width: 267px;
  height: 40px;
  font: 13pt Georgia;
  text-decoration: none;
  text-align: center;
  font-style: italic;
  color: #333;
  background-color: #fea421;
  margin: 0px 0px 0px 10px;
  border-radius: 10px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  appearance: none;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.3s, border-color 0.3s;
}

.btn--rounded {
  padding: 10px 25px;
  border-radius: 200px;
  border: 1px solid var(--blue-light);
}

.btn--blue {
  color: var(--blue);
  background-color: var(--blue-light);
  border-color: var(--blue-light);
}
.btn--blue:hover {
  background-color: var(--white);
}

.btn--yellow {
  color: var(--white);
  background-color: var(--yellow);
  border-color: var(--yellow);
}
.btn--yellow:hover {
  background-color: var(--orange);
  border-color: var(--orange);
}

.btn--blue-bd {
  color: var(--blue);
  background-color: transparent;
  border-color: var(--blue);
}
.btn--blue-bd:hover {
  background-color: var(--blue-light);
}

.toggler {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}
.toggler div {
  width: 17px;
  height: 3px;
  background: var(--blue);
  transition: transform 0.5s;
}
.toggler div:nth-child(2) {
  width: 24px;
}
.toggler.opened div:first-child {
  width: 24px;
  transform: rotate(45deg) translate(6px, 5px);
}
.toggler.opened div:nth-child(2) {
  transform: rotate(135deg) translate(-1px, 0);
}
.toggler.opened div:last-child {
  opacity: 0;
}

.btn.opened .toggler div:first-child {
  width: 24px;
  transform: rotate(45deg) translate(4px, 4px);
}
.btn.opened .toggler div:nth-child(2) {
  transform: rotate(135deg) translate(0, 0);
}
.btn.opened .toggler div:last-child {
  opacity: 0;
}

.side-contacts {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-basis: 100%;
  flex-shrink: 0;
}

.side-contacts__block {
  display: flex;
  flex-direction: column;
  padding: 10px 12px;
  background-color: var(--white);
  border-radius: 10px;
}
.side-contacts__block:last-child {
  padding-bottom: 14px;
}

.side-contacts__info {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--text);
  font-size: 16px;
  font-weight: 700;
  line-height: 1.6;
  text-decoration: none;
}
.side-contacts__info svg {
  color: var(--blue);
  fill: var(--blue);
}
.side-contacts__info:not(:last-child) {
  margin-bottom: 8px;
}

a.side-contacts__info {
  transition: color 0.3s;
}
a.side-contacts__info:hover {
  color: var(--blue);
}

.side-contacts__link {
  display: flex;
  text-decoration: none;
  color: var(--text);
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2;
  transition: color 0.3s;
}
.side-contacts__link:not(:last-child) {
  margin-bottom: 5px;
}
.side-contacts__link:hover {
  color: var(--blue);
}

.top-nav__link {
  display: flex;
  padding-block: 7px;
  color: var(--text);
  font-weight: 700;
  line-height: 1.15;
  text-transform: uppercase;
  text-decoration: none;
  transition: color 0.3s;
}
.top-nav__link:hover {
  color: var(--blue);
}

@media (min-width: 1024px) {
  .top-nav__list {
    display: flex;
    gap: 15px;
  }
}
.services-menu {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
}

.services-menu__item {
  display: flex;
  flex-direction: column;
}

.services-menu__span {
  display: flex;
  padding-inline: var(--padding);
  width: 100%;
  color: var(--text);
}

.services-menu__link,
.services-menu__subs-it {
  display: flex;
  flex-grow: 1;
  padding-block: 10px;
  color: var(--text);
  text-decoration: none;
}

.services-menu__subs-it {
  padding-inline: var(--padding);
}

.services-menu__subs {
  overflow: hidden;
  height: 0px;
}

.services-menu__btn span {
  display: none;
}

.services-menu__subs-list li {
  display: flex;
  break-inside: avoid;
}

@media (max-width: 1023px) {
  .services-menu {
    height: 0;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s;
  }
  .services-menu.opened {
    height: auto;
    max-height: 5000px;
  }
  .services-menu__item.submenu-opened {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--white);
    height: calc(100svh - 127px);
  }
  .services-menu__item.submenu-opened .services-menu__link {
    font-size: 18px;
    font-weight: 700;
  }
  .services-menu__item.submenu-opened .services-menu__subs {
    height: 100%;
  }
  .services-menu__subs {
    overflow-y: auto;
  }
  .services-menu__span.opened {
    flex-wrap: wrap;
    row-gap: 20px;
  }
  .services-menu__span.opened .services-menu__link {
    flex-basis: 100%;
  }
  .services-menu__span.opened .services-menu__btn {
    order: -1;
    padding-top: 10px;
  }
  .services-menu__span.opened .services-menu__btn span {
    display: block;
  }
  .services-menu__span.opened .services-menu__btn svg {
    order: -1;
    transform: rotate(90deg);
  }
}
@media (max-width: 1023px) and (min-width: 768px) and (max-width: 1023px) {
  .services-menu__item.submenu-opened {
    height: calc(100svh - 85px);
  }
}
@media (min-width: 1024px) {
  .services-menu {
    z-index: 4;
    background-color: transparent;
    filter: drop-shadow(0px 4px 59.1px rgba(2, 58, 94, 0.2));
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .services-menu.opened {
    visibility: visible;
    opacity: 1;
  }
  .services-menu__btn {
    transform: rotate(-90deg);
    transition: color 0.3s;
  }
  .services-menu__link {
    padding-left: 20px;
  }
  .services-menu__span {
    gap: 10px;
    padding-right: 20px;
  }
  .services-menu__item {
    width: 300px;
    background-color: var(--white);
    transition: background-color 0.3s;
  }
  .services-menu__item:last-child {
    padding-bottom: 30px;
    border-radius: 0 0 10px 10px;
  }
  .services-menu__item:hover {
    background-color: var(--blue-light);
  }
  .services-menu__item:hover .services-menu__btn {
    color: var(--blue);
  }
  .services-menu__item:hover .services-menu__subs {
    visibility: visible;
    opacity: 1;
    filter: drop-shadow(0px 4px 59.1px rgba(2, 58, 94, 0.2));
  }
  .services-menu__subs-it {
    padding-inline: 20px;
    transition: color 0.3s;
  }
  .services-menu__subs-it:hover {
    color: var(--blue);
  }
  .services-menu__subs {
    position: absolute;
    top: 0;
    left: 99%;
    display: flex;
    height: auto;
    min-height: 100%;
    width: calc(100svw - 183px - 300px - var(--padding) * 2);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .services-menu__subs-list {
    display: block;
    column-count: 2;
    gap: 10px;
    padding-bottom: 30px;
    width: 100%;
    background-color: var(--white);
    border-radius: 0 0 10px 10px;
    filter: drop-shadow(0px 4px 59.1px rgba(2, 58, 94, 0.2));
  }
}
@media (min-width: 1270px) {
  .services-menu__subs {
    width: calc(787px - var(--padding) * 2);
  }
}
.menu__link {
  display: flex;
  color: var(--white);
  font-weight: 700;
  line-height: 1.07;
  text-transform: uppercase;
  text-decoration: none;
}

.menu__subs-link {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-block: 10px;
  padding-inline: var(--padding);
  color: var(--white);
  text-decoration: none;
}
.menu__subs-link svg {
  flex-shrink: 0;
}

.menu__item-span {
  display: flex;
  align-items: center;
  padding-right: var(--padding);
}
.menu__item-span svg {
  transition: transform 0.3s;
}

.menu__link {
  flex-grow: 1;
}

.menu__subs {
  height: 0;
  overflow: hidden;
}

@media (max-width: 1023px) {
  .menu__item.submenu-opened .menu__subs {
    height: auto;
  }
  .menu__link {
    padding-block: 10px;
    padding-inline: var(--padding);
  }
  .menu__subs .btn--blue-bd {
    margin-left: var(--padding);
    width: fit-content;
    color: var(--white);
    border-color: var(--white);
  }
}
@media (min-width: 1024px) {
  .menu {
    display: flex;
    align-items: center;
    gap: 30px;
    position: relative;
    z-index: 4;
  }
  .menu__subs {
    position: absolute;
    z-index: 1;
    top: 100%;
    width: calc(100% + var(--padding));
    display: flex;
    height: auto;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .menu__subs .btn--blue-bd {
    position: absolute;
    bottom: 30px;
    left: 20px;
  }
  .menu__subs-list {
    display: block;
    column-count: 4;
    gap: 10px;
    margin-top: 23px;
    padding-inline: 20px;
    padding-top: 5px;
    padding-bottom: 80px;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    border-radius: 0 0 10px 10px;
    filter: drop-shadow(0px 4px 59.1px rgba(2, 58, 94, 0.2));
  }
  .menu__item-span {
    gap: 8px;
  }
  .menu__subs-link {
    padding-inline: 0;
    color: var(--text);
    transition: color 0.3s;
  }
  .menu__subs-link:hover {
    color: var(--blue);
  }
  .menu__item:hover .menu__subs {
    visibility: visible;
    opacity: 1;
  }
  .menu__item:hover .menu__item-span svg {
    transform: rotate(180deg);
  }
  .menu__link {
    transition: color 0.3s;
  }
  .menu__link:hover {
    color: var(--yellow);
  }
}
@media (min-width: 1270px) {
  .menu__subs {
    width: 712px;
  }
}
.langs__bubble {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media (max-width: 1023px) {
  .langs__bubble {
    height: 0;
    max-height: 0;
    transition: max-height 0.3s;
    overflow: hidden;
  }
  .langs__bubble-inside {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    padding-block: 10px;
  }
  .langs__active.opened svg:last-child {
    transform: rotate(180deg);
  }
  .langs__bubble.opened {
    height: auto;
    max-height: 2000px;
  }
}
@media (min-width: 1024px) {
  .langs {
    position: relative;
  }
  .langs__active span {
    display: none;
  }
  .langs__bubble {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 4;
    opacity: 0;
    border-radius: 10px;
    visibility: hidden;
    transition: opacity 0.3s;
  }
  .lang__btn {
    padding: 10px 20px;
    width: 100%;
    justify-content: flex-start;
    background-color: var(--white);
    transition: background-color 0.3s;
  }
  .lang__btn:hover {
    background-color: var(--blue-light);
  }
  .langs__bubble-inside {
    margin-top: 10px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 59.1px 0px rgba(2, 58, 94, 0.2);
  }
  .langs:hover .langs__bubble {
    visibility: visible;
    opacity: 1;
  }
}
.head__contacts {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 5px 8px;
}

.head__contacts-phone {
  color: var(--text);
  font-size: 16px;
  font-weight: 700;
  line-height: 1.62;
  text-decoration: none;
  transition: color 0.3s;
}
.head__contacts-phone:hover {
  color: var(--blue);
}

.head__contacts-mail {
  display: flex;
  align-items: center;
  grid-column: 1/-1;
  gap: 10px;
  color: var(--text);
  text-decoration: none;
  transition: color 0.3s;
}
.head__contacts-mail:hover {
  color: var(--blue);
}

.head__contacts-mail--top {
  justify-content: center;
  padding-top: 7px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
  flex-basis: 100%;
}

@media (max-width: 1023px) {
  .head__contacts {
    margin-bottom: 30px;
  }
}
.head {
  position: relative;
  display: flex;
  flex-direction: column;
}

.head__btm {
  color: var(--white);
  background-color: var(--blue);
}

.head__btm-toggler .toggler div {
  height: 1px;
}

.head__social {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: var(--blue);
  background-color: var(--blue-opacity);
}

@media (max-width: 1023px) {
  .head__logo-mobile {
    margin-right: auto;
  }
  .head__mobile {
    display: flex;
    flex-wrap: wrap;
    padding-block: 15px;
  }
  .head__pay-mobile {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }
  .head__btn-mobile {
    margin-right: 20px;
  }
  .head__logo {
    display: none;
  }
  .head__top {
    order: 1;
    padding-inline: var(--padding);
    background-color: var(--blue-light);
    padding-bottom: 30px;
  }
  .head__btm {
    padding-top: 15px;
    padding-bottom: 27px;
  }
  .head__btm-nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
  }
  .head__btm-toggler {
    margin-left: var(--padding);
    width: fit-content;
  }
  .head__container {
    display: flex;
    flex-direction: column;
    padding-inline: 0;
    margin-left: 0;
  }
  .head__contacts-mail--top {
    position: relative;
  }
  .head__contacts-mail--top::after {
    content: "";
    position: absolute;
    top: 0;
    left: calc(-1 * var(--padding));
    width: calc(100% + 2 * var(--padding));
    border-bottom: 1px solid var(--border-color);
  }
  .head__dt {
    position: absolute;
    top: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    overflow-y: auto;
    height: calc(100svh - 127px);
    transform: translate3d(calc(-100svw - 100%), 0, 0);
  }
  .head__dt.opened {
    transform: translate3d(0, 0, 0);
    z-index: 1;
  }
  .head__top-nav {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 25px;
  }
  .head__pay {
    order: 1;
    padding: 10px 20px;
    width: 200px;
    margin-bottom: 30px;
    border-radius: 200px;
    font-weight: 700;
    line-height: 1.14;
    text-transform: uppercase;
    color: var(--yellow);
    border: 1px solid var(--yellow);
  }
  .head__langs {
    order: 2;
    margin-bottom: 30px;
  }
  .head__contacts {
    order: 3;
  }
  .head__social {
    order: 4;
  }
  .head__call,
  .head__order {
    margin-bottom: 15px;
    width: 200px;
  }
  .head__call svg {
    display: none;
  }
}
@media (max-width: 1023px) and (min-width: 768px) {
  .head {
    z-index: 5;
  }
  .head__contacts-mail--top {
    flex-basis: auto;
    padding-top: 0;
    margin-right: 20px;
  }
  .head__contacts-mail--top::after {
    display: none;
  }
  .head__pay-mobile,
  .head__btn-mobile,
  .head__toggler {
    order: 1;
  }
  .head__dt {
    height: calc(100svh - 85px);
  }
}
@media (min-width: 1024px) {
  .head__mobile {
    display: none !important;
  }
  .head__container {
    display: flex;
    align-items: center;
  }
  .head__btm-nav {
    display: flex;
    align-items: center;
    gap: 40px;
  }
  .head__top .head__container {
    position: relative;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 13px;
  }
  .head__btm {
    padding-top: 13px;
    padding-bottom: 11px;
  }
  .head__btm .head__container {
    padding-left: calc(190px + var(--padding));
    padding-right: calc(var(--padding) + 46px);
  }
  .head__social {
    position: absolute;
    z-index: 3;
    right: var(--padding);
    bottom: -53px;
    color: var(--white);
    background-color: rgba(216, 237, 245, 0.1);
  }
  .head__pay span {
    display: none;
  }
  .head__pay svg {
    width: 36px;
    height: 36px;
  }
  .head__order {
    position: absolute;
    left: var(--padding);
    bottom: -53px;
  }
  .services-menu {
    position: absolute;
    top: 100%;
  }
  .head__call svg {
    display: none;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .head__call {
    padding: 0;
    border: 0;
  }
  .head__call span {
    display: none;
  }
  .head__call svg {
    display: block;
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    details > summary {
      display: flex;
    }
  }
}
.qa-it {
  border-bottom: 1px solid #7799B0;
}
.qa-it:last-child {
  border-bottom: 0;
}

.qa-it__btn {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.qa-it__btn span:first-child {
  margin-right: auto;
}
.qa-it__btn:hover .qa-it__btn-svg {
  color: #447595;
}

.qa-it__btn-text {
  text-align: left;
  font-size: 16px;
  font-weight: 700;
}

.qa-it[open] .qa-it__content {
  max-height: 6000px;
}
.qa-it[open] .qa-it__btn-svg {
  color: var(--green);
  transform: rotate(180deg);
}

.qa-it__content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
}
.qa-it__content p {
  margin-block: 10px;
}
.qa-it__content p:first-child {
  margin-top: 0;
}
.qa-it__content p:last-child {
  margin-bottom: 0;
}

.qa-it__btn-svg {
  flex-shrink: 0;
  transition: transform 0.3s, color 0.3s, border-color 0.3s, background-color 0.3s;
}

.qa-it__content-inside {
  padding-block: 10px;
}

@media (max-width: 1023px) {
  .qa-it {
    padding-block: 16px;
  }
}
@media (min-width: 1024px) {
  .qa-it__btn-svg {
    width: 44px;
    height: 44px;
  }
  .qa-it__content-inside {
    padding-bottom: 24px;
    max-width: calc(100% - 140px);
  }
}
.qa {
  position: relative;
  padding-bottom: 40px;
}

.qa__h2 {
  margin-top: 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #447595;
  margin-bottom: 26px;
}

.qa__list {
  padding: 16px;
  background: rgba(119, 153, 176, 0.2);
}

@media (min-width: 1024px) {
  .qa__h2 {
    margin-bottom: 40px;
  }
  .qa {
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .qa__list {
    padding-inline: 40px;
    padding-block: 20px;
  }
}