.head {
  position: relative;
  display: flex;
  flex-direction: column;
}

.head__btm {
  color: var(--white);
  background-color: var(--blue);
}

.head__btm-toggler .toggler {
  div {
    height: 1px;
  }
}

.head__social {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: var(--blue);
  background-color: var(--blue-opacity);
}

@media (max-width: calc($desktop - 1px)) {
  .head__logo-mobile {
    margin-right: auto;
  }

  .head__mobile {
    display: flex;
    flex-wrap: wrap;
    padding-block: 15px;
  }


  .head__pay-mobile {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }

  .head__btn-mobile {
    margin-right: 20px;
  }

  .head__logo {
    display: none;
  }

  .head__top {
    order: 1;
    padding-inline: var(--padding);
    background-color: var(--blue-light);
    padding-bottom: 30px;
  }

  .head__btm {
    padding-top: 15px;
    padding-bottom: 27px;
  }

  .head__btm-nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
  }

  .head__btm-toggler {
    margin-left: var(--padding);
    width: fit-content;
  }

  .head__container {
    display: flex;
    flex-direction: column;
    padding-inline: 0;
    margin-left: 0;
  }

  .head__contacts-mail--top {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: calc(-1 * var(--padding));
      width: calc(100% + 2 * var(--padding));
      border-bottom: 1px solid var(--border-color);
    }
  }

  .head__dt {
    position: absolute;
    top: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    overflow-y: auto;
    height: calc(100svh - 127px);
    transform: translate3d(calc(-100svw - 100%), 0, 0);
  }

  .head__dt.opened {
    transform: translate3d(0, 0, 0);
    z-index: 1;
  }

  .head__top-nav {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 25px;
  }

  .head__pay {
    order: 1;
    padding: 10px 20px;
    width: 200px;
    margin-bottom: 30px;
    border-radius: 200px;
    font-weight: 700;
    line-height: 1.14;
    text-transform: uppercase;
    color: var(--yellow);
    border: 1px solid var(--yellow)
  }

  .head__langs {
    order: 2;
    margin-bottom: 30px;
  }

  .head__contacts {
    order: 3;
  }

  .head__social {
    order: 4;
  }

  .head__call,
  .head__order {
    margin-bottom: 15px;
    width: 200px;
  }

  .head__call {
    svg {
      display: none;
    }
  }

  @media (min-width: $tablet) {

    .head {
      z-index: 5;
    }
    .head__contacts-mail--top {
      flex-basis: auto;
      padding-top: 0;
      margin-right: 20px;


      &::after {
        display: none;
      }
    }

    .head__pay-mobile,
    .head__btn-mobile,
    .head__toggler {
      order: 1;
    }

    .head__dt {
      height: calc(100svh - 85px);
    }
  }
}

@media (min-width: $desktop) {
  .head__mobile {
    display: none !important;
  }

  .head__container {
    display: flex;
    align-items: center;
  }

  .head__btm-nav {
    display: flex;
    align-items: center;
    gap: 40px;
  }

  .head__top .head__container {
    position: relative;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 13px;
  }

  .head__btm {
    padding-top: 13px;
    padding-bottom: 11px;
  }

  .head__btm .head__container {
    padding-left: calc(190px + var(--padding));
    padding-right: calc(var(--padding) + 46px);
  }

  .head__social {
    position: absolute;
    z-index: 3;
    right: var(--padding);
    bottom: -53px;
    color: var(--white);
    background-color: rgba(216, 237, 245, 0.1);
  }

  .head__pay {
    span {
      display: none;
    }

    svg {
      width: 36px;
      height: 36px;
    }
  }

  .head__order {
    position: absolute;
    left: var(--padding);
    bottom: -53px;
  }

  .services-menu {
    position: absolute;
    top: 100%;
  }

  .head__call {

    svg {
      display: none;
    }
  }

  @media (max-width: 1200px) {
    .head__call {
      padding: 0;
      border: 0;
      span {
        display: none;
      }

      svg {
        display: block;
      }
    }
  }
}