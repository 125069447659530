:root {
  --blue-light: #D8EDF5;
  --blue: #447595;
  --blue-opacity: rgba(68, 117, 149, 0.10);
  --border-color: #E1F1F7;;
  --white: #fff;
  --yellow: #FEAF22;
  --orange: #F28518;
  --text: #333;
  --padding: 15px;
}

$tablet: 768px;
$desktop: 1024px;
$container: 1470px;
$big: 1920px;

.bg-blue {
  background-color: var(--blue);
}
